import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BatchDetailModalComponent } from 'app/dayclose-process/batch-detail-modal/batch-detail-modal.component';
import { BatchMaster } from 'app/models/batch-master';
import { BatchService } from 'app/services/batch.service';
import { DatePipe, Location } from "@angular/common";
@Component({
  selector: 'app-cob-batch-setting',
  templateUrl: './cob-batch-setting.component.html',
  styleUrls: ['./cob-batch-setting.component.scss']
})
export class CobBatchSettingComponent implements OnInit {
  // @ViewChild('modalContent') modalContent: TemplateRef<any>;

  batchData: BatchMaster = {
    Id: 0
  };
  batchList: BatchMaster[] = [];

  closeResult: string;

  constructor(private batchService: BatchService, private location: Location, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.fetchBatchMasterList();
  }

  


  insertBatch() {
    
    this.batchService.insertBatch(this.batchData).subscribe(
      response => {
        if(response > 0){
          alert('Inserted Successfully!');
          this.fetchBatchMasterList();
        }
      },
      error => {
        alert('Insert Failed!')
      }
    );
  }



  fetchBatchMasterList() {
    this.batchService.getBatchMasterList().subscribe(
      (data: BatchMaster[]) => {
        this.batchList = data;
      },
      error => {
        console.error('Error fetching batch list:', error);
      }
    );
  }


  deleteBatch(item:any){
    this.batchService.removeBatchMaster(item.id).subscribe(
      (data: any) => {
        alert('Deleted Successfully!');
        this.fetchBatchMasterList();
      },
      error => {
        console.error('Error fetching batch list:', error);
      }
    );
  }

  viewBatch(item:any){
    this.batchService.getBatchDetailByMasterId(item.id).subscribe(
      (data: any) => {
         this.openModal(data, item);
      },
      error => {
        console.error('Error fetching batch list:', error);
      }
    );
  }

  editBatch(item:any){
    this.batchData.Id = item.id;
    this.batchData.batchCode = item.batchCode;
    this.batchData.batchName = item.batchName;

   
  }

  openModal(data: any, master:any) {
    const modalRef = this.modalService.open(BatchDetailModalComponent, { centered: true });
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.master = master;
  }
  goBack() {
    this.location.back();
  }

}
