import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NagadDirectDebitLogComponent } from './nagad-direct-debit-log/nagad-direct-debit-log.component';
import { NagadTransactionLogComponent } from './nagad-transaction-log/nagad-transaction-log.component';
import { NagadDisbursementLogComponent } from 'app/user/nagad-disbursement-log/nagad-disbursement-log.component';

const routes: Routes = [
  { path: "", component: NagadDirectDebitLogComponent, data: { title: "Direct Debit Log" } },
  { path: "direct-debit-log", component: NagadDirectDebitLogComponent, data: { title: "Direct Debit Log" } },
  { path: "transaction-logs", component: NagadTransactionLogComponent, data: { title: "Transaction Log" } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NagadRoutingModule { }
