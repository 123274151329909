import { Component, OnInit } from "@angular/core";
import { SmsEmailFormat } from "../models/sms-email-format";
import { PaymentService } from "../payment.service";
import { ThumbRule } from "../models/thumb-rule";
import { PdfService } from "app/pdf-reporting/pdf.service";
import Swal from "sweetalert2";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { DatePipe, Location } from "@angular/common";
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: "app-thumb-rule-info",
  templateUrl: "./thumb-rule-info.component.html",
  styleUrls: ["./thumb-rule-info.component.scss"],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ThumbRuleInfoComponent implements OnInit {
  selectedFile: File | null = null;
  newSchedulerTime: string = "";
  newSchedulerTimeE: string = "";
  serverTime: string = "";
  interval: any;
  timer: number = 0;

  thumbRUle: ThumbRule = {
    id: null,
    orgName: "",
    orgAddress: "",
    orgPhone: "",
    orgEmail: "",
    sessionTimeMin: 0,
    enableBanglaSMS: 0,
    enableEnglishSMS: 0,
    enableBanglaEmail: 0,
    enableEnglishEmail: 0,
    isEODAutoProcess: 0,
    autoProcessTime: null,
    allowNewTab: false,
    allowMultipleLogin: false,
    reportType: "",
    dateFormat: "dd-MM-yyyy",
    dpsAccountPrefix: "",
    dpsAccountMid: "",
    dpsAccountSuffix: "",
    scheduler: [],
    eligibility: [],
    eligibilityFreq: "ONCE",
    autoEncashment: 0,
    tapPaymentFrequency: 0,
    tapEncashmentFrequency: 0,
    dormantMonths: 0
  };

  dateFormats: string[] = [
    "dd-MM-yyyy", // Example: 14-09-2024
    "MM/dd/yyyy", // Example: 09/14/2024
    "MM-dd-yyyy", // Example: 09-14-2024
    "yyyy-MM-dd", // Example: 2024-09-14
    "yyyy/MM/dd", // Example: 2024/09/14
    "dd/MM/yyyy", // Example: 14/09/2024
    "yyyy.MM.dd", // Example: 2024.09.14
  ];

  constructor(
    private formatService: PaymentService,
    private pdfService: PdfService,
    private http: HttpClient,
    private location: Location
  ) {}

  ngOnInit() {
    this.GetThumRule();
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.CurrentServerTime();
    }, 1000);
  }

  CurrentServerTime(){
    this.formatService.CurrentServerTime().subscribe((res) => {
      this.serverTime = res.timeString;
    })
  }

  GetThumRule() {
    this.formatService.GetThumbRule().subscribe((res) => {
      console.log(res);
      this.thumbRUle = {
        id: res.thumbRule.id,
        orgName: res.thumbRule.orgName,
        orgAddress: res.thumbRule.orgAddress,
        orgPhone: res.thumbRule.orgPhone,
        orgEmail: res.thumbRule.orgEmail,
        sessionTimeMin: res.thumbRule.sessionTimeMin,
        enableBanglaSMS: res.thumbRule.enableBanglaSMS,
        enableEnglishSMS: res.thumbRule.enableEnglishSMS,
        enableBanglaEmail: res.thumbRule.enableBanglaEmail,
        enableEnglishEmail: res.thumbRule.enableEnglishEmail,
        isEODAutoProcess: res.thumbRule.isEODAutoProcess,
        autoProcessTime: res.thumbRule.autoProcessTime,
        allowNewTab: res.thumbRule.allowNewTab,
        allowMultipleLogin: res.thumbRule.allowMultipleLogin,
        reportType: res.thumbRule.reportType,
        dateFormat: res.thumbRule.dateFormat,
        dpsAccountPrefix: res.thumbRule.dpsAccountPrefix,
        dpsAccountMid: res.thumbRule.dpsAccountMid,
        dpsAccountSuffix: "00000001",
        scheduler: res.autoDebitTime,
        eligibility: res.eligibilityTime,
        eligibilityFreq: res.thumbRule.eligibilityFreq,
        autoEncashment: res.thumbRule.autoEncashment,
        tapPaymentFrequency: res.thumbRule.tapPaymentFrequency,
        tapEncashmentFrequency: res.thumbRule.tapEncashmentFrequency,
        dormantMonths: res.thumbRule.dormantMonths
      };
    });
  }

  addSchedulerTime() {
    if (this.newSchedulerTime) {
      this.thumbRUle.scheduler.push(this.newSchedulerTime);
      this.newSchedulerTime = ""; // Clear the input after adding
    } else {
      alert("Please enter a valid time.");
    }
  }

  removeSchedulerTime(index: number) {
    this.thumbRUle.scheduler.splice(index, 1); // Remove the time at the specified index
  }

  addSchedulerTimeE() {
    if (this.newSchedulerTimeE) {
      this.thumbRUle.eligibility.push(this.newSchedulerTimeE);
      this.newSchedulerTimeE = ""; // Clear the input after adding
    } else {
      alert("Please enter a valid time.");
    }
  }

  removeSchedulerTimeE(index: number) {
    this.thumbRUle.eligibility.splice(index, 1); // Remove the time at the specified index
  }

  Submit(): void {
    if (this.ValidateForm()) {
      // Create FormData object to handle both file and form data
      const formData = new FormData();
      formData.append("id", this.thumbRUle.id);
      formData.append("orgName", this.thumbRUle.orgName || "");
      formData.append("orgAddress", this.thumbRUle.orgAddress || "");
      formData.append("orgPhone", this.thumbRUle.orgPhone || "");
      formData.append("orgEmail", this.thumbRUle.orgEmail || "");
      formData.append("eligibilityFreq", this.thumbRUle.eligibilityFreq || "");
      formData.append("autoEncashment", String(this.thumbRUle.autoEncashment));
      formData.append("tapPaymentFrequency", String(this.thumbRUle.tapPaymentFrequency));
      formData.append("tapEncashmentFrequency", String(this.thumbRUle.tapEncashmentFrequency));
      formData.append("dormantMonths", String(this.thumbRUle.dormantMonths));
      formData.append(
        "sessionTimeMin",
        String(this.thumbRUle.sessionTimeMin) || ""
      );
      formData.append(
        "enableBanglaSMS",
        String(this.thumbRUle.enableBanglaSMS) || ""
      );
      formData.append(
        "enableEnglishSMS",
        String(this.thumbRUle.enableEnglishSMS) || ""
      );
      formData.append(
        "enableBanglaEmail",
        String(this.thumbRUle.enableBanglaEmail) || ""
      );
      formData.append(
        "enableEnglishEmail",
        String(this.thumbRUle.enableEnglishEmail) || ""
      );
      formData.append(
        "isEODAutoProcess",
        String(this.thumbRUle.isEODAutoProcess) || ""
      );
      formData.append(
        "autoProcessTime",
        String(this.thumbRUle.autoProcessTime) || ""
      );
      formData.append("allowNewTab", String(this.thumbRUle.allowNewTab) || "");
      formData.append(
        "allowMultipleLogin",
        String(this.thumbRUle.allowMultipleLogin) || ""
      );
      formData.append("reportType", this.thumbRUle.reportType || "");
      formData.append("dateFormat", this.thumbRUle.dateFormat || "");
      formData.append(
        "dpsAccountPrefix",
        this.thumbRUle.dpsAccountPrefix || ""
      );
      formData.append("dpsAccountMid", this.thumbRUle.dpsAccountMid || "");
      formData.append(
        "dpsAccountSuffix",
        this.thumbRUle.dpsAccountSuffix || ""
      );

      if (this.selectedFile) {
        formData.append("manualFile", this.selectedFile);
      }

      // Append scheduler array to formData
      this.thumbRUle.scheduler.forEach((time, index) => {
        if (time != "") {
          formData.append(`scheduler[${index}]`, time);
        }
      });

      // Append eligibility array to formData
      this.thumbRUle.eligibility.forEach((time, index) => {
        if (time != "") {
          formData.append(`eligibility[${index}]`, time);
        }
      });
      // this.formatService.SaveThumbRule(this.thumbRUle).subscribe(
      //   () => {
      //     Swal.fire({
      //       title: "Success",
      //       text: "Thumb Rule Updated Successfully!",
      //       icon: "success",
      //       showCancelButton: false,
      //       confirmButtonText: "Ok",
      //     })
      //     this.GetThumRule();
      //   },
      //   (error) => {
      //     Swal.fire({
      //       title: "Warning",
      //       text: "Failed to update Rule!",
      //       icon: "warning",
      //       showCancelButton: false,
      //       confirmButtonText: "Ok",
      //     })
      //   }
      // );
      // Replace 'YOUR_API_URL' with the actual URL of your API endpoint
      this.http
        .post(environment.baseUrl + "/api/SaveThumbRule", formData)
        .subscribe(
          () => {
            Swal.fire({
              title: "Success",
              text: "Thumb Rule Updated Successfully!",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Ok",
            });
            this.GetThumRule(); // Assuming this method refreshes the data
          },
          (error) => {
            Swal.fire({
              title: "Warning",
              text: "Failed to update Rule!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonText: "Ok",
            });
          }
        );
    }
  }

  OpenPDF() {
    this.pdfService.GenerateThumbPdf().subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  ValidateForm(): boolean {
    if (
      this.thumbRUle.dpsAccountPrefix == null ||
      this.thumbRUle.dpsAccountPrefix == ""
    ) {
      Swal.fire({
        title: "Warning",
        text: "Account Prefix is mandatory!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      return false;
    } else if (
      this.thumbRUle.dpsAccountMid == null ||
      this.thumbRUle.dpsAccountMid == ""
    ) {
      Swal.fire({
        title: "Warning",
        text: "Account Mid is mandatory!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      return false;
    } else if (
      this.thumbRUle.dpsAccountSuffix == null ||
      this.thumbRUle.dpsAccountSuffix == ""
    ) {
      Swal.fire({
        title: "Warning",
        text: "Account Suffix is mandatory!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      return false;
    } else {
      return true;
    }
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  onAutoCOBChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;

    console.log("Selected value:", selectedValue);
    if (selectedValue === "1") {
      this.thumbRUle.isEODAutoProcess = 1;
    } else if (selectedValue === "0") {
      this.thumbRUle.isEODAutoProcess = 0;
    }
  }

  goBack() {
    this.location.back();
  }
}
