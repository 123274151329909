 
<div class="card">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9">
                  <h5 class="card-title page-heading" style="color: black">
                    Balance Type
                  </h5>
                   
                </div>
                <div class="col-md-2">
                   
                   
                </div>
                <div class="col-md-1">
                  <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                    <i class="icon-arrow-left" style="font-size: 17px;"></i>
                </button>
                </div>
              </div>
              <hr style="height: 10%" />
        </div>
    </div>
    <div class="card-header row">
        <div class="col-md-5 row ml-4">
          <label for="processTillDate" class="col-md-5 mt-1" style="font-weight: bolder;">Target Date:</label>
          <div class="">
            <input type="date" class="form-control"id="processTillDate" [(ngModel)]="processTillDate">
          </div>
          <!-- <div class="col-md-3">
            <a href="javascript:void(0)" class="btn btn-info" (click)="onDateChange()">Save</a>
          </div> -->
        </div>
        <div class="col-md-5 mt-1">
            <!-- <button type="button" class="btn btn-success btn-sm" id="processButton"
                  (click)="StartProcess()">Process</button> -->
            <button type="button" class="btn btn-success btn-sm ml-2" id="processButton" (click)="LogScheduleForCOB()"
                *ngIf="!currentProcessStatus">Process</button>
            <button type="button" class="btn btn-danger ml-2 btn-sm" id="terminateButton" (click)="Terminate()"
                *ngIf="currentProcessStatus">Terminate</button>
            <button type="button" disabled style="color: black;" class="btn btn-default ml-2 btn-sm"
                *ngIf="currentProcessStatus">Processing...</button>
        </div>
        <div class="col-md-4">
          <img src="../../../assets/img/loader/loader.gif" alt="Loading" width="50" class="float-right" *ngIf="isLoading">
        </div>
        <!-- <button type="button" class="btn btn-info ml-2 btn-sm" id="paymentButton"
            (click)="Payment('01763108284', '1', 'bKash')">Payment</button> -->

    </div>
    <div class="card-body">
        <div class="form-group row d-none">
            <!-- <label for="type" class="col-md-1">Type:</label>
           <div class="col-2">
               <select type="text" class="form-control"  [(ngModel)]="types">
                    <option value="">Select</option>
                    <option value="Deposit">Deposit</option>
                    <option value="FDR">FDR</option>
                    <option value="DPS">DPS</option>
                    <option value="Loan">Loan</option>
               </select>
           </div> -->

            <label for="dateInput" class="col-md-2">From Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="fromDate" class="form-control">
            </div>

            <label for="dateInput" class="col-md-2">To Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>

            <div class="col-2">
                <button type="button" class="btn btn-primary" id="processButton">Process</button>
            </div>
        </div>
        <!-- <hr> -->
        <div class="row">
            <!-- <table style="width: 100%;">
                <thead>
                    <tr style="background-color: rgb(180, 179, 179);">
                        <th>
                            <input (click)="onChangeCheckboxAll()" type="checkbox" id="allCheck" [(ngModel)]="isAllProcessed" class="form-control checkbox">
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;">Check All</label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <div style="display: flex;">
                                <a href="javascript.void(0)" (click)="ProcessAll()" class="btn btn-info btn-sm">Process</a>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody style="background-color: white;">
                    <tr *ngFor="let item of batchDetails">
                        <td style="width: 5%;">
                            <input id="row_{{item.batchDetail?.id}}" (click)="onChangeCheckbox(item.batchDetail?.id)" type="checkbox" class="form-control checkbox">
                            <input type="hidden" [name]="isIdChecked(item.batchDetail?.id) ? 'name' : ''" value="{{item.batchDetail?.name}}">
                            <input type="hidden" [name]="isIdChecked(item.batchDetail?.id) ? 'id' : ''" value="{{item.batchDetail?.id}}">
                        </td>
                        <td style="">
                            <label for="row_{{item.batchDetail?.id}}" style="font-weight: bold; margin: 0;">{{item.batchDetail.name}}</label><br>
                            <small for="row_{{item.batchDetail?.id}}" style="font-weight: bold; margin: 0; font-size: 10px;">{{item.batchDetail.query}}</small>
                        </td>
                        <td style="">
                            <input type="text" *ngIf="item.parameterInfos[0]?.name != null" [name]="isIdChecked(item.batchDetail?.id) ? 'param1' : ''" placeholder="@{{item.parameterInfos[0]?.name}}" readonly [value]="getValue(item.parameterInfos[0]?.name)" class="form-control">
                        </td>
                        <td style="">
                            <input type="text" *ngIf="item.parameterInfos[1]?.name != null" [name]="isIdChecked(item.batchDetail?.id) ? 'param2' : ''" placeholder="@{{item.parameterInfos[1]?.name}}" readonly [value]="getValue(item.parameterInfos[1]?.name)" class="form-control">
                        </td>
                        <td style="">
                            <input type="text" *ngIf="item.parameterInfos[2]?.name != null" [name]="isIdChecked(item.batchDetail?.id) ? 'param3' : ''" placeholder="@{{item.parameterInfos[2]?.name}}" [value]="getValue(item.parameterInfos[2]?.name)" readonly class="form-control">
                        </td>
                        <td style="">
                            <input type="text" *ngIf="item.parameterInfos[3]?.name != null" [name]="isIdChecked(item.batchDetail?.id) ? 'param4' : ''" placeholder="{{item.parameterInfos[3]?.name}}" [value]="getValue(item.parameterInfos[3]?.name)" readonly class="form-control">
                        </td>
                        <td style="width: 100px;">
                        </td>
                    </tr>
                </tbody>
            </table> -->


            <div class="col-12">
                <ul style="list-style-type: none; font-size: 16px;">
                    <li *ngFor="let item of batchMasters">
                        <input type="checkbox" [(ngModel)]="item.selected" (change)="selectAllDetails(item)"
                            style="zoom: 1.5; display: none;">
                        <span style="color: darkblue;">{{item.batchCode}} - {{item.batchName}}</span> &nbsp; &nbsp;
                        <input type="hidden" [(ngModel)]="item.id" />
                        <ul style="list-style-type: none; font-size: 14px;">
                            <li *ngFor="let detail of getBatchDetail(item.id)">
                                <div class="row">
                                    <div class="col-6">
                                        <input type="checkbox" [(ngModel)]="detail.selected" style="zoom: 1.5; display: none;">
                                        <span (click)="ShowProcessData(detail.batchDetail.id)" style="color: darkcyan; cursor: pointer;">{{detail.batchDetail.name}} ( {{detail.processed + detail.remaining}} = {{detail.processed}} + {{detail.remaining}} )</span>
                                        <input type="hidden" [(ngModel)]="detail.batchDetail.id" />
                                    </div>
                                    <div class="col-6">
                                        <div class="progress" style="height: 20px;">
                                            <div class="progress-bar bg-success progress-bar-striped active" role="progressbar"
                                                [style.width]="detail.percentage + '%'" [attr.aria-valuenow]="detail.percentage" aria-valuemin="0"
                                                aria-valuemax="100">
                                                {{detail.percentage}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>
    </div>

    <!-- <div class="card-footer">
        <button type="button" class="btn btn-success pull-right" id="processButton">Process</button>
    </div> -->
</div>
