import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { DatePipe, Location } from "@angular/common";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pending-retail-product',
  templateUrl: './pending-retail-product.component.html',
  styleUrls: ['./pending-retail-product.component.scss']
})
export class PendingRetailProductComponent implements OnInit {

  productList: any[] = [];
  productDetails: any;
  closeResult: string;
  clickedProductId: number = 0;
  events: string = '';
  remarks: string = '';
  errorMessage: string | null = null;

  constructor(private _loanBuilderService:ProductBuilderService,private modalService: NgbModal,
    private router: Router, private pdfService:PdfService, private location: Location,)
    { }

  ngOnInit(): void {
    this.GetAllPendingProduct();

    this.events = 'Return,Reject';// sessionStorage.getItem('userevents');
  }

  getProductDetails(id: any) {
    debugger;
    this.clickedProductId = id;
    this._loanBuilderService.ProductBuilderDetailsByProId(id).subscribe(
      (data: any) => {

        this.productDetails = data;
      },
      (error) => {
        console.error("Error fetching product details:", error);
      }
    );
  }

  GetAllPendingProduct() {
    this._loanBuilderService.GetAllPendingProductByRole().subscribe(res => {
      this.productList = res;
    })
  }

  Approve(productId:number){
    // this.validateInput();
    if (!this.errorMessage) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Approve',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Approved',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this._loanBuilderService.AproveLoanProductByUserRole(productId, this.remarks).subscribe(res => {
            if(res >= 1){
              Swal.fire({
                title: 'Success?',
                text: 'Approved Successfully!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((e) =>{
                window.location.reload();
              })
            }
            else{
              Swal.fire({
                title: 'Failed',
                text: 'Failed to Approve',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                //cancelButtonText: 'No'
              })
            }
          })
        }
      })
    }
    else{
      Swal.fire({
        title: 'Warning',
        text: 'You must enter a comment',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      })
    }


  }

  Return(productId:number){
    this.validateInput();
    if (!this.errorMessage) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Return',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Returned',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this._loanBuilderService.ReturnLoanProductByUserRole(productId, this.remarks).subscribe(res => {
            if(res >= 1){
              Swal.fire({
                title: 'Success?',
                text: 'Returned Successfully!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((e) =>{
                window.location.reload();
              })
            }
            else{
              Swal.fire({
                title: 'Failed',
                text: 'Failed to Return',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                //cancelButtonText: 'No'
              })
            }
          })
        }
      })
    }
    else{
      Swal.fire({
        title: 'Warning',
        text: 'You must enter a comment',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      })
    }
  }

  Reject(productId:number){
    this.validateInput();
    if (!this.errorMessage) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Reject',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Rejected',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this._loanBuilderService.RejectLoanProductByUserRole(productId, this.remarks).subscribe(res => {
            if(res >= 1){
              Swal.fire({
                title: 'Success?',
                text: 'Rejected Successfully!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((e) =>{
                window.location.reload();
              })
            }
            else{
              Swal.fire({
                title: 'Failed',
                text: 'Failed to Reject',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                //cancelButtonText: 'No'
              })
            }
          })
        }
      })
    }
    else{
      Swal.fire({
        title: 'Warning',
        text: 'You must enter a comment',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      })
    }
  }

  getLoanProductDetails(id:any){
    this.clickedProductId = id;
    this._loanBuilderService.getProductBuilderDetailsById(id).subscribe(
      (data : any) => {
        this.productDetails = data;

      }
    );
  }

  openBuilderDetailsModal(content) {
    debugger;
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }
  download(){
    this.pdfService.DpsProducInfo(this.productDetails?.productInfo?.id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
    // const fileUrl = `${apiUrlDoc}ProductPdf?id=${this.productDetails?.productInfo?.id}`;
    // window.open(fileUrl, '_blank');
  }

  // validateInput() {
  //   const minWords = 50;
  //   const wordCount = this.remarks.trim().split(/\s+/).length;

  //   if (wordCount < minWords) {
  //     this.errorMessage = `Please enter at least ${minWords} words. Currently, you have ${wordCount} words.`;
  //   } else {
  //     this.errorMessage = null;
  //   }
  // }

  validateInput() {
    const maxChars = 15;
    const charCount = this.remarks.trim().length;

    if (charCount > maxChars) {
      this.errorMessage = `Please enter no more than ${maxChars} characters. Currently, you have ${charCount} characters.`;
    } else {
      this.errorMessage = null;
    }
  }


  goBack() {
    this.location.back();
  }

}
