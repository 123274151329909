import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BatchMaster } from 'app/models/batch-master';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BatchDetail } from 'app/models/batch-detail';

const apiUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class BatchService {


  constructor(private http:HttpClient) { }

  insertBatch(batchData: BatchMaster): Observable<any> {
    return this.http.post<any>(`${apiUrl}/api/insertBatchMaster`, batchData);
  }

  getBatchMasterList(): Observable<BatchMaster[]> {
    return this.http.get<BatchMaster[]>(`${apiUrl}/api/BatchMasterList`);
  }


  removeBatchMaster(id: number): Observable<number> {
    return this.http.get<number>(`${apiUrl}/api/DeleteBatchMasterById?id=` + id);
  }

  getBatchDetailByMasterId(masterId: number): Observable<BatchDetail[]> {
    return this.http.get<BatchDetail[]>(`${apiUrl}/api/GetBatchDetailByMasterId?masterId=` + masterId);
  }


  removeBatchDetail(id: number): Observable<number> {
    return this.http.get<number>(`${apiUrl}/api/DeleteBatchDetailById?id=` + id);
  }


  InsertBatchDetail(batchData: BatchDetail): Observable<any> {
    return this.http.post<any>(`${apiUrl}/api/insertBatchDetail`, batchData);
  }


  getJobInfo(): Observable<any[]> {
    return this.http.get<any[]>(`${apiUrl}/api/GetAllCobJobInfo`);
  }


  apiCallForBatchProcess(batchData: BatchDetail): Observable<any> {
    return this.http.post<any>(`${apiUrl}/api/apiCallForBatchProcess`, batchData);
  }

  terminateAllJob(): Observable<number> {
    return this.http.get<number>(`${apiUrl}/api/TerminateAllDailyProcess`);
  }


  getFileLogs(fromDate: Date, toDate: Date) {
    const url = `${apiUrl}/api/GetFileLogs?fromDate=${fromDate.toString()}&toDate=${toDate.toString()}`;
    return this.http.get(url);
  }


  getfileSettings(): Observable<any[]> {
    return this.http.get<any[]>(`${apiUrl}/api/getfileSettings`);
  }


  GetBaseUrl(): string {
    return apiUrl;
  }


  LogScheduleForCOB(targetDate: string): Observable<any> {
    return this.http.get<any>(`${apiUrl}/api/LogScheduleForCOB?targetDate=${targetDate}`);
  }

  CheckProcessStatus(): Observable<any> {
    return this.http.get<any>(`${apiUrl}/api/CheckProcessStatus`);
  }

}
