import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import swal from 'sweetalert';
import { LanguageServiceService } from './services/language-service.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './services/session.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    currentLanguage: string;
    subscription: Subscription;

    supportedLanguage = ['en', 'bn'];

    constructor(private router: Router, private languageService: LanguageServiceService, private sessionService: SessionService,
        private translateService: TranslateService) {
        // this.languageService.language$.subscribe(language => {
        //     this.currentLanguage = language;
        // });

        languageService.setLanguage();
        // const language = localStorage.getItem('lang');

        // this.translateService.addLangs(this.supportedLanguage);
        // this.translateService.setDefaultLang('en');
        // const browserLang = this.translateService.getBrowserLang();
        // this.translateService.use(language);
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));


            this.sessionService.initSessionTimer();

            const pathName = window.location.pathname;

            if(localStorage.getItem('token') == null || localStorage.getItem('token') == 'null'){
              if (pathName !== '/pages/terms-and-conditions-dps') {
                this.router.navigate(['/pages/login']);
              }
            }
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}
