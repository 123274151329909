import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

const apiUrl = environment.baseUrl + '/api/Dashboard/';
const apiUrl2 = environment.baseUrl + '/api/CustomerAccount/';
const apiLoan = environment.baseUrl + '/api/LoanDisbursement/';

@Injectable({
  providedIn: "root",
})
export class DashboardCustomService {
  constructor(private http: HttpClient) {}

  getCountLoanInfo(): Observable<any> {
    return this.http.get(apiUrl + "GetTotalLoanInfoForClientDash");
  }

  // getCustomerAccountByCustomerId(): Observable<any>{
  //   return this.http.get(apiUrl2+ 'GetAllCustomerAccountByCustomerId');
  // }

  getCustomerAccountByCustomerId(): Observable<any> {
    return this.http.get(apiUrl2 + "GetustomerAccountBalanceByCustomerId");
  }

  getcCustomerDPSByDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    tenureId: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetCustomersAllDPSByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&tenureId=" +
        tenureId +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  getCustomerAccountLoan(): Observable<any> {
    return this.http.get(apiUrl2 + "GetCustomerAccountLoan");
  }

  getCustomerLoanAccountInfo(): Observable<any> {
    return this.http.get(apiUrl2 + "GetCustomerAccountLoanForWeb");
  }

  getLoanCollection(): Observable<any> {
    return this.http.get(apiLoan + "GetLoanCollectionByCustomerId");
  }

  getAllRole(): Observable<any> {
    return this.http.get(apiUrl + "GetAllRoleList");
  }

  getUserRole(): Observable<any> {
    return this.http.get(apiUrl + "GetAllUserRoleByUser");
  }

  getLoanLeadInfoForAdmin(): Observable<any> {
    return this.http.get(apiUrl + "GetLeadGenerationListForAdminDash");
  }

  getAppliedLoanLeadInfoForDisburse(): Observable<any> {
    return this.http.get(apiUrl + "GetAppliedLeadListForAdminDash");
  }

  getAppliedLoanLeadInfoForDisburseBYDate(
    startDate: any,
    EndDate: any
  ): Observable<any> {
    return this.http.get(
      apiUrl +
        "GetAppliedLeadListForAdminDashByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetLoanInfoForAdminDeshBoard(): Observable<any> {
    return this.http.get(apiUrl + "GetLoanInfoForAdminDeshBoard");
  }

  GetCashFlowAllForAdminDashBoard(): Observable<any> {
    return this.http.get(apiUrl + "GetCashFlowAllForAdminDashBoard");
  }

  GetMonthlyCashGoingandOut(): Observable<any> {
    return this.http.get(apiUrl + "GetMonthlyCashGoingandOut");
  }

  GetMonthlyProfitandLoss(): Observable<any> {
    return this.http.get(apiUrl + "GetMonthlyProfitandLoss");
  }

  GetAccountCountAdminDeshBoardOther(): Observable<any> {
    return this.http.get(apiUrl + "GetCustomerAccountForAdminDeshBoard");
  }

  GetAccountCountAdminDeshBoard(parentChannelId: any): Observable<any> {
    return this.http.get(
      apiUrl +
        "GetCustomerAccountForAdminDeshBoard?parentChannelId=" +
        parentChannelId
    );
  }
  getDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }
  getApprovedDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }
  getFinalDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }

  getFinalDisbursLoanByDate(startDate: any, EndDate: any): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetFinalDisbursListLoanByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetTodayCollectionList(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/Dashboard/GetTodayCollectionList"
    );
  }

  getTodayDPSReceivableList(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/Dashboard/GetTodayDPSReceivableList"
    );
  }

  TodayDPSReceivableList(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/Dashboard/GetTodayDPSReceivableList?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  TodayReceivedList(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/Dashboard/GetTodayReceivedList"
    );
  }

  TodaySettlementList(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/Dashboard/GetTodaySettlementList"
    );
  }

  AdvanceCollectionList(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/Dashboard/GetAdvanceCollectionList"
    );
  }

  disbursLoan(id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/DisbursLoan/" + id
    );
  }

  disbursLoanByLeadCode(
    leadCode: number,
    cibSubjectCode: any
  ): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/DisbursLoanByLeadCode/" +
        leadCode +
        "/" +
        cibSubjectCode
    );
  }

  getLeadStatusBYLeadCode(leadCode: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/GetLeadInfoStatusCheckByLeadCode/" +
        leadCode
    );
  }

  /////
  RejectLoan(id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/RejectLoanByLeadId/" + id
    );
  }

  FinalDisbursLoan(id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/FinalDisbursLoan/" + id
    );
  }

  getListForCustomerDashboard(type: number): Observable<any> {
    return this.http.get(apiUrl + "CustomerDashboardList/" + type);
  }
  getLoanCollectionByLeadId(type: number): Observable<any> {
    return this.http.get(apiUrl + "GetLoanCollectionByLeadId/" + type);
  }
  getCustomerAccount(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/GetCustomerAccountByLeadId"
    );
  }

  GetOutStadingByLeadId(leadId: any) {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/GetOutStadingByLeadId/" +
        leadId
    );
  }

  GetSetteletementOutStandingByLeadId(leadId: any) {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/GetLoanSettleOutStadingByLeadId/" +
        leadId
    );
  }

  GetOutStadingCollectionByLeadId(leadId: any) {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/GetOutStadingCollectionByLeadId/" +
        leadId
    );
  }

  getClProcessListByClTypeWise(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/COBProcess/GetCobLogHistoryByCLtype"
    );
  }

  getClProcessListByClTypeWiseByDate(
    startDate: any,
    EndDate: any
  ): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/COBProcess/GetCobLogHistoryByCLtypeByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  getAllLoanStatus(): Observable<any> {
    return this.http.get(apiUrl + "GetAllLoanStatus");
  }

  getLoanListForTS(): Observable<any> {
    return this.http.get(apiUrl + "CustomerLoanList");
  }

  GetAllDPSEncashment(): Observable<any> {
    return this.http.get(apiUrl2 + "GetAllDPSEncashment");
  }

  GetAllDPSEncashmentBySearch(
    accountNo: string,
    accountName: string,
    encashmentDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl2 +
        "GetAllDPSEncashmentBySearch?accountName=" +
        accountName +
        "&accountNo=" +
        accountNo +
        "&encashmentDate=" +
        encashmentDate
    );
  }
  GetAlldormantAccount(): Observable<any> {
    return this.http.get(apiUrl2 + "GetAlldormantAccount");
  }

  GetAlldormantAccountBySearch(
    accountNo: string,
    accountName: string,
    dormantDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl2 +
        "GetAlldormantAccountBySearch?accountName=" +
        accountName +
        "&accountNo=" +
        accountNo +
        "&dormantDate=" +
        dormantDate
    );
  }

  GetAllDPSEncashmentByBusinessDate(): Observable<any> {
    return this.http.get(apiUrl2 + "GetAllDPSEncashmentByBusinessDate");
  }

  GetAllDPSEncashmentAlertByBusinessDate(
    startDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetAllDPSEncashmentAlertByBusinessDate?startDate=" +
        startDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  EarlyEncashmentByBusinessDate(
    startDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: number
  ): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetAllEarlyEncashment?startDate=" +
        startDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  GetAllDPSMissedPaymentByBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId:any
  ): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetAllDPSMissedPaymentByBusinessDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  GetAllDPSCloseAccountsByBusinessDate(): Observable<any> {
    return this.http.get(apiUrl2 + "GetAllDPSCloseAccountsByBusinessDate");
  }
  ApproveDPSEncashment(id: number): Observable<any> {
    return this.http.get(apiUrl2 + "ApproveDPSEncashment?id=" + id);
  }
  ApproveDormantAccount(id: number): Observable<any> {
    return this.http.get(apiUrl2 + "ApproveDormantAccount?id=" + id);
  }
  ApproveLeadByUnderwriting(leadId: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/ApproveLeadByUnderwriting?leadId=" + leadId
    );
  }

  GetDesignationwiseCustomeraccoount(parentChannelId: number): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetDesignationwiseCustomeraccoount?parentChannelId=" +
        parentChannelId
    );
  }

  GetTenureCount(parentChannelId: number): Observable<any> {
    return this.http.get(
      apiUrl2 + "GetTenureCount?parentChannelId=" + parentChannelId
    );
  }

  GetAllTenureAccountCount(parentChannelId: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/Dashboard/GetTenureWiseAccCountSumForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetChannelAccountCount(parentChannelId: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/Dashboard/GetChannelWiseAccCountSumForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetSevendaysPayReceivableAmountForAdminDash(
    parentChannelId: number
  ): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/Dashboard/SevendaysPayableReceivableAmountForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetSevendaysOpenCloseAmountForAdminDash(
    parentChannelId: number
  ): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/Dashboard/SevendaysOpeningClosingAmountForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetAllPayableDPSByTodayBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetTodaysPayableDPSbyBusinessDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  TotalPayableDPSByTodayBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetTotalPayableDPSbyDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  DPSCloseAccountsByBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      apiUrl2 +
        "GetAllDPSCloseAccountsByBusinessDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  private parentChannelIdSource = new BehaviorSubject<number>(100);
  parentChannelId$ = this.parentChannelIdSource.asObservable();
  setParentChannelId(id: number) {
    this.parentChannelIdSource.next(id);
  }
}
