<div class="card" style="width: 100%;">

  <div class="card-body">
    <div class="container-fluid">


      <div class="row">
        <div class="col-md-9">
          <h5 class="card-title page-heading" style="color: black">All Vouchers</h5>
        </div>
        <div class="col-md-2">


        </div>
        <div class="col-md-1">
          <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
            <i class="icon-arrow-left" style="font-size: 17px;"></i>
        </button>
        </div>
      </div>
      <hr style="height: 10%" />

      <div class="row">

      </div>
      <div class="row">
        <div class="col-md-12">
         <div class="form-group row">
            <div class="col-4">
              <label for="fromDate" class="col-sm-12 col-form-label text-left">From Date</label>
            <div class="col-sm-12 input-group">
              <input type="date" class="form-control"  [(ngModel)]="fromDate"  (ngModelChange)="onDateChange()" placeholder="Select From Date">
            </div>
            </div>
            <div class="col-4">
              <label for="endDate" class="col-sm-12 col-form-label text-left">To Date</label>
            <div class="col-sm-12 input-group">
              <input type="date" class="form-control"  [(ngModel)]="endDate" (ngModelChange)="onDateChange()"  placeholder="Select End Date">
            </div>
            </div>
            <div class="col-2">
              <button  class="btn btn-info" (click)="getVoucherMasterList()" style="margin-left: 5px; margin-top: 30px; float: right">Search</button>

            </div>
            <div class="col-2">
              <button  class="btn btn-success" (click)="getVoucherMasterExcelList()" style="margin-left: 5px; margin-top: 30px;float: left">Excel</button>

            </div>
          </div>

        </div>

      </div>

      <div >

        <div class="row" style="margin-bottom: 15px;">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control" style="width:16%">
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchValue" placeholder="Search" (keyup)="onSearch()">
          </div>
        </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="width: 10%;">SL No.</th>
              <th>Voucher No </th>
              <th>Ref Name </th>
              <th>Customer / Product</th>
              <th>Voucher Amount </th>
              <th>Voucher Date </th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of voucherMasterList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{i + 1}}</td>
              <td>{{item.voucherNo}}</td>
              <td>{{item.refNo}}</td>
              <td>
                {{item.productBuilder != null ? item.productBuilder?.name : item.customerAccount != null ? item.customerAccount?.customers?.customerNameEng : ''}}
                {{item.customerAccount != null ? item.customerAccount.customerAccountNo : '' }}
              </td>
              <td>{{item.voucherAmount | number}}</td>
              <td>{{item.voucherDate | date: dateFormat}}</td>


              <td>

                <button class="btn btn-info btn-sm mb-1" style="margin-left: 5px;width:100%;"
                  (click)="openVoucherDetailsModal(voucherDetails)" (click)="getVoucherDetails(item.id)">Details</button>
                <button class="btn btn-success btn-sm mb-1" style="margin-left: 5px;width:100%" (click)="OpenPDF(item.id)">Print
                  </button>
                <button (click)="openVoucherDetailsModal(breakDown1)" class="btn btn-primary btn-sm mb-1" *ngIf="item.refNo == 'Monthly Interest'" style="margin-left: 5px;width:100%" (click)="getCustomerWiseBreakDown(item.id)">Break Down</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left;">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right;">
            <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
          </div>
        </div>
      </div>




    </div>
  </div>
</div>


<ng-template #voucherDetails let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-sm" style="width: 70%!important;">
    <div class="modal-content" >
      <div class="modal-header">
        <h4 class="modal-title page-heading">Voucher Details</h4>
        <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <table id="example" class="table table-striped table-bordered table-responsive" style="width:100%">
        <thead>
          <tr>
            <th style="width: 10%;">SL No.</th>
            <th> Amount </th>
            <th>Transaction Mode </th>
            <th>GL </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of voucherDetailsInfo; let ii = index">
            <td>{{ii + 1}}</td>
            <td>{{data.amount | number}}</td>
            <td>{{data.transectionMode.modeName}}</td>
            <td>{{data.ledgerRelation?.ledger?.accountName}}</td>
          </tr>
        </tbody>
      </table>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>



<ng-template #breakDown1 let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-sm">
    <div class="modal-content" >
      <div class="modal-header">
        <h4 class="modal-title">Voucher BreakDown</h4>
        <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <table id="example" class="table table-striped table-bordered table-responsive" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Customer No</th>
            <th>Customer Name</th>
            <th>Account No</th>
            <th>Installment</th>
            <th>Int. Charged</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of customerWiseBreakdown; let ii = index">
            <td>{{ii + 1}}</td>
            <td>{{data.customeR_CODE}}</td>
            <td>{{data.customeR_NAME_ENG}}</td>
            <td>{{data.accounT_NO}}</td>
            <td class="text-right">{{data.installment}}</td>
            <td class="text-right">{{data.interesT_CHARGED}}</td>
            <td>
              <button (click)="openDailyBreakDown(breakDown2)" class="btn btn-primary btn-sm mb-1" style="margin-left: 5px;width:100%" (click)="getDailyBreakDown(data.voucherId, data.accId)">Daily Break Down</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>






<ng-template #breakDown2 let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-sm">
    <div class="modal-content" >
      <div class="modal-header">
        <h4 class="modal-title">Daily BreakDown</h4>
        <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <table id="example" class="table table-striped table-bordered table-responsive" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Process Date</th>
            <th>Interest Charged</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of dailyBreakDown; let ii = index">
            <td>{{ii + 1}}</td>
            <td>{{data.procesS_DATE}}</td>
            <td>{{data.interesT_CHARGED}}</td>
          </tr>
        </tbody>
      </table>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>
