<section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">

      <div class="card-body">
        <div class="container-fluid">



          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title" style="color: black">Business Date Info</h5>

            </div>
            <div class="col-md-2">
               
              <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary btn-gotolist"
   (click)="onClickShowSaveForm('saveList')"
   style="float:right; margin-right:5px;margin-left: 8px;">List View</button>
            </div>
            <div class="col-md-1">
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                <i class="icon-arrow-left" style="font-size: 17px;"></i>
            </button>
            </div>
          </div>


           
          <hr>
          <form [formGroup]="frmGroup">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Business Date</label>
                  <div class="col-sm-8">
                    <input type="date" class="form-control" name="BusinessDate" formControlName="BusinessDate"
                      placeholder="Account Code">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Business Closing Date</label>
                  <div class="col-sm-8">
                    <input type="date" class="form-control" name="BusinessClosingDate" formControlName="BusinessClosingDate"
                      placeholder="Account Code">
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Status </label>
                  <div class="col-sm-8">
                    <select class="form-control"  formControlName="statusId">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of statusList">
                        <option [value]="item.id">{{item.statusName}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div> -->
                <div class="form-group row">
                  <label for="ActiveStatus" class="col-sm-4 col-form-label">Active Status<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">


                    <select class="form-control" formControlName="ActiveStatus" placeholder="Active Status">
                      <option value="select">Select</option>
                      <option value="0">Close</option>
                      <option value="1">Open</option>
                      <option value="2">Locked</option>
                      <!-- <option value="3">Close</option> -->
                    </select>
                  </div>
                </div>


              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label for="isCOB" class="col-sm-4 col-form-label">IsCOB<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">


                    <select class="form-control" formControlName="isCOB" placeholder="isCOB">
                      <option value="select">Select</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="isMonthCOB" class="col-sm-4 col-form-label">Is Month COB<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">

                    <select class="form-control" formControlName="isMonthCOB" placeholder="isMonthCOB">
                      <option value="select">Select</option>
                      <option value="1">YES</option>
                      <option value="0">NO</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Remarks</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="Remarks" formControlName="Remarks"
                      placeholder="Remarks">
                  </div>
                </div>
              </div>
            </div>
            <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success btn-add"
              style="float:right; margin-top:5px;">Save </button>

          </form>
        </div>

      </div>


    </div>
    <div class="card" *ngIf="showSaveForm==='saveList'">
      <div class="card-body">
        <div class="container-fluid">


          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title" style="color: black">Business Date List</h5>

            </div>
            <div class="col-md-2">
               
              <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary btn-gotoadd"
                (click)="onClickShowSaveForm('saveForm')"
                style="float:right; margin-right:5px;margin-left: 8px;">Add New</button>
            </div>
            <div class="col-md-1">
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                <i class="icon-arrow-left" style="font-size: 17px;"></i>
            </button>
            </div>
          </div>


          
          
          <hr>
          <div class="row">
            <div class="col-md-6" style="text-align: left;">
              <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
                <option value="5">5</option>
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="col-md-6 form-group" style="text-align: right;">
              <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchControl" (keyup)="onSearch()">
            </div>
          </div>
          <table id="example" class="table table-bordered table-sm" style="width: 100%;">
            <thead>
              <tr>
                <th>SL No.</th>
                <th>Business Date</th>
                <th>Business Closing Date</th>
                <th>Active Status</th>
                <th>IsCOB</th>
                <th>Is Month COB</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let item of BusinessDateList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index;"   [ngClass]="{'active-row': item.activeStatus === 1}">
                <td>{{ ( currentPage - 1 ) * itemsPerPage +  i + 1 }}</td>
                <td>{{item.business_Date| date: dateFormat}}</td>
                <td>{{item.businessClosingDate| date: dateFormat}}</td>
                <td>{{item.activeStatus==0?'Close':item.activeStatus==1?'Open':item.activeStatus==2?'Locked':'' }}</td>
                <td>{{item.isCOB==1?'Yes':'No'}}</td>
                <td>{{item.isMonthCOB==1?'Yes':'No'}}</td>
                <td>{{item.remarks}}</td>
                <td>
                  <button class="btn btn-primary btn-sm" *ngIf="item.activeStatus == 1" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                  <button [disabled]="isEdit" *ngIf="item.activeStatus == 1" class="btn btn-danger btn-sm" style="margin-left: 5px;"
                    (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-md-6" style="text-align: left;">
              <span>Showing {{currentItems}} of {{totalItems}} entries</span>
            </div>
            <div class="col-md-6" style="text-align: right;">
              <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
