<div class="card">
  <div class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <h5 class="card-title page-heading" style="color: black">Thumb Rule</h5>
          <p class="note pl-1">Set Global Rules</p>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-1">
          <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
            <i class="icon-arrow-left" style="font-size: 17px;"></i>
          </button>
        </div>
      </div>
      <hr style="height: 10%" />
      <div class="mt-4">
        <form>
          <div class="box">
            <div class="form-group row">
              <div class="col-md-6">
                <label for="orgName">Organization Name:</label>
                <input type="text" [(ngModel)]="thumbRUle.orgName" name="orgName" id="orgName" class="form-control">
              </div>
              <div class="col-md-6">
                <label for="orgAddress">Organization Address:</label>
                <input type="text" [(ngModel)]="thumbRUle.orgAddress" name="orgAddress" id="orgAddress" class="form-control">
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="orgPhone">Organization Phone:</label>
                <input type="text" [(ngModel)]="thumbRUle.orgPhone" name="orgPhone" id="orgPhone" class="form-control">
              </div>
              <div class="col-md-6">
                <label for="orgEmail">Organization Email:</label>
                <input type="text" [(ngModel)]="thumbRUle.orgEmail" name="orgEmail" id="orgEmail" class="form-control">
              </div>
            </div>
          </div>

          <hr>
          <div class="box">
            <div class="form-group row">


              <div class="col-md-6">
                <label for="reportType">Report Type:</label>
                <select id="reportType" [(ngModel)]="thumbRUle.reportType" name="reportType" class="form-control" required>
                  <option value="">Choose A Type</option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                  <option value="Internal Confidential">Internal Confidential</option>
                  <option value="External Confidential">External Confidential</option>
                  <option value="Regulatory Report">Regulatory Report</option>
                  <option value="System Generated Report">System Generated Report</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="dpsAccountPrefix">DPS Account Format:</label>
                <div class="container row">
                  <div class="col-md-4">
                    <input type="text" [(ngModel)]="thumbRUle.dpsAccountPrefix" name="dpsAccountPrefix" class="form-control" placeholder="Account Prefix">
                  </div>
                  <div class="col-md-4">
                    <input type="text" [(ngModel)]="thumbRUle.dpsAccountMid" readonly name="dpsAccountMid" class="form-control" placeholder="Account Mid">
                  </div>
                  <div class="col-md-4">
                    <input type="text" [(ngModel)]="thumbRUle.dpsAccountSuffix" readonly name="dpsAccountSuffix" class="form-control" placeholder="Account Suffix">
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="dateFormat">Date Format:</label>
                <select id="dateFormat" [(ngModel)]="thumbRUle.dateFormat" name="dateFormat" class="form-control" required>
                  <option [ngValue]="format" *ngFor="let format of dateFormats">{{format}}</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="manualFile">Upload Manual File:</label>
                <input type="file" (change)="onFileChange($event)" id="manualFile" class="form-control">
              </div>
            </div>
          </div>


          <hr>
          <div class="box">
            <div class="form-group row">
              <div class="col-md-6">
                <label for="sessionTimeMin">Session Timeout (Minute):</label>
                <input type="hidden" [(ngModel)]="thumbRUle.id" class="form-control">
                <input type="text" [(ngModel)]="thumbRUle.sessionTimeMin" name="sessionTimeMin" id="sessionTimeMin" class="form-control">
              </div>
              <div class="col-md-6">
                <label for="allowNewTab">Allow New Tab:</label>
                <select id="allowNewTab" [(ngModel)]="thumbRUle.allowNewTab" name="allowNewTab" class="form-control" required>
                  <option [ngValue]="false">No</option>
                  <option [ngValue]="true">Yes</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="allowMultipleLogin">Allow Multiple Login:</label>
                <select id="allowMultipleLogin" [(ngModel)]="thumbRUle.allowMultipleLogin" name="allowMultipleLogin" class="form-control" required>
                  <option [ngValue]="false">No</option>
                  <option [ngValue]="true">Yes</option>
                </select>
              </div>
            </div>
          </div>

          <hr>
          <div class="box">
            <div class="form-group row">
              <div class="col-md-6">
                <label for="isEODAutoProcess">Auto EOD Process:</label>
                <select id="isEODAutoProcess" [(ngModel)]="thumbRUle.isEODAutoProcess" name="isEODAutoProcess" class="form-control" (change)="onAutoCOBChange($event)" required>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="col-md-6" *ngIf="thumbRUle.isEODAutoProcess == 1">
                <label for="autoProcessTime">EOD Process Time:</label>
                <input type="time" [(ngModel)]="thumbRUle.autoProcessTime" class="form-control">
              </div>
            </div>
          </div>

          <hr>

          <div class="box">
            <div class="form-group row">
              <div class="col-md-6">
                <label for="enableBanglaSMS">Bangla SMS:</label>
                <select id="enableBanglaSMS" [(ngModel)]="thumbRUle.enableBanglaSMS" name="enableBanglaSMS" class="form-control" required>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="enableEnglishSMS">English SMS:</label>
                <select id="enableEnglishSMS" [(ngModel)]="thumbRUle.enableEnglishSMS" name="enableEnglishSMS" class="form-control" required>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="enableBanglaEmail">Bangla Email:</label>
                <select id="enableBanglaEmail" [(ngModel)]="thumbRUle.enableBanglaEmail" name="enableBanglaEmail" class="form-control" required>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="enableEnglishEmail">English Email:</label>
                <select id="enableEnglishEmail" [(ngModel)]="thumbRUle.enableEnglishEmail" name="enableEnglishEmail" class="form-control" required>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
          </div>


          <hr>
          <div class="box">
            <div class="form-group row">
              <div class="col-md-6">
                <label for="scheduler">Scheduler: <span style="background-color: yellow; font-weight: bold;">(Current Time: {{serverTime}})</span></label>
                <p class="note">Configure a schedule for direct debit time slots.</p>

                <div class="input-group mb-2">
                  <input type="time" [(ngModel)]="newSchedulerTime" class="form-control" style="width: 120px;" placeholder="HH:MM" />
                  <button type="button" class="btn btn-primary" (click)="addSchedulerTime()">Add</button>
                </div>

                <div class="d-flex flex-wrap mb-2">
                  <ng-container *ngFor="let time of thumbRUle.scheduler; let i = index">
                    <span class="badge badge-primary m-1 time-box" [@fadeIn]="thumbRUle.scheduler.length">
                      {{ time }}
                      <button type="button" class="btn-close btn-close-white" (click)="removeSchedulerTime(i)" aria-label="Remove">&times;</button>
                    </span>
                  </ng-container>
                </div>

              </div>

              <div class="col-md-6">
                <label for="scheduler">Token Validate Time: <span style="background-color: yellow; font-weight: bold;">(Current Time: {{serverTime}})</span></label>
                <p class="note">Configure a schedule for token validation time slots.</p>

                <div class="input-group mb-2">
                  <input type="time" [(ngModel)]="newSchedulerTimeE" class="form-control" style="width: 120px;" placeholder="HH:MM" />
                  <button type="button" class="btn btn-primary" (click)="addSchedulerTimeE()">Add</button>
                </div>

                <div class="d-flex flex-wrap mb-2">
                  <ng-container *ngFor="let time of thumbRUle.eligibility; let i = index">
                    <span class="badge badge-primary m-1 time-box" [@fadeIn]="thumbRUle.eligibility.length">
                      {{ time }}
                      <button type="button" class="btn-close btn-close-white" (click)="removeSchedulerTimeE(i)" aria-label="Remove">&times;</button>
                    </span>
                  </ng-container>
                </div>

              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="eligibilityFreq">Token Validation Frequency:</label>
                <p class="note">Policy of validating Nagad Tokens.</p>
                <select id="eligibilityFreq" [(ngModel)]="thumbRUle.eligibilityFreq" name="eligibilityFreq" class="form-control" required>
                  <option value="DAILY">Check Daily</option>
                  <option value="ONCE" selected>Check Once in 5 days</option>
                </select>
              </div>
            </div>
          </div>


          <hr>
          <div class="box">
            <div class="form-group row">
              <div class="col-md-6">
                <label for="tapPaymentFrequency">Payment Frequency (TAP):</label>
                <p class="note">After how many minutes does TAP Payments synchronize?</p>
                <input type="text" placeholder="Payment Frequency in Minute" [(ngModel)]="thumbRUle.tapPaymentFrequency" name="tapPaymentFrequency" id="tapPaymentFrequency" class="form-control" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46" >
              </div>
              <div class="col-md-6">
                <label for="tapEncashmentFrequency">Encashment Frquency (TAP):</label>
                <p class="note">After how many minutes does TAP Encashment synchronize?</p>
                <input type="text" placeholder="Encashment Frequency in Minute" [(ngModel)]="thumbRUle.tapEncashmentFrequency" name="tapEncashmentFrequency" id="tapEncashmentFrequency" class="form-control" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46" >
              </div>
            </div>
          </div>

          <hr>
          <div class="box">
            <div class="form-group row">
              <div class="col-md-6">
                <label for="autoEncashment">Auto Encashment:</label>
                <p class="note">Do you want to enable encashment without approval?</p>
                <select id="autoEncashment" [(ngModel)]="thumbRUle.autoEncashment" name="autoEncashment" class="form-control" required>
                  <option value="0">No</option>
                  <option value="1" selected>Yes</option>
                </select>
              </div>

              <div class="col-md-6">
                <label for="dormantMonths">Dormant (Months):</label>
                <p class="note">After how many months are accounts considered dormant?</p>
                <input type="text" placeholder="Dormant Months in Number" [(ngModel)]="thumbRUle.dormantMonths" name="dormantMonths" id="dormantMonths" class="form-control" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46" >
              </div>
            </div>
          </div>


          <button class="btn btn-success btn-add" (click)="Submit()">Update</button>
          <button class="btn btn-info ml-1" (click)="OpenPDF()">PDF</button>
        </form>
      </div>
    </div>
  </div>
</div>
