import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';


const apiUrl=environment.baseUrl;

@Injectable({
  providedIn: "root",
})
export class RoleService {
  constructor(private http: HttpClient) {}

  save(model: any) {
    return this.http.post(apiUrl + "/api/Auth/SaveRole", model);
  }

  getlist(): Observable<any> {
    return this.http.get(apiUrl + "/api/Auth/GetAllRole");
  }

  GetUserCheckByPhone(phone: any) {
    return this.http.get(apiUrl + "/api/Auth/GetUserInfoByPhone/" + phone);
  }
}
