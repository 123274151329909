import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { CustomerService } from 'app/customer/service/customer.service';

@Component({
  selector: 'app-operational-dashboard',
  templateUrl: './operational-dashboard.component.html',
  styleUrls: ['./operational-dashboard.component.scss']
})
export class OperationalDashboardComponent implements OnInit {

   waitingApplication: number;
  forwardedApplication: number;
  approvedLoan: number;
  rejectedLoan: number;
  waitingForDisburse: number;
  disbursmentList: number;
  finalDisbursList: number;

  LoanApplication: number;
  dpsAccount: number;
  FdrAccount: number;
  savingAccount: number;
  registerCustomer: number;

  customerList: any[] = [];
  accId: any;
  customerLoan: any[] = [];
  constructor(
    private customerService: CustomerService,
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private pdfService: PdfService,
  ) { }

  ngOnInit(): void {
    this.getLoanLeadInfoForAdmin();
    this.getAccountCountForAdmin();
    this.getCustomerList();
  }


  getCustomerList() {
    this.customerService.getlist().subscribe(res => {
      this.customerList = res;
      this.changeDetectorRef.detectChanges();
    })
  }

  getLoanLeadInfoForAdmin() {
    this._serviceDashboard.GetLoanInfoForAdminDeshBoard().subscribe({
      next: (res: any) => {

        this.waitingApplication = res.waitingApplication;
        this.forwardedApplication = res.forwardedApplication;
        this.approvedLoan = res.approvedLoan;
        this.rejectedLoan = res.rejectedLoan;
        this.waitingForDisburse = res.waitingForDisburse;
        this.disbursmentList = res.disbursmentList;
        this.finalDisbursList = res.finalDisbursmentList;
        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {

      }
    });

  }

  getAccountCountForAdmin() {
    this._serviceDashboard.GetAccountCountAdminDeshBoardOther().subscribe({
      next: (res: any) => {
        this.registerCustomer = res.registerCustomer;
        this.savingAccount = res.savingAccount;
        this.FdrAccount = res.fdrAccount;
        this.dpsAccount = res.dpsAccount;
        this.LoanApplication = res.loanApplication;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });

  }

  OpenPDF(customerId: any) {
    this.pdfService.customerDetailsReport(customerId).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

  PrintKyc() {
    this.pdfService.OpenKycPdf(this.accId).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    })
  }




  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error('Method not implemented.');
  }
  onUnderProClicked(type: number) {
    this.router.navigate(['/dashboard-custom/cutomer-lead']);
    this.changeDetectorRef.detectChanges();
  }
  onWaiting(st: number) {
    this.router.navigate(['/dashboard/waiting-application']);
  }
  onForwarded(st: number) {
    this.router.navigate(['/dashboard/forward-loan-app']);
  }
  onApproved(st: number) {
    this.router.navigate(['/dashboard/approved-loan-app']);
  }
  onRejected(st: number) {
    this.router.navigate(['/dashboard/rejected-loan-app']);
  }
  onDisburse(st: number) {
    this.router.navigate(['/dashboard/application-disburse-list']);
  }
  ApprovedDisburse(st: number) {
    this.router.navigate(['/dashboard/app-approve-disburse']);
  }
  FinalDisburse(st: number) {
    this.router.navigate(['/dashboard/final-disburse-list']);
  }

  

  

}
