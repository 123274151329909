<div class="card">
     
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9">
            <h5 class="card-title page-heading" style="color: black">
              Batch Master
            </h5>
             
          </div>
          <div class="col-md-2">
             
            
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
              <i class="icon-arrow-left" style="font-size: 17px;"></i>
          </button>
          </div>
        </div>
        <hr style="height: 10%" />
        
        <div class="form-group row">
          <label for="batchCode" class="col-md-2">Batch Code</label>
          <input type="hidden" [(ngModel)]="batchData.Id">
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              id="batchCode"
              [(ngModel)]="batchData.batchCode"
            />
          </div>
  
          <label for="batchName" class="col-md-2">Batch Name</label>
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              id="batchName"
              [(ngModel)]="batchData.batchName"
            />
          </div>
        </div>
        <div class="form-group row pull-right">
          <button class="btn btn-success btn-sm" (click)="insertBatch()">Insert Batch</button>
        </div>
      </div>
       
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-header"><b>Batch List</b></div>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th>Batch Code</th>
            <th>Batch Name</th>
            <th>Actions</th> <!-- Added column for actions -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of batchList">
            <td>{{ item.batchCode }}</td>
            <td>{{ item.batchName }}</td>
            <td>
              <button class="btn btn-primary btn-sm" (click)="editBatch(item)">Edit</button>
              <!-- <button class="btn btn-danger btn-sm" (click)="deleteBatch(item)">Delete</button> -->
              <button class="btn btn-info btn-sm" (click)="viewBatch(item)">View</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>



  <!-- <ng-container *ngTemplateOutlet="modalContent"></ng-container> -->

