import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { FdrdetailsService } from 'app/fdr-account/fdrdetails.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';
import { DatePipe, Location } from "@angular/common";
import { LayoutService } from 'app/shared/services/layout.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-dps-today-receivcble",
  templateUrl: "./dps-today-receivcble.component.html",
  styleUrls: ["./dps-today-receivcble.component.scss"],
})
export class DpsTodayReceivcbleComponent implements OnInit {
  parentChannelId: number;
  receivablepsList: any[] = [];
  dateFormat: string = "";
  startDate: any;
  EndDate: any;
  businessDate: any;
  formattedStartDate: string;
  formattedEndDate: string;

  //////
  customername: string = "";
  customercode: string = "";
  mobileNumber: string = "";
  accountNo: string = "";

  nidNo: string = "";
  typeId: number = 0;
  selectedFilterType: number = 0;

  showMobileNumberField: boolean = false;
  showAccountField: boolean = false;
  showCustomerCodeField: boolean = false;
  showCustomerNameField: boolean = false;

  constructor(
    private pdfService: PdfService,
    private _fdrService: FdrdetailsService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetect: ChangeDetectorRef,
    private shared: SharedService,
    private location: Location,
    private layoutService: LayoutService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCurrentBusinessDate();
    //this.GetAllPayableDPS();
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.thumbRule.dateFormat;
    this._serviceDashboard.parentChannelId$.subscribe((id) => {
      this.parentChannelId = id;
    });
  }
  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;
      this.startDate = this.datePipe.transform(this.businessDate, "yyyy-MM-dd");
      this.EndDate = this.datePipe.transform(this.businessDate, "yyyy-MM-dd");
      this.GetAllReceivableDPS();
    });
  }
  formatDates() {
    this.formattedStartDate = this.datePipe.transform(
      this.startDate,
      "dd-MM-yyyy"
    );
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }

  onDateChange() {
    this.formatDates();
    this.GetAllReceivableDPS();
  }

  GetAllReceivableDPS() {
    this.receivablepsList = [];
    if (!this.startDate) {
      this.formattedStartDate = this.businessDate;
    } else {
      this.formattedStartDate = this.datePipe.transform(
        this.startDate,
        "yyyy-MM-dd"
      );
    }

    if (!this.EndDate) {
      this.formattedEndDate = this.businessDate;
    } else {
      this.formattedEndDate = this.datePipe.transform(
        this.EndDate,
        "yyyy-MM-dd"
      );
    }

    this._serviceDashboard
      .TodayDPSReceivableList(
        this.formattedStartDate,
        this.formattedEndDate,
        this.customername,
        this.accountNo,
        this.mobileNumber,
        this.parentChannelId
      )
      .subscribe((res) => {
        if (res.length > 0) {
          this.receivablepsList = res;

          this.changeDetect.detectChanges();
        }
      });
  }

  goBack() {
    this.location.back();
  }

  //////////////////////////////////
  SearchByParameter() {
    this.typeId;
  }

  onFilterTypeChange(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showCustomerNameField = true;
        this.customername = "";
        break;
      case "3":
        this.showMobileNumberField = true;
        break;

      case "4":
        this.showAccountField = true;
        break;
    }
  }

  deleteFilterTypeFields(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showCustomerNameField = false;
        this.customername = "";
        break;
      case "3":
        this.showMobileNumberField = false;
        this.mobileNumber = "";
        break;
      case "4":
        this.showAccountField = false;
        this.accountNo = "";
        break;
    }
  }

  refreshFilter(): void {
    this.showCustomerNameField = false;
    this.showMobileNumberField = false;
    this.showAccountField = false;

    this.accountNo = "";
    this.mobileNumber = "";
    this.customername = "";
    this.typeId = 0;

    this.router.navigate(["/dps-account/dps-today-receivcble"], {
      skipLocationChange: true,
    });
  }

  DPSExcelReceivable() {
    
    if (!this.startDate) {
      this.formattedStartDate = this.businessDate;
    } else {
      this.formattedStartDate = this.datePipe.transform(
        this.startDate,
        "yyyy-MM-dd"
      );
    }

    if (!this.EndDate) {
      this.formattedEndDate = this.businessDate;
    } else {
      this.formattedEndDate = this.datePipe.transform(
        this.EndDate,
        "yyyy-MM-dd"
      );
    }
    this.pdfService
      .dPSReceivableExcel(
        this.formattedStartDate,
        this.formattedEndDate,
        this.customername,
        this.accountNo,
        this.mobileNumber,
        this.parentChannelId)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }
}