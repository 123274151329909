import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardCustomService } from '../dashboard-custom.service';
import { Location } from "@angular/common";
import { PdfService } from 'app/pdf-reporting/pdf.service';


@Component({
  selector: "app-today-received-list",
  templateUrl: "./today-received-list.component.html",
  styleUrls: ["./today-received-list.component.scss"],
})
export class TodayReceivedListComponent implements OnInit {
  dataList: any[] = [];

  constructor(
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private pdfService: PdfService
  ) {}

  ngOnInit(): void {
    this.GetTodayReceivedList();
  }

  goBack() {
    this.location.back();
  }
  GetTodayReceivedList() {
    this._serviceDashboard.TodayReceivedList().subscribe((res: any[]) => {
      this.dataList = res;
      
      this.changeDetectorRef.detectChanges();
    });
  }

  OpenPDF(leadId) {
    this.pdfService.OpenCollectionPdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
}
