 
  <div class="card">
    <div class="card-body">
      <div class="container-fluid">

        <div class="row">
          <div class="col-md-9">
            <h5 class="card-title page-heading" style="color: black">
              Add New Format
            </h5>
             
          </div>
          <div class="col-md-2">
             
             
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
              <i class="icon-arrow-left" style="font-size: 17px;"></i>
          </button>
          </div>
        </div>
        <hr style="height: 10%" />

          <!-- Form for adding/editing -->
      <div class="mt-4">
        <!-- <h3>Add New Format</h3> -->
        <form (ngSubmit)="saveFormat()">
          <div class="form-group row">
            <div class="col-md-6">
              <input type="hidden" id="id" [(ngModel)]="newFormat.id" name="id" class="form-control" required>

              <label for="particular">Particular:</label>
              <select id="particular" [(ngModel)]="newFormat.particular" (change)="changeFormats($event.target.value)" name="particular" class="form-control" required>
                <option value="">Choose</option>
                <option value="DPS CREATE">DPS CREATE</option>
                <option value="DPS PAYMENT">DPS PAYMENT</option>
                <option value="EARLY ENCASHMENT">EARLY ENCASHMENT</option>
                <option value="MATURITY CONFIRMATION">MATURITY CONFIRMATION</option>
                <option value="MFS ALERT">MFS ALERT</option>
                <option value="OVERDUE ALERT">OVERDUE ALERT</option>
                <option value="PREMATURE ALERT">PREMATURE ALERT</option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="type">Type:</label>
              <select id="type" [(ngModel)]="newFormat.type" name="type" class="form-control" required>
                <option value="SMS">SMS</option>
                <option value="EMAIL">EMAIL</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="lnCode">Language:</label>
              <select id="lnCode" [(ngModel)]="newFormat.lnCode" name="lnCode" class="form-control" required>
                <option value="BN">Bangla</option>
                <option value="EN">English</option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="status">Status:</label>
              <select id="status" [(ngModel)]="newFormat.status" name="status" class="form-control" required>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="subject">Subject:</label>
              <input type="text" id="subject" [(ngModel)]="newFormat.subject" name="subject" class="form-control" required>
          </div>
          <div class="form-group">
            <label for="subject">Body:</label>
            <textarea id="body" [(ngModel)]="newFormat.body" name="body" class="form-control" required cols="30" rows="5"></textarea>
            <p style="color: darkred; margin: 0; font-weight: bold; font-size: 11px;">{{placeholder}}</p>
            <small style="margin: 0; font-size: 12px;">Replace parameters with following, </small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'[Amount]'"></span> = Amount</small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'[Account]'"></span> = Account</small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'[Interest Rate]'"></span> = Interest Rate</small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'[Fees]'"></span> = Fees</small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'[Charges]'"></span> = Charges</small><br>
          </div>
          <div class="form-check">
            <input type="checkbox" id="isHTML" [(ngModel)]="newFormat.isHTML" name="isHTML" class="form-check-input">
            <label class="form-check-label" for="isHTML">Is HTML</label>
          </div>
          <button type="submit" class="btn btn-success btn-add mt-2">Save</button>
        </form>
      </div>
      </div>
       
    </div>

    <div class="card-body">
      <div class="container-fluid">
        <!-- List of formats -->
      <div class="mt-5">
        <h3 class="card-title page-heading" style="color: black">
          All Formats
        </h3>
         
        <table class="table">
          <thead>
            <tr>
              <th>Particular</th>
              <th>Subject</th>
              <th>Body</th>
              <th>Is HTML</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let format of formats">
              <td style="text-align: center;">
                <p><b>{{ format.particular }}</b></p>
                <p><b>{{ format.type }}</b></p>
                <p><b>({{ format.lnCode }})</b></p>
              </td>
              <td>{{ format.subject }}</td>
              <td style="font-size: 12px;">{{ format.body }}</td>
              <td>{{ format.isHTML ? 'Yes' : 'No' }}</td>
              <td>{{ format.status === 1 ? 'Active' : 'Inactive' }}</td>
              <td>
                <button (click)="editFormat(format)" class="btn btn-sm btn-primary">Edit</button>
                <button (click)="deleteFormat(format.id)" class="btn btn-sm btn-danger">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
       
    </div>
  </div>
 
