import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupComponent } from './group/group.component';
import { SubGroupComponent } from './sub-group/sub-group.component';
import { LedgerComponent } from './ledger/ledger.component';
import { CashPostingComponent } from './cash-posting/cash-posting.component';
import { CommonModule } from '@angular/common';
import { AccountBalanceComponent } from './account-balance/account-balance.component';
import { VoucherMasterComponent } from './voucher-master/voucher-master.component';
import { CashApprovalComponent } from './cash-approval/cash-approval.component';
import { CashApproveComponent } from './cash-approve/cash-approve.component';
import { CashReceivedComponent } from './cash-received/cash-received.component';
import { FundTransferComponent } from './fund-transfer/fund-transfer.component';
import { LedgerListComponent } from './ledger-list/ledger-list.component';
import { GlGenerateComponent } from './gl-generate/gl-generate.component';
import { BusinessDateComponent } from './business-date/business-date.component';
import { HolidayComponent } from './holiday/holiday.component';
import { AddMoneyComponent } from './add-money/add-money.component';
import { TransectionLimitComponent } from './transection-limit/transection-limit.component';
import { OtherBankComponent } from './other-bank/other-bank.component';
import { NpsbComponent } from './npsb/npsb.component';
import { OtherAccountComponent } from './other-account/other-account.component';
import { OwnAccountFundTransferComponent } from './own-account-fund-transfer/own-account-fund-transfer.component';
import { BeftnComponent } from './beftn/beftn.component';
import { MobileRechargeComponent } from './mobile-recharge/mobile-recharge.component';
import { WasaComponent } from './wasa/wasa.component';
import { BrebComponent } from './breb/breb.component';
import { DpdcComponent } from './dpdc/dpdc.component';
import { DescoComponent } from './desco/desco.component';
import { NescoComponent } from './nesco/nesco.component';
import { BgdclComponent } from './bgdcl/bgdcl.component';
import { BKashComponent } from './b-kash/b-kash.component';
import { NagadComponent } from './nagad/nagad.component';
import { RocketComponent } from './rocket/rocket.component';
import { BanglaQrComponent } from './bangla-qr/bangla-qr.component';
import { UpayComponent } from './upay/upay.component';
import { FundTransferListComponent } from './fund-transfer-list/fund-transfer-list.component';
import { PaymentVoucherComponent } from './payment-voucher/payment-voucher.component';
import { ReceiveVoucherComponent } from './receive-voucher/receive-voucher.component';
import { JournalVoucherComponent } from './journal-voucher/journal-voucher.component';
import { ContraVoucherComponent } from './contra-voucher/contra-voucher.component';
import { TreeTableTestComponent } from './tree-table-test/tree-table-test.component';
import { BatchProcessMonitorComponent } from 'app/dayclose-process/batch-process-monitor/batch-process-monitor.component';
import { BatchProcessComponent } from 'app/dayclose-process/batch-process/batch-process.component';
import { BankUltLedgerMappingComponent } from './bank-ult-ledger-mapping/bank-ult-ledger-mapping.component';
import { TransactioneventComponent } from './transactionevent/transactionevent.component';
import { SubLedgerEntryComponent } from './sub-ledger-entry/sub-ledger-entry.component';
import { LedgerEditComponent } from './ledger-edit/ledger-edit.component';
import { ImportFundTransferComponent } from './import-fund-transfer/import-fund-transfer.component';


const routes: Routes = [
  {
    path: "group",
    component: GroupComponent,
    data: { title: "Account Group" },
  },
  {
    path: "sub-group",
    component: SubGroupComponent,
    data: { title: "Account Sub-Group" },
  },
  {
    path: "ledger",
    component: LedgerComponent,
    data: { title: "Account Ledger" },
  },
  {
    path: "cash",
    component: CashPostingComponent,
    data: { title: "Cash Deposit" },
  },
  {
    path: "account-balance",
    component: AccountBalanceComponent,
    data: { title: "Cash Deposit" },
  },
  {
    path: "voucher-info",
    component: VoucherMasterComponent,
    data: { title: "VoucherList" },
  },
  {
    path: "cash-approval",
    component: CashApprovalComponent,
    data: { title: "cashapproval" },
  },
  {
    path: "cash-approve",
    component: CashApproveComponent,
    data: { title: "Voucher Pending" },
  },
  {
    path: "cash-received",
    component: CashReceivedComponent,
    data: { title: "Cash Receive" },
  },
  {
    path: "fund-transfer",
    component: FundTransferComponent,
    data: { title: "Cash Receive" },
  },
  {
    path: "import-fund-transfer",
    component: ImportFundTransferComponent,
    data: { title: "Import Fund Transfer" },
  },
  {
    path: "ledger-list",
    component: LedgerListComponent,
    data: { title: "Account Ledger List" },
  },
  //{path: '', children: [{ path: 'ledger-with-id/:parameter',component: LedgerComponent,data: { title: 'Ledger List'}},] },
  {
    path: "",
    children: [
      {
        path: "ledger-edit/:parameter",
        component: LedgerEditComponent,
        data: { title: "Ledger Edit" },
      },
    ],
  },
  {
    path: "gl-generate",
    component: GlGenerateComponent,
    data: { title: "GL Generate" },
  },
  {
    path: "business-date",
    component: BusinessDateComponent,
    data: { title: "Business Date" },
  },
  { path: "holiday", component: HolidayComponent, data: { title: "Holiday" } },
  {
    path: "add-money",
    component: AddMoneyComponent,
    data: { title: "add-money" },
  },
  {
    path: "transection-limit",
    component: TransectionLimitComponent,
    data: { title: "Transection Limit" },
  },
  {
    path: "other-bank",
    component: OtherBankComponent,
    data: { title: "Other Bank" },
  },
  { path: "npsb", component: NpsbComponent, data: { title: "NPSB" } },
  {
    path: "transection-limit",
    component: TransectionLimitComponent,
    data: { title: "Transection Limit" },
  },
  {
    path: "other-account",
    component: OtherAccountComponent,
    data: { title: "Other Account" },
  },
  {
    path: "add-money",
    component: AddMoneyComponent,
    data: { title: "add-money" },
  },
  {
    path: "own-account-fund-transfer",
    component: OwnAccountFundTransferComponent,
    data: { title: "Own Account" },
  },
  { path: "beftn", component: BeftnComponent, data: { title: "BEFTN" } },
  {
    path: "mobile-recharge",
    component: MobileRechargeComponent,
    data: { title: "Mobile Recharge" },
  },
  { path: "wasa", component: WasaComponent, data: { title: "WASA" } },
  { path: "breb", component: BrebComponent, data: { title: "BREB" } },
  { path: "dpdc", component: DpdcComponent, data: { title: "DPDC" } },
  { path: "desco", component: DescoComponent, data: { title: "DESCO" } },
  { path: "nesco", component: NescoComponent, data: { title: "NESCO" } },
  { path: "bgdcl", component: BgdclComponent, data: { title: "BGDCL" } },
  { path: "b-kash", component: BKashComponent, data: { title: "bkash" } },
  { path: "nagad", component: NagadComponent, data: { title: "Nagad" } },
  { path: "rocket", component: RocketComponent, data: { title: "Rocket" } },
  {
    path: "bangla-qr",
    component: BanglaQrComponent,
    data: { title: "Bangla QR" },
  },
  { path: "upay", component: UpayComponent, data: { title: "Upay" } },
  {
    path: "fund-transfer-list",
    component: FundTransferListComponent,
    data: { title: "Fund Transfer List" },
  },
  {
    path: "payment-voucher",
    component: PaymentVoucherComponent,
    data: { title: "Payment Voucher" },
  },
  {
    path: "receive-voucher",
    component: ReceiveVoucherComponent,
    data: { title: "Receive Voucher" },
  },
  {
    path: "journal-voucher",
    component: JournalVoucherComponent,
    data: { title: "Journal Voucher" },
  },
  {
    path: "contra-voucher",
    component: ContraVoucherComponent,
    data: { title: "Contra Voucher" },
  },
  {
    path: "tree-table",
    component: TreeTableTestComponent,
    data: { title: "Tree Table Test" },
  },
  {
    path: "business-data",
    component: BusinessDateComponent,
    data: { title: "Business Date" },
  },
  {
    path: "bank-ult-ledger-mapping",
    component: BankUltLedgerMappingComponent,
    data: { title: "bank-ult-ledger-mapping" },
  },
  {
    path: "transactionevent",
    component: TransactioneventComponent,
    data: { title: "transactionevent" },
  },
  {
    path: "sub-ledger-entry",
    component: SubLedgerEntryComponent,
    data: { title: "sub-ledger-entry" },
  },
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
