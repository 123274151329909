<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-11">
                    <h5 class="card-title page-heading" style="color: black">Pending Products</h5>
                </div>
                <!-- <div class="col" style="text-align: right;">
                     <button type="button" data-toggle="tooltip" [routerLink]="['/product/loan-product-entry']" class="btn btn-info"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to Entry</button>
                </div> -->
                <div class="col-md-1 text-right">
                    <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()">
                        <i class="icon-arrow-left" style="font-size: 17px;"></i>
                    </button>
                </div>
            </div>
            <hr>
            <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr style="background-color:  #EEF5E9;">
                        <th>SL No.</th>
                        <th>Product Category</th>
                        <th>Product Builder Code</th>
                        <th>Product Builder Name</th>
                        <th>Version</th>
                        <th>Effective Date</th>
                        <!-- <th>Routes</th> -->
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of productList; let i = index;">
                        <td>{{i + 1}}</td>
                        <td>{{item.categoryName}}</td>
                        <td>{{item.code}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.version}}</td>
                        <td style="white-space: nowrap;">{{item.effectiveDate | date:'dd MMM yyyy' }}</td>
                        <!-- <td style="padding: 10px;" style="width: 25%;">
                            <a *ngFor="let approver of item.routes; let j = index;">{{j + 1}}. {{approver.role.name}} ({{approver.updatedBy}})<br></a>
                        </td> -->
                        <!-- <td>{{item.status === 1 ? 'Active' : "in-Active"}}</td> -->
                        <td>
                          <button class="btn btn-info mb-1" style="margin-left: 5px;"
                          (click)="openBuilderDetailsModal(productBuilderDetails)" (click)="getProductDetails(item.id)"><i class="fa fa-list"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #productBuilderDetails let-c="close" let-d="dismiss">
  <div class="modal-dialog">
      <!-- Modal content goes here -->
      <div class="modal-content" style="margin-left: -8%; width: 110%;">
          <div class="modal-header">
              <h4 class="modal-title">Product Builder Details</h4>
              <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <table class="table-bordered">
              <th class="col-3">Product Name </th>
              <td class="col-3">{{ productDetails?.productInfo?.name }}</td>

              <th class="col-3">Product Category </th>
              <td class="col-3">{{ productDetails?.productInfo?.categoryName}}</td>



          </table>
          <table class="table-bordered">

              <th class="col-3">Version: </th>
              <td class="col-3">{{ productDetails?.productInfo?.version }}</td>
              <th class="col-3">Product Code: </th>
              <td class="col-3">{{ productDetails?.productInfo?.code }}</td>

          </table>

          <table class="table-bordered">
              <th class="col-3">Description: </th>
              <td class="col-3">{{ productDetails?.productInfo?.description }}</td>
              <th class="col-3">Interest Rate: </th>
              <td class="col-3">{{productDetails?.productInfo?.interestRate}} <span>%</span> </td>
          </table>

          <table class="table-bordered">
              <th class="col-3">Min Int. Rate:</th>
              <td class="col-3">{{ productDetails?.productInfo?.interestRateMin }} <span>%</span></td>
              <th class="col-3">Max Int. Rate:</th>
              <td class="col-3">{{productDetails?.productInfo?.interestRateMax}} <span>%</span> </td>
          </table>


          <table class="table-bordered">
              <th class="col-3">Minimum Amount:</th>
              <td class="col-3">{{ productDetails?.productInfo?.minAmount}}</td>
              <th class="col-3">Maximum Amount:</th>
              <td class="col-3">{{productDetails?.productInfo?.maxAmount}}</td>
          </table>





          <table class="table-bordered">
              <th class="col-3">Currency: </th>
              <td class="col-3">{{ productDetails?.productInfo?.currency?.currency }}</td>
              <th class="col-3">Day Size: </th>
              <td class="col-3">{{ productDetails?.productInfo.daySize?.name }}</td>
          </table>
          <table class="table-bordered">
              <th class="col-3">Tax Key: </th>
              <td class="col-3">{{ productDetails?.productInfo.taxKey?.taxKeyName }}</td>
              <th class="col-3">Excise Duty: </th>
               <td class="col-3">{{ productDetails?.productInfo?.exciseDuty?.exciseDutyName }}</td>
          </table>
          <table class="table-bordered">
              <th class="col-3">Interest Calculation Type</th>
              <td class="col-3">{{ productDetails?.productInfo?.interestCalculationType?.name }}</td>
              <th class="col-3">Interest Calculation Frequency: </th>
              <td class="col-3">{{productDetails?.productInfo?.interestCalculationFrequency?.name }} -
                  {{productDetails?.productInfo?.interestCalculationFrequency?.days }}
                  {{productDetails?.productInfo?.interestCalculationFrequency?.days !=null? "days" : "" }}</td>
          </table>
          <table class="table-bordered">
              <th class="col-3">Balance Type:</th>
              <td class="col-3">{{ productDetails?.productInfo?.balanceType?.name }}</td>
              <th class="col-3">Status:</th>
              <td class="col-3">
                  {{ productDetails?.productInfo?.status? "Active" :
                  "In-Active"}}
              </td>
          </table>
          <table class="table-bordered">
              <th class="col-3">Commission:</th>
              <td class="col-3">
                  <ul>
                      <li *ngFor="let item of productDetails?.productCommission">
                          {{item.commission?.code}}-{{item.commission?.name}}
                      </li>
                  </ul>
              </td>
              <th class="col-3">Premature Encashment Rate:</th>
              <td class="col-3">
                  {{productDetails?.productInfo?.bankRate}}
                  <span>%</span>
              </td>

          </table>


          <table class="table-bordered">
              <th class="col-3">Tenures:</th>
              <td class="col-3">
                  <ul>
                      <li *ngFor="let item of productDetails?.dpsTenures">
                          {{item.duration?.monthName}}
                      </li>
                  </ul>
              </td>
              <th class="col-3">Effective Date:</th>
              <td class="col-3">
                  {{productDetails?.productInfo?.effectiveDate | date}}

              </td>

          </table>

          <table class="table-bordered">
            <th class="col-3">Modification Date:</th>
            <td class="col-3">
              {{productDetails?.productInfo?.modificationDate | date}}
            </td>
            <th class="col-3"></th>
            <td class="col-3">

            </td>

          </table>
            <br>
            <br>
            <hr>
            <br>
            <br>
          <table class="table table-bordered" style="border-color:black; font-size: 13px;" >
              <thead style="background-color:lightgray;border-bottom:groove;">
                  <tr>
                      <th style="text-align:left;vertical-align:middle">Event Name</th>
                      <th style="text-align:left;vertical-align:middle">GL Name</th>
                      <th style="text-align:left;vertical-align:middle">Type</th>
                  </tr>
              </thead>
              <tbody style="color:black;border-color:black;">

                  <tr *ngFor="let item of productDetails?.productCharges">
                      <td style="text-align: left; vertical-align:middle">{{item?.productEvent?.eventName}}</td>
                      <td style="text-align: left; vertical-align:middle">{{item?.ledgerRelation?.ledger?.accountName}}</td>
                      <td style="text-align: left; vertical-align:middle">{{item?.glType == 'DR' ? 'Debit' : 'Credit'}}</td>
                  </tr>
              </tbody>
          </table>

          <table style="border: none;">
            <tr style="border: none;">
              <th style="border: 1px solid darkcyan; padding: 5px;">Comment <span style="color: darkred;">*</span></th>
              <th style="border: 1px solid darkcyan; padding: 5px;">
                <textarea
                    style="border: none;"
                    placeholder="Please Type a Comments Here..."
                    [(ngModel)]="remarks"
                    (ngModelChange)="validateInput()"
                    type="text"
                    class="form-control">
                  </textarea>
              </th>
            </tr>
            <tr>
              <th></th>
              <th style="font-size: 10px; color: darkred; padding: 5px;" *ngIf="errorMessage">
                Please enter no more than 15 characters!
              </th>
            </tr>
          </table>


          <div class="modal-footer">
              <button type="button" class="btn btn-info btn-sm" (click)="download()">Download</button>
              <button type="button" class="btn btn-success btn-sm" (click)="Approve(clickedProductId)">Approve</button>
              <button type="button" *ngIf="events.indexOf('Return') >= 0" class="btn btn-warning btn-sm" (click)="Return(clickedProductId)">Return</button>
              <button type="button" *ngIf="events.indexOf('Reject') >= 0" class="btn btn-danger btn-sm" (click)="Reject(clickedProductId)">Reject</button>
              <button type="button" class="btn btn-warning btn-sm" (click)="c('Close click')">Close</button>
          </div>
      </div>
  </div>
</ng-template>
