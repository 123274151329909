<!-- upload.component.html -->
<div class="card" style="width: 100%; max-width: 100%;">
  <div class="card-body">
    <div class="container m-0">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <form (ngSubmit)="onSubmit()" #uploadForm="ngForm" class="upload-form">
            <div class="card shadow-sm border-light">
              <div class="card-body">
                <h3 class="text-center mb-4">Upload Excel File</h3>
                <a (click)="DownloadExcelSample()" style="display: flex;
                justify-content: space-around;
                margin: 39px;" class="btn btn-info btn-sm">Download Sample</a>

                <!-- Dropzone -->
                <div
                  class="dropzone-area"
                  (dragover)="onDragOver($event)"
                  (dragleave)="onDragLeave($event)"
                  (drop)="onDrop($event)"
                  (click)="triggerFileInput()"
                >
                  <p class="dropzone-text">Drag & Drop your Excel file here or click to select</p>
                  <input
                    type="file"
                    id="file"
                    (change)="onFileChange($event)"
                    accept=".xlsx, .xls"
                    class="d-none"
                  />
                </div>

                <!-- Show selected file name -->
                <div *ngIf="file" class="file-info mt-3">
                  <p><strong>Selected File:</strong> {{ file.name }}</p>
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    [disabled]="!file"
                    class="btn btn-primary w-100 mt-3"
                  >
                    Load
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div *ngIf="uploadStatus" class="mt-3">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Dr Account</th>
            <th>Cr Account</th>
            <th>Voucher Date</th>
            <th>Amount</th>
            <th>Narration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploadedData">
            <td>{{item.drAccount}}</td>
            <td>{{item.crAccount}}</td>
            <td>{{item.voucherDate | date}}</td>
            <td>{{item.amount}}</td>
            <td>{{item.narration}}</td>
            <td>
              <a (click)="OpenVoucherModal(item)" (click)="OpenVoucherDetails(voucherDetails)" class="btn btn-info btn-sm">View</a>
            </td>
          </tr>
        </tbody>
      </table>
      <a (click)="UploadAllVouchers(uniqueId)"class="btn btn-success float-end">Upload All</a>
    </div>
  </div>
</div>





<ng-template #voucherDetails let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-sm" style="width: 70%!important;">
    <div class="modal-content" >
      <div class="modal-header">
        <h4 style="width: 100%;">Voucher Details</h4>
        <button type="button" class="close" style="width: 10px;" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <table id="example" class="table table-striped table-bordered table-responsive" style="width:100%">
        <thead>
          <tr>
            <th colspan="4" class="text-right">Voucher Date: {{singleVoucherData.voucherDate | date}}</th>
          </tr>
          <tr>
            <th style="width: 10%;">GL A/C No</th>
            <th>Account Name</th>
            <th>Amount Dr.</th>
            <th>Amount Cr.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{singleVoucherData.drAccount}}</td>
            <td>
              <p>{{singleVoucherData.drAccountName}}</p>
              <p><strong>Narration:</strong> {{singleVoucherData.narration}}</p>
            </td>
            <td class="text-right">{{singleVoucherData.amount | number}}</td>
            <td class="text-right">{{0 | number}}</td>
          </tr>
          <tr>
            <td>{{singleVoucherData.crAccount}}</td>
            <td>
              <p>{{singleVoucherData.crAccountName}}</p>
              <p><strong>Narration:</strong> {{singleVoucherData.narration}}</p>
            </td>
            <td class="text-right">{{0 | number}}</td>
            <td class="text-right">{{singleVoucherData.amount | number}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="2" class="text-right">Total (BDT)</th>
            <td class="text-right">{{singleVoucherData.amount | number}}</td>
            <td class="text-right">{{singleVoucherData.amount | number}}</td>
          </tr>
        </tfoot>
      </table>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>


