 
        <div class="card">
            
            <div class="card-body">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-md-9">
                          <h5 class="card-title page-heading" style="color: black">
                            Payment Receive Report
                          </h5>
                           
                        </div>
                        <div class="col-md-2">
                           
                           
                        </div>
                        <div class="col-md-1">
                          <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                            <i class="icon-arrow-left" style="font-size: 17px;"></i>
                        </button>
                        </div>
                      </div>
                      <hr style="height: 10%" />

                    <div class="col-md-12">
                        <!-- <div class="form-group row" id="ledgerId">
                            <label for="ledgerId" class="col-sm-3 col-form-label">Select Ledger</label>
                            <div class="col-sm-9">
                                <select type="text" class="form-control" (change)="onLedgerChange($event.target.value)" id="ledgerId">
                                    <option value="0">Select</option>
                                    <option *ngFor="let item of AccountNoList | async" [value]="item.id">{{item.ledger?.accountName}} -
                                        {{item.ledger?.accountCode}}</option>
    
                                </select>
                            </div>
                        </div> -->
                        <!-- <div class="form-group row" *ngIf="subledgerStatus === 'Show'"  id="LedgerProject">
                            <label for="ledgerId" class="col-sm-3 col-form-label">Select Sub Ledger</label>
                            <div class="col-sm-9">
                                <select type="text" (change)="onSubLedgerChange($event.target.value)" class="form-control" id="SubledgerId">
                                    <option value="0">Select</option>
                                    <option *ngFor="let item of AccountSubLedgerList" [value]="item.id">{{item.subLedger?.accountName}} - {{item.subLedger?.accountCode}}</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="form-group row" id="DateRangeSelect">
                            <label for="fromDate" class="col-sm-3 col-form-label text-left">From Date <span class="redStar">*</span></label>
                            <div class="col-sm-3 input-group">
                                <input type="text" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date" [minDate]="{year: businessYear, month: businessMonth, day: businessDay}" (click)="d3.toggle()" ngbDatepicker #d3="ngbDatepicker" readonly>
                                <div class="input-group-append">
                                  <span class="input-group-text">
                                    <i class="fa fa-calendar" (click)="d3.toggle()"></i>
                                  </span>
                                </div>
                            </div>
                            <!-- <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span class="redStar">*</span></label>
                            <div class="col-sm-4 input-group">
                                <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select From Date">
                            </div> -->
                        </div>
    
    
                        <div class="form-group row" style="margin-top:10px;">
                            <label class="col-sm-8"></label>
                            <div class="col-sm-4">
                                <button  class="btn btn-success" style="margin-left: 5px; float: right" (click)="OpenPDF()">Print</button>
                            </div>
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
     