<div class="card">
  <div class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <h5 class="card-title page-heading" style="color: black">
           COB File
          </h5>

        </div>
        <div class="col-md-2">


        </div>
        <div class="col-md-1">
          <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
            <i class="icon-arrow-left" style="font-size: 17px;"></i>
        </button>
        </div>
      </div>
      <hr style="height: 10%" />
    </div>
  </div>
    <div class="card-header">
        <div class="row">
            <!-- <div class="col">
                <select class="form-control" [(ngModel)]="selectedReportType">
                  <option value="0">All</option>
                  <option *ngFor="let reportType of fileSettings" [value]="reportType.id">
                    {{ reportType.name }}
                  </option>
                </select>
            </div> -->
            <div class="col">
                <input type="date" class="form-control" [(ngModel)]="fromDate">
            </div>
            <div class="col">
                <input type="date" class="form-control" [(ngModel)]="toDate">
            </div>
            <div class="col">
                <button class="btn btn-success" type="button" (click)="search()">Search</button>
            </div>
        </div>
    </div>

    <div class="card-body">
      <table class="table table-bordered table-primary table-hover table-striped" style="color: black;">
        <thead>
          <tr>
            <td>Name</td>
            <td>Log Date</td>
            <td>File Name</td>
            <td>Download</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of fileData">
            <td>
              <img *ngIf="file.extension == '.pdf'" width="30" height="30" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png" alt="File Icon">
              <img *ngIf="file.extension == '.txt'" width="30" height="30" src="https://www.svgrepo.com/show/120056/txt-text-file-extension-symbol.svg" alt="File Icon">
              <img *ngIf="file.extension == '.xlsx'" width="30" height="30" src="https://cdn-icons-png.flaticon.com/512/8361/8361467.png" alt="File Icon">
              <img *ngIf="file.extension == '.xls'" width="30" height="30" src="https://cdn-icons-png.flaticon.com/512/8243/8243067.png" alt="File Icon">
              {{file.name}}
            </td>
            <td>{{file.logDate | date: dateFormat}}</td>
            <td>{{file.fileName}}</td>
            <td>
              <a (click)="downloadFile(file)">
                <img src="/assets/img/downloads-icon-15.png" width="30" height="30" alt="">
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
