import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NagadRoutingModule } from './nagad-routing.module';
import { NagadTransactionLogComponent } from './nagad-transaction-log/nagad-transaction-log.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from 'app/app.module';
import { HttpClient } from '@microsoft/signalr';
import { NagadDirectDebitLogComponent } from './nagad-direct-debit-log/nagad-direct-debit-log.component';


@NgModule({
  declarations: [
    NagadTransactionLogComponent,
    NagadDirectDebitLogComponent
  ],
  imports: [
    CommonModule,FormsModule,NagadRoutingModule,ReactiveFormsModule, NgxPaginationModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ]
})
export class NagadModule { }
