<section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">

        <div class="card-body">
            <div class="container-fluid">


                <div class="row">
                    <div class="col-md-9">
                        <h5 class="card-title page-heading" style="color: black">Internal GL Ledger Info</h5>

                    </div>
                    <div class="col-md-2">
                        <button type="button" data-toggle="tooltip" title="Go to list"
                        class="btn btn-secondary btn-gotolist" (click)="goToList()"
                        style="float:right; margin-right:5px;margin-left: 8px;">List View</button>
                    </div>
                    <div class="col-md-1">
                      <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                        <i class="icon-arrow-left" style="font-size: 17px;"></i>
                    </button>
                    </div>
                  </div>


                <!-- <div class="row">
                    <div class="col-md-9">
                        <h5 class="card-title page-heading" style="color: black">Internal GL Ledger Info</h5>
                    </div>
                    <div class="col-md-2">
                        <button type="button" data-toggle="tooltip" title="Go to list"
                            class="btn btn-secondary btn-gotolist" (click)="goToList()"
                            style="float:right; margin-right:5px;margin-left: 8px;">List View</button>
                    </div>
                    <div class="col-md-1">
                       
                        <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()">
                            <i class="icon-arrow-left" style="font-size: 17px; float: right; padding: 2px;"></i>
                        </button>
                    
                    </div>
                  </div> -->

 
                <hr>
                <form [formGroup]="frmGroup">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group row">
                                <label for="" class="col-sm-4 ">GL Type</label>
                                <div class="col-sm-8">
                                    <select (change)="onNatureGroupChange($event.target.value)" class="form-control"
                                        name="natureId" formControlName="natureId">
                                        <option value="0">Select</option>
                                        <ng-container *ngFor="let item of natureList">
                                            <option [value]="item.id">{{item.natureName}} - {{item.natureCode}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="" class="col-sm-4 ">Group <span style="color: red;">
                                        *</span></label>
                                <div class="col-sm-8">
                                    <select (change)="onGroupChange($event.target.value)" class="form-control"
                                        name="groupId" formControlName="groupId">
                                        <option value="0">Select</option>
                                        <ng-container *ngFor="let item of groupListByNature">
                                            <ng-container *ngIf="!item.parentGroupId; else childOption">
                                                <!-- Render options for parent groups -->
                                                <option [value]="item.id" disabled style="color:blue">{{item.groupName}}
                                                    - {{item.groupCode}}</option>
                                            </ng-container>
                                            <ng-template #childOption>
                                                <!-- Render options for child groups -->
                                                <option [value]="item.id" style="color:darkblue">
                                                    &nbsp;&nbsp;&nbsp;&nbsp; {{item.groupName}} - {{item.groupCode}}
                                                </option>
                                            </ng-template>
                                        </ng-container>
                                    </select>

                                    <input type="hidden" class="form-control" name="id" formControlName="id">
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Account Name <span style="color: red;">
                                        *</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" name="accountName"
                                        formControlName="accountName" placeholder="Account Name">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="" class="col-sm-4 ">Account Code</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" name="accountCode"
                                        formControlName="accountCode" readonly placeholder="Account Code">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="" class="col-sm-4 ">ledger Type <span style="color: red;">
                                        *</span></label>
                                <div class="col-sm-8">
                                    <select class="form-control" name="ledgerType" formControlName="ledgerType">
                                        <option value="0">Select</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Bank">Bank</option>
                                        <option value="General">General</option>

                                    </select>


                                </div>
                            </div>
                            <!-- <div class="form-group row">
                    <label for="" class="col-sm-4 ">Effective Date</label>
                    <div class="col-sm-8">
                      <input type="date" class="form-control" name="accountCode" effectiveDate="effectiveDate" >
                    </div>
                </div> -->
                        </div>
                        <div class="col-md-12">




                            <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Remarks</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" name="remarks" formControlName="remarks"
                                        placeholder="Remarks">
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label">Is Active</label>
                <div class="col-sm-2">
                  <input type="checkbox" [value]="1" style="width: 20px;height: 20px;margin-top: 6px"
                    class="form-control" name="isActive" formControlName="isActive" placeholder="isActive">
                </div>
              </div> -->
                        </div>
                    </div>
                    <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button"
                        class="btn btn-success btn-add" style="float:right; margin-top:5px;">Save </button>

                </form>
            </div>

        </div>


    </div>
    
</section>