import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionService } from 'app/services/session.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
  private authToken: string | null = null;
  private apiUrl: string = environment.baseUrl;

  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(public _firebaseAuth: AngularFireAuth, public router: Router, private http: HttpClient, sessionService: SessionService) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }

  saveUserLastSession(sessionId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/SaveUserLastSession?sessionId=${sessionId}`);
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve(true);
      }, 1000);
    });

  }

  logout() {
    alert(2);
    sessionStorage.removeItem('sessionToken');
    localStorage.removeItem("userName"); //removed

    this._firebaseAuth.signOut();
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }

  isAuthenticated() {
    return true;

//     try {
//       let token = localStorage.getItem('token');
//       let payload = JSON.parse(window.atob(token.split('.')[1]));
//       let userId = payload['UserId'];
//       let loginId = payload['LoginId'];
//       let userTypeId = payload['UserTypeId'];
//       let tokenExp = payload['exp'];
//       let currentTimeSpan = (new Date().getTime())/1000;
//       let isExpired = (tokenExp-currentTimeSpan)<0;
//       if (userId != null && loginId != null && userTypeId != null && !isExpired) {
//         return true;
//       } else {
//         return false;
//       }
//     }
//     catch(err){
//       //this.appService.redirect('user/login')
//     }


    //return true;
  }



  setAuthToken(token: string): void {
    this.authToken = token;

    this.saveUserLastSession(token);

    sessionStorage.setItem('sessionToken', token);
  }

  getAuthToken(): string | null {
    return this.authToken || sessionStorage.getItem('sessionToken');
  }

  clearAuthToken(): void {
    this.authToken = null;
    sessionStorage.removeItem('sessionToken');
  }





}
