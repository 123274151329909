import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DatePipe, Location } from "@angular/common";
import { CibreportsService } from "../cibreports.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "app/services/shared.service";

@Component({
  selector: "app-cibcontract-generate",
  templateUrl: "./cibcontract-generate.component.html",
  styleUrls: ["./cibcontract-generate.component.scss"],
})
export class CibcontractGenerateComponent implements OnInit {
  generateDate: Date = new Date();
  monthsId: number = 0;
  constructor(
    private location: Location,
    private cibReports: CibreportsService,
    private router: Router,
    private route: ActivatedRoute,
    private shared: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.monthsId;
  }

  goBack() {
    this.location.back();
  }

  cibContractGenerate() {
    const currentYear = new Date().getFullYear();
    const currentDay = new Date().getDate();
    const formattedDate = `${currentYear}-${this.getMonthString(
      this.monthsId
    )}-${currentDay}`;

    this.cibReports.GenerateCIBContract(formattedDate).subscribe((res) => {
      if (res !== null) {
        this.shared.ShowAlert(
          "Success",
          "Contract  generate successfully!",
          "success"
        );
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  private getMonthString(monthId: number): string {
    return monthId.toString().padStart(2, "0");
  }
}

