 
<div class="card">
  <div class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <h5 class="card-title page-heading" style="color: black">
            Monthly Interest Provision
          </h5>
           
        </div>
        <div class="col-md-2">
           
           
        </div>
        <div class="col-md-1">
          <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
            <i class="icon-arrow-left" style="font-size: 17px;"></i>
        </button>
        </div>
      </div>
      <hr style="height: 10%" />
     
    <div class="form-group row">
      <label for="dateInput" class="col-md-2">From Date:</label>
      <div class="col-md-3 input-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="fromDate"
          placeholder="Select From Date"
          [minDate]="{
            year: businessYear,
            month: businessMonth,
            day: businessDay
          }"
          (click)="d3.toggle()"
          ngbDatepicker
          #d3="ngbDatepicker"
          readonly
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-calendar" (click)="d3.toggle()"></i>
          </span>
        </div>
      </div>

      <label for="dateInput" class="col-md-2">To Date:</label>
      <div class="col-md-3 input-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="toDate"
          placeholder="Select To Date"
          [minDate]="{
            year: businessYear,
            month: businessMonth,
            day: businessDay
          }"
          (click)="d4.toggle()"
          ngbDatepicker
          #d4="ngbDatepicker"
          readonly
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-calendar" (click)="d3.toggle()"></i>
          </span>
        </div>
      </div>

      <div class="col-2">
        <button
          type="button"
          class="btn btn-primary"
          (click)="LoadData()"
          id="processButton"
        >
          Find
        </button>
      </div>
    </div>
    <hr />
    <div class="row" *ngIf="isLoad">
      <table style="width: 100%" class="table table-bordered table-striped">
        <thead>
          <tr style="background-color: rgb(180, 179, 179)">
            <th>Process Date</th>
            <th>Customer Name</th>
            <th>Customer Account</th>
            <th>Interest Charged</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody style="background-color: white">
          <tr *ngFor="let item of dailyInterests">
            <td>{{ item.processDate | date: dateFormat }}</td>
            <td>{{ item.customerAccount?.accountTilte }}</td>
            <td>{{ item.customerAccount?.customerAccountNo }}</td>
            <td style="text-align: right;">{{ item.interestCharged }}</td>
            <td>
              <button class="btn btn-secondary btn-sm mb-1" style="margin-left: 5px;width:100%" (click)="OpenPDF(item.voucherId)">Voucher</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
</div>
