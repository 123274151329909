import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { CibReportsRoutingModule } from './cib-reports-routing.module';
import { CibsubjectGenerateComponent } from './cibsubject-generate/cibsubject-generate.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CibsubjectPdfComponent } from './cibsubject-pdf/cibsubject-pdf.component';
import { SharedModule } from "app/shared/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { CibcontractGenerateComponent } from './cibcontract-generate/cibcontract-generate.component';
import { CibcontractReportComponent } from './cibcontract-report/cibcontract-report.component';

@NgModule({
  declarations: [CibsubjectGenerateComponent, CibsubjectPdfComponent, CibcontractGenerateComponent, CibcontractReportComponent],
  imports: [

    CommonModule,
    CibReportsRoutingModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
  ],
  providers: [DatePipe],
})
export class CibReportsModule {}
