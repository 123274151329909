<div class="row">

    <div class="col-md-12 mt-3">
        <div class="form-group row">
            <label for="sortorder" class="col-sm-4 col-form-label"></label>
            <div class="col-sm-6 " style="margin-left: 66px;">
                
                <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" id="all" name="channel" [value]="100"
                        [(ngModel)]="selectedParentChannelId" style=" cursor: pointer;"  (change)="onChannelChange(100)" checked>
                    <label class="form-check-label" [class.selected]="selectedParentChannelId === 100" for="all">All</label>
                </div>
                <ng-container *ngFor="let item of parentChannelList">
                    <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" [id]="'channel' + item.id" name="channel"
                            [value]="item.id" [(ngModel)]="selectedParentChannelId"  style=" cursor: pointer;" (change)="onChannelChange(item.id)">
                        <label class="form-check-label" [class.selected]="selectedParentChannelId === item.id"
                            [for]="'channel' + item.id"   >
                            {{ item.channelName }}
                        </label>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


    

    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/today-dps-list'" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-3" style="font-size: 16px;"></h3>
                            <h3 class="mb-1" style="font-size: 16px;">{{todaydpsnewaccount || 0}}</h3>

                            <span style="color:black;font-size: 15px;" style="margin-right: 39%;">New Accounts </span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="fa fa-check-square-o Secondary font-large-1 float-right"
                                style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/dps-today-receivcble'" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-3" style="font-size: 16px;"></h3>
                            <h3 class="mb-1" style="font-size: 16px;">{{todayReceivableAmount ? (todayReceivableAmount |
                                number) :0}}</h3>
                            <span style="font-size: 15px;">Today Receivable</span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="ft-layers Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/today-payable-dps'" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-3" style="font-size: 16px;"></h3>
                            <!-- <h3 class="mb-1" style="font-size: 16px;">{{todayPayableDPS}}</h3> -->
                            <h3 class="mb-1" style="font-size: 16px;">{{todayTotalMaturity ? (todayTotalMaturity |
                                number) : 0}}</h3>


                            <span style="color:black;font-size: 15px;" style="margin-right: 39%;">Today's Payable</span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- ----------------------------------------------------------- -->
    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/total-payable-dps'" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-3" style="font-size: 16px;"></h3>
                            <!-- <h3 class="mb-1" style="font-size: 16px;">{{todayPayableDPS}}</h3> -->
                            <h3 class="mb-1" style="font-size: 16px; ">{{totaPayable ? (totaPayable | number):0}}</h3>


                            <span style="color:black;font-size: 15px;" style="margin-right: 39%;">Total Payable</span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="fa fa-money Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/close-account-list'" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-3" style="font-size: 16px;"></h3>
                            <h3 class="mb-1" style="font-size: 16px;">{{dpsclosedaccount || 0}}</h3>
                            <span style="color:black;font-size: 15px;" style="margin-right: 39%;">Close DPS Accounts
                            </span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="fa fa-shield Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/encashment-alert'" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <h3 class="mb-3" style="font-size: 16px;"></h3>
    
                            <h3 class="mb-1" style="font-size: 16px;">{{ encashmentalert ? (encashmentalert | number)
                                :0}}</h3>
                            <span style="color:black;font-size: 15px;" style="margin-right: 39%;">Encashment Alert
                            </span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="fa fa-hourglass-half Secondary font-large-1 float-right"
                                style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/missed-payment-list'" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <div class="row mb-2">

                                <h3 style="font-size: 16px;    margin-left: 15px;">{{ dpsmissedpayment ?
                                    (dpsmissedpayment | number) : 0 }}</h3>
                                <h3 style="font-size: 16px;margin-left: 108px;">{{dpsmissedpaymentCount || 0}}</h3>
                            </div>

                            <span style="color:black;font-size: 15px;" style="margin-right: 39%;">Missed Payment</span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="fa fa-window-restore Secondary font-large-1 float-right"
                                style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

   



    <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/role/today-direct-debit'" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="">
                    <div class="media">
                        <div class="media-body text-left" style="margin-left: 11%;">
                            <div class="row mb-2">
                            
                                <h3 style="font-size: 14px;    margin-left: 14px;">Success: {{ todayDDebitSuccess || 0 }}</h3>
                                <h3 style="font-size: 14px;margin-left: 28px;">Fail: {{todayDDebitFail || 0}}</h3>
                            </div>
                            <span style="color:black;font-size: 14px;" style="margin-right: 39%;">Today Direct Debit
                            </span>
                        </div>
                        <div class="media-right align-self-center mt-3">
                            <i class="fa fa-calendar-check-o Secondary font-large-1 float-right"
                                style="margin-right: 39%;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>





  <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/role/direct-debit-summary'" style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <div class="row mb-2">

                            <h3 style="font-size: 14px;    margin-left: 14px;">Success: {{ totalDDebitSuccess || 0 }}</h3>
                            <h3 style="font-size: 14px;margin-left: 28px;">Fail: {{totalDDebitFail || 0}}</h3>
                        </div>
                        <span style="color:black;font-size: 14px;" style="margin-right: 39%;">Total Direct Debit
                        </span>
                    </div>
                    <div class="media-right align-self-center mt-3">
                        <i class="fa fa-calculator Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/role/direct-debit-accounts'" style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <div class="row mb-2">

                            <h3 style="font-size: 16px;    margin-left: 14px;">{{ ddPendingCount|| 0 }}</h3>
                           
                        </div>
                        <span style="color:black;font-size: 14px;" style="margin-right: 39%;">Today DD Pending
                        </span>
                    </div>
                    <div class="media-right align-self-center mt-3">
                        <i class="fa fa-object-group Secondary font-large-1 float-right"
                            style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/role/invalid-token-info'" style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <div class="row mb-2">

                            <h3 style="font-size: 16px;    margin-left: 14px;">{{ totalToken|| 0 }}</h3>

                        </div>
                        <span style="color:black;font-size: 14px;" style="margin-right: 39%;">Invalid Token
                        </span>
                    </div>
                    <div class="media-right align-self-center mt-3">
                        <i class="fa fa-question-circle-o Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/today-early-encashment'" style="cursor: pointer;">
    <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="card-content">
            <div class="">
                <div class="media">
                    <div class="media-body text-left" style="margin-left: 11%;">
                        <div class="row mb-2">
<h3 style="font-size: 16px;    margin-left: 15px;">{{ todayEarlyEncashmentAmount ?
    (todayEarlyEncashmentAmount | number) : 0 }}</h3>
                            <h3 style="font-size: 16px;margin-left: 108px;">{{ todayEarlyEncashment|| 0 }}</h3>

                        </div>
                        <span style="color:black;font-size: 14px;" style="margin-right: 39%;">Today Early Encashment
                        </span>
                    </div>
                    <div class="media-right align-self-center mt-3">
                        <i class="fa fa-object-group Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</div>
<!-- ------------------------------BarChart------------------------------------ -->
<div class="col-md-12 row" style="background-color: #FFFFFF!important;margin-left: 0px;height: 423px;">
    <div class="col-sm-8" >
        <div class="mt-3">
            <h5 class="text-center">Opening & Closing log for 7 days</h5>
        </div>
        
                <div id="bar-bar1" class="height-400">
                    <canvas baseChart class="chart" [datasets]="barChartData" [labels]="barChartLabels"
                        [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend"
                        [chartType]="barChartType" chartHover="chartHovered($event)"
                        (chartClick)="chartClicked($event)" style="height: 560px!important;margin-top:5% ;"></canvas>
                </div>
           
     

    </div>
    <div class="col-sm-4 " style="background-color: #FFFFFF!important;height: 423px;">
        <h5 class="text-center mt-3">Tenure Wise DPS Account</h5>
        <div class="card-content">
            <div class="card-body">
                <h3 class="text-center" style="font-size: 25px;"></h3>
                <div id="pie-chart" class="height-250 mt-3 chart-wrapper">
                <canvas baseChart class="chart large-chart" [data]="pieChartDataTe" [labels]="durationNames"
                    [options]="pieChartOptionsTe" [chartType]="'pie'" [colors]="pieChartColorsTe" chartHover="chartHovered($event)"
                    (chartClick)="chartClicked($event)" style="display: block; width: 600px; height: 400px; margin-top: 0 auto;">
                </canvas>

                </div>
            </div>
        </div>

    </div>
</div>

    <!-- -------------------------------------------------------------------------- -->
<br>
    <div class="col-md-12 row"  style="background-color: #FFFFFF!important;margin-left: 0px;" >
<div class="col-sm-4" style="background-color: #FFFFFF!important;">
    <h6 class="text-center">AVB - 500/1000/1500/2000/2500/3000/3500/4000/4500/5000 VS Tenure</h6>
    <div class="">


        <div class="card-body">
            <h3 class=" text-center" style="font-size: 25px;"></h3>
            <div id="bar-doughnut" class="height-250">
                <canvas baseChart class="chart" [data]="countList" [labels]="tenureNames" [options]="tenureChartOptions"
                    [chartType]="tenureChartType" [colors]="tenureChartColors" chartHover="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
            </div>
        </div>

    </div>
</div>

   
    <div class="col-sm-4" style="background-color: #FFFFFF!important;">
        <h5 class="text-center mt-3">Designation Wise DPS Account</h5>
        <div class="card-body">
            <h3 class=" text-center" style="font-size: 25px;"></h3>
            <div id="bar-doughnut" class="height-250">
                <canvas baseChart class="chart" [data]="occupationList" [labels]="designationNames"
                    [options]="designationChartOptions" [chartType]="designationChartType" [colors]="designationChartColors"
                    chartHover="chartHovered($event)" (chartClick)="chartClicked($event)"
                    style="display: block;width: 310px;height: 250px;margin-top:8%"></canvas>
            </div>
        </div>
    </div>

<div class="col-sm-4" style="background-color: #FFFFFF!important;">
    <h5 class="text-center mt-4">Channel's Data</h5>
    <div class="card-content">
        <div class="card-body">
            <h3 class="text-center" style="font-size: 25px;"></h3>
            <div id="pie-chart mt-2" style="height: 230px;">
                <canvas baseChart class="chart" [data]="pieChartDataCh" [labels]="channelName" [chartType]="'pie'"
                    [options]="pieChartOptionsCh" chartHover="chartHovered($event)" [colors]="pieChartColorsCh"
                    (chartClick)="chartClicked($event)"
                    style="display: block; width: 210px;height: 242px; margin-top: 11%;">
                </canvas>
            </div>
        </div>
    </div>
</div>
    

    </div>

    <!-- -------------------------------------------------------------------------------------------------- -->



<br>

    <div class="col-sm-12 row" style="background-color: #FFFFFF!important;margin-left: 0px;height: 600px;">
        <div class="col-12">
            <div class="mt-3">
                <h5 class="text-center">Receivable vs Payable for 7 days</h5>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div id="bar-bar1" class="height-400">
                        <canvas baseChart class="chart" 
                        [datasets]="barChartDataR" 
                        [labels]="barChartLabelsR"
                        [options]="barChartOptionsR" 
                        [colors]="barChartColorsR" 
                        [legend]="barChartLegendR"
                        [chartType]="barChartTypeR"
                        (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"
                            style="display: block; width: 934px; height: 400px;margin-top: -3%;">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- <div class="col-xl-3 col-lg-6 col-12" (click)="onDisburse(5)" style="cursor: pointer;height: 50%;">
            <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
                <div class="card-content">
                    <div class="">
                        <div class="media">
                            <div class="media-body text-left" style="margin-left: 11%;">
                                <h3 class="mb-1 " style="font-size: 16px;">{{waitingForDisburse}}</h3>
                                <span style="color:black;font-size: 15px;">Waiting loan</span>
                            </div>
                            <div class="media-right align-self-center">
    
                                <i class="ft-rotate-cw Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-12" (click)="FinalDisburse(7)" style="cursor: pointer;">
            <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
                <div class="card-content">
                    <div class="">
                        <div class="media">
                            <div class="media-body text-left" style="margin-left: 11%;">
                                <h3 class="mb-1 " style="font-size: 16px;">{{finalDisbursList}}</h3>
                                <span style="color:black;font-size: 15px;">Disbursed loan</span>
                            </div>
                            <div class="media-right align-self-center">
                                <i class="ft-check-circle  Secondary font-large-1 float-right"
                                    style="margin-right: 39%;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    <!--  
        <div class="col-xl-3 col-lg-6 col-12" [routerLink]="'/dps-account/dps-list'" style="cursor: pointer;">
    
    
           <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
                <div class="card-content">
                    <div class="">
                        <div class="media">
                            <div class="media-body text-left" style="margin-left: 11%;">
                                <h3 class="mb-1 " style="font-size: 16px;">{{ dpsAccount || 0 }}</h3>
                                <span style="color:black;font-size: 15px;">DPS List</span>
                            </div>
                            <div class="media-right align-self-center">
                                <i class="ft-slack Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> -->

        <!-- <div class="col-xl-3 col-lg-6 col-12"  [routerLink]="'/dps-account/today-encashment-list'" style="cursor: pointer;">
            <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
                <div class="card-content">
                    <div class="">
                        <div class="media">
                            <div class="media-body text-left" style="margin-left: 11%;">
                                <h3 class="mb-1" style="font-size: 16px;">{{todayEncashement || 0}}</h3>
                                <span style="color:black;font-size: 15px;" style="margin-right: 39%;">Encashement</span>
                            </div>
                            <div class="media-right align-self-center">
                                <i class="ft-credit-card Secondary font-large-1 float-right" style="margin-right: 39%;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->