
<section>

    <div class="card col-12">

        <div class="card-header row">


            <div class="col-6">
                <h6 class="m-0 font-weight-bold text-primary">Today Received List</h6>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">

                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
            <hr>
        </div>
        <div class="card-body">
            <div class="table-container" style="max-height: 700px; overflow-y: auto;">

            <table id="example" class="table table-striped table-bordered scrollable-table" style="width:100%">
                <thead style="position: sticky;top:0;z-index: 1;background-color: #f8f9fa;">
                    <tr>
                        <th>SL No.</th>
                        <th>Account No</th>
                        <th>Customer Info</th>
                        <th>Collection Date</th>
                        <th>Loan Amount</th>
                        <th>Interest Amount</th>
                        <th>Total Collection</th>
                        <th>Today Collection</th>
                        <!-- <th>Outstanding</th> -->
                        <!-- <th>Overdue</th> -->
                        <th>Action</th>
            
                    </tr>
                </thead>
            
                <tbody>
            
                    <tr *ngFor="let item of dataList; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item?.accountNo}}</td>
                        <td><span>Name: {{item?.customeR_NAME_ENG}}</span> <br>
                            <span>Mobile No: {{item?.mobilE_NO}}</span>
                        </td>
                        <td>{{item?.lastCollectionDate}}</td>
                        <td>{{item?.loanAmount}}</td>
                        <td>{{item?.interestAmount}}</td>
                        <td>{{item?.totalCollectionAmount}}</td>
                        <td>{{item?.lastCollectionAmount}}</td>
                        <!-- <td>{{item?.outStanding}}</td> -->
                        <!-- <td>{{item?.overDue}}</td> -->
                        <td>
                            <button class="btn btn-sm btn-info" (click)="OpenPDF(item?.leadgenerationId)" type="button"
                                title="Schedule pdf">Payment</button>
                        </td>
            
                    </tr>
                </tbody>
            </table>

            </div>
        </div>
    </div>
</section>