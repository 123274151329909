<div class="card w-100">
    <div class="card-body">
        <div class="container-fluid">


            <div class="row">
                <div class="col-md-9">
                    <h5 class="card-title page-heading" style="color: black">New DPS Accounts List</h5>

                </div>
                <div class="col-md-2">
                   
                   
                </div>
                <div class="col-md-1">
                  <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                    <i class="icon-arrow-left" style="font-size: 17px;"></i>
                </button>
                </div>
              </div>
              <hr style="height: 10%" />

 
            <div class="row mb-1">
                <div class="col-md-4">
                    <label for="fromDate">From Date</label>
                    <input type="date" id="fromDate" class="form-control" [(ngModel)]="startDate"
                        (ngModelChange)="onDateChange()" placeholder="Select From Date">
                </div>
                <div class="col-md-4">
                    <label for="toDate">To Date</label>
                    <input type="date" id="toDate" class="form-control" [(ngModel)]="EndDate"
                        (ngModelChange)="onDateChange()" placeholder="Select To Date">
                </div>
                <div class="col-4">
                    <label>Filter Type</label>
                    <select class="form-control" [(ngModel)]="typeId" (change)="onFilterTypeChange($event.target.value)"
                        (change)="SearchByParameter()">
                        <option value="0"> Select</option>
                        <option value="1">Customer Name</option>
                        <option value="3"> Mobile No</option>
                        <option value="4"> Account No</option>
                        <option value="5"> Tenure Name</option>
                    </select>
                </div>
            </div>

            <div class="card mb-1">
                <div class="row g-4">
                    <div class="col-md-4" *ngIf="showCustomerNameField">
                        <label>Customer Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="customername"
                                (keyup.enter)="getCustomerDPSAccount()" placeholder="Search by customer name ...">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(1)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="showAccountField">
                        <label>Account No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="accountNo"
                                placeholder="Search by Account No...">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(4)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="showMobileNumberField">
                        <label>Mobile No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="mobileNumber"
                                placeholder="Search by mobile number...">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(3)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>
                </div>


                <div class="row g-4">
                    <div class="col-md-4" *ngIf="showTenureField">
                        <label>Tenure Name</label>
                        <div class="input-group">
                            <!-- <input type="text" class="form-control" [(ngModel)]="tenureId" (keyup.enter)="getCustomerDPSAccount()"
                                placeholder="Search by customer name ..."> -->



                                        <select class="form-control" [(ngModel)]="tenureId" name="tenureId">
                                            <option value="0">Select</option>
                                        <option *ngFor="let item of loandurationList" [value]="item?.id">
                                            {{item?.monthName}}</option>

                                        </select>

                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(5)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>



                </div>
            </div>


            <div class="d-flex justify-content-end mb-1">
                <button class="btn btn-success  mr-2" (click)="getCustomerDPSAccount()">Search</button>
                <button class="btn btn-info" (click)="refreshFilter()">Refresh</button>
                <button class="btn btn-secondary" style="margin-left: 10px; float: right;background-color:#ACCCF5 ;cursor: pointer;" (click)="OpenDPSListExcel()">Excel</button>
            </div>
        </div>


        <!-- ----------------------------------------------------------------------------------- -->

        <div class="card-body pt-2" style="overflow: auto; max-height: 600px;">
            <div class="container-fluid">
                <div *ngIf="customerList.length === 0" class="text-center">
                    <p>No data available</p>
                </div>
                <table *ngIf="customerList.length > 0" id="example"
                    class="table table-striped table-bordered scrollable-table" style="width:100%">
                    <thead style="position: sticky; top:-20px; z-index: 1; background-color: #EEF5E9">
                        <tr>
                            <th>Sl.</th>
                            <th>Action</th>
                            <th>Account Type</th>
                            <th>Account Name</th>
                            <th>Account No</th>
                            <th>Create Date</th>
                            <th>Amount</th>
                            <th>Tenure</th>
                            <th>Maturity Date</th>
                            <th>Maturity Amount</th>
                            <th>Channel</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of customerList; let i = index">
                            <td style="width: 2%;">{{ i + 1 }}</td>
                            <td>
                                <!-- <button class="btn btn-info btn-sm"
                                style="font-size: 12px; width: 80px !important; height: 25px;"
                                (click)="PrintKyc(item.accountId)" title="Click here for download pdf">View PDF</button> -->
                                <button [disabled]="isEdit" title="PDF" class="btn btn-info btn-sm mb-1"
                                    style="font-size: 12px; width: 80px !important; height: 25px;"
                                    (click)="CustomerPreViewPDF(item.customerId)">View
                                    PDF</button>


                                <button class="btn btn-success btn-sm mt-2"
                                    style="font-size: 12px; width: 80px !important; height: 25px;"
                                    (click)="DPSSchedulePDF(item.accountId)"
                                    title="Click here for download pdf">Schedule</button>
                            </td>
                            <td>{{ item.accountType }}</td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.accNo }}</td>
                            <td>{{ item.createdDate | date: dateFormat}}</td>
                            <td style="text-align: right;">{{ item.balance | number}}</td>
                            <td>{{ item.dpsTenure}}</td>
                            <td>{{ item.maturityDate | date: dateFormat}}</td>
                            <td style="text-align: right;">{{ item.maturityAmount | number}}</td>
                            <td>{{ item.channelName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>
