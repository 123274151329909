<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <h5 class="card-title" style="color: black">Loan Product Builder List</h5>
                </div>
                <div class="col-3" style="text-align: right;">
                    <button type="button" data-toggle="tooltip" [routerLink]="['/product/loan-product-entry/0']"
                        class="btn btn-info" style="float:right;margin-right:5px;margin-left: 8px;">Go to Entry</button>
                </div>

                <div class="col-1">
                    <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                        <!-- <i class="ft-chevrons-left"> Back</i> -->
                        <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                    </button>
                </div>
            </div>
            <hr>
            <table id="example" class="table table-striped table-bordered" style="width:100%">
                <thead>
                    <tr>
                        <th>SL No.</th>
                        <th>Product Builder Code</th>
                        <th>Product Builder Name</th>
                        <th>Version</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of loanProductList; let i = index;">
                        <td>{{i + 1}}</td>
                        <td>{{item.code}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.version}}</td>
                        <td>{{item.description}}</td>
                        <td style="width: 15%;">
                            <span style="font-size: 12px!important;"
                                [ngClass]="{'badge-green': item.statuss === true || item.statuss === null, 'badge-yellow': item.statuss === false}">
                                {{ item.statuss === true || item.statuss === null ? 'Active' : 'In-Active' }}
                            </span>
                        </td>

                        <td style="width: 23%;">
                            <button class="btn btn-info mb-1" style="margin-left: 5px;"
                                (click)="openBuilderDetailsModal(productBuilderDetails)"
                                (click)="getLoanProductDetails(item.id)"><i class="fa fa-list"></i></button>
                            <button class="btn btn-primary mb-1" style="margin-left: 5px;" (click)="edit(item)"><i
                                    class="fa fa-edit"></i></button>
                            <button class="btn btn-secondary mb-1" style="margin-left: 5px;"
                                (click)="delete(item.id,item.statuss === true|| item.statuss===null? false : true)"><i
                                    class="fa fa-times"></i></button>
                            <!-- <button class="btn btn-primary mb-1" style="margin-left: 5px;" (click)="edit(item)"><i
                                    class="fa fa-edit"></i></button>
                            <button [disabled]="isEdit" class="btn btn-danger mb-1" style="margin-left: 5px;"
                                (click)="delete(item.id)"><i class="fa fa-trash-o"></i></button> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #productBuilderDetails let-c="close" let-d="dismiss">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Product Informations</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table-bordered" style="line-height: 2.3;">
                        <tr>
                            <th>Code: </th>
                            <td>{{ productDetails?.productInfo?.code }}</td>
                            <th>Name: </th>
                            <td>{{ productDetails?.productInfo?.name }}</td>
                        </tr>

                        <tr>
                            <th>Version: </th>
                            <td>{{ productDetails?.productInfo?.version }}</td>
                            <th>Description: </th>
                            <td>{{ productDetails?.productInfo?.description }}</td>
                        </tr>

                        <tr>
                            <th>Loan Type: </th>
                            <td>{{ productDetails?.productInfo?.loanType?.typeName }}</td>
                            <th>Payment Type: </th>
                            <td>{{ productDetails?.productInfo?.paymentType?.typeName }}</td>
                        </tr>

                        <tr>
                            <th>Overdue Cal Days: </th>
                            <td>{{ productDetails?.productInfo?.overDueCalculationAfterDays }}</td>
                            <th>Grace Period Days: </th>
                            <td>{{ productDetails?.productInfo?.gracePeriodDays }}</td>
                        </tr>

                        <tr>
                            <th>Currency: </th>
                            <td>{{ productDetails?.productInfo?.currency?.currency }}</td>
                            <th>Day Size: </th>
                            <td>{{ productDetails?.productInfo.daySize?.name }}</td>
                        </tr>

                        <tr>
                            <th >Balance Type:</th>
                            <td >{{ productDetails?.productInfo?.balanceType?.name }}</td>

                            <th >Excise Duty: </th>
                            <!-- <td >{{ productDetails?.productInfo.exciseDuty?.exciseDutyName }}</td> -->

                            <td >
                                <ul>
                                    <li *ngFor="let item of productDetails?.exciseDutyDetails">
                                        <span>Excise Amount: {{ item.dutyAmount }}</span> <br>
                                        <span>Amount Range: {{ item.minAmountRange }} -({{ item.maxAmountRange
                                            }})</span>

                                    </li>
                                </ul>
                            </td>
                        </tr>

<!-- 
                    </table>
                </div>

                <div class="table-responsive">
                 <table class="table-bordered"> -->
                    <tr>
                        <th>Interest Calculation Type:
                            {{productDetails?.productInfo?.interestCalculationTypeId>1?'Slab':''}}</th>
                        <td >
                            <ul *ngIf="productDetails?.productInfo?.interestCalculationTypeId>1?true:false">
                                <li *ngFor="let item of productDetails?.productInstCalTypes">
                                    {{item?.slab?.min}}-{{item?.slab?.max}} ({{item?.slab?.amountParcent}})%
                                </li>
                            </ul>
                            <span
                                *ngIf="productDetails?.productInfo?.interestCalculationTypeId>1?false:true">{{productDetails?.productInfo?.interestCalculationType?.name}}</span>
                        </td>

                   
                        <th>Interest Calculation Frequency: </th>
                        <td>{{productDetails?.productInfo?.interestCalculationFrequency?.name }} -
                            {{productDetails?.productInfo?.interestCalculationFrequency?.days }}
                            {{productDetails?.productInfo?.interestCalculationFrequency?.days !=null? "days" : "" }}
                        </td>
                    </tr>
                    <tr>
                        <th >Capitalize Rule:</th>

                        <td >
                            <ul>
                                <li *ngFor="let item of productDetails?.productRules"> {{item?.rule?.code}}
                                </li>
                            </ul>
                        </td>
                   
                        <th>Capitalization Frequency:</th>
                        <td>
                            {{productDetails?.productInfo?.capitalizationFrequency?.name }} - {{
                            productDetails?.productInfo?.capitalizationFrequency?.days }} {{
                            productDetails?.productInfo?.capitalizationFrequency?.days !=null? "days" :
                            ""}}
                        </td>
                    </tr>
                    <tr>
                        <th>Charge:</th>
                        <td>
                            <ul>
                                <li *ngFor="let item of productDetails?.charges">
                                    {{item?.code}}-{{item?.name}}
                                </li>
                            </ul>
                        </td>
                    
                        <th>Commission:</th>
                        <td>
                            <ul>
                                <li *ngFor="let item of productDetails?.productCommission">
                                    {{item?.commission?.code}}-{{item?.commission?.name}}
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Loan Duration: </th>
                        <td>
                            <ul>
                                <li *ngFor="let item of productDetails?.productTenures">
                                    {{item?.duration?.monthName}}
                                </li>
                            </ul>
                        </td>
                   
                        <th>Status:</th>
                        <td>
                            {{ productDetails?.productInfo?.status ===0 ? "in-Active" :
                            "Active"}}
                        </td>
                    </tr>

                </table>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="download()">Download</button>
                <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            </div>
        </div>
    </div>
</ng-template>