import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Ledger } from "../model/ledger";
import { LedgerService } from "../service/ledger.service";
import { SharedService } from "app/services/shared.service";
import { GroupService } from "../service/group.service";
import { Observable, of } from "rxjs";
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: "app-ledger",
  templateUrl: "./ledger.component.html",
  styleUrls: ["./ledger.component.scss"],
})
export class LedgerComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: Ledger[] = [];
  subGroupList: any[] = [];
  groupListByNature: any[] = [];
  natureList: any[] = [];
  model = new Ledger();
  id: number;

  constructor(
    private _service: LedgerService,
    private _GroupService: GroupService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private _groupService: GroupService,
    private activeRoute: ActivatedRoute,
    private location: Location
  ) {
    // this.frmGroup = new FormGroup({
    //   id: new FormControl(),
    //   groupId: new FormControl(),
    //   accountCode: new FormControl(),
    //   accountName: new FormControl(),
    //   isActive: new FormControl(),
    //   ledgerType: new FormControl(),
    //   // effectiveDate: new FormControl(),
    //   refType: new FormControl(),
    //   remarks: new FormControl(),
    //   subGrpId:new FormControl()

    // });
    this.getLedgerList();
    //this.buildForm();
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.id = params["parameter"];
    });
    this.LedgerForm();
    this.getLedgerList();
    this.loadGroupList();
    this.GetGroupNatureList();
    //this.getLedgerListForEdit()
    // this.showSaveForm='saveList';
    this.showSaveForm = "saveForm";
  }

  showSaveForm: string = "";
  accountCode: any;

  LedgerForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      groupId: [0],
      accountName: [""],
      accountCode: [""],
      isActive: [],
      remarks: [""],
      refType: [""],
      ledgerType: [0],
      subGrpId: [0],
      natureId: [0],
      haveSubledger: [0],
    });
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
  }

  getLedgerList() {
    this._service.getlist().subscribe((res) => {
      this.list = res;
    });
  }

  // getLedgerListForEdit() {
  //   this._service.getlist().subscribe(res => {
  //     this.list = res;
  //   })
  // }

  goToList() {
    // this.router.navigate(['/e-kyc/nid-verify']);
    this.router.navigate(["/accounting/ledger-list"]);
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    this.model.groupId = this.frmGroup.value.groupId;
    this.model.accountCode = this.frmGroup.value.accountCode.toString();
    this.model.accountName = this.frmGroup.value.accountName;
    this.model.isActive = this.frmGroup.value.isActive = true ? 1 : 0;
    this.model.ledgerType = this.frmGroup.value.ledgerType;
    // this.model.effectiveDate = this.frmGroup.value.effectiveDate;
    this.model.refType = this.frmGroup.value.refType;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.subGrpId = this.frmGroup.value.subGrpId;
    this.model.haveSubledger = 0;
    if (this.model.groupId == 0) {
      this.shared.ShowAlert("Warning...", "Please Select Group", "warning");
      return false;
    } else if (this.model.accountName == "") {
      this.shared.ShowAlert("Warning", "Please Insert Account Name", "warning");
      return false;
    } else if (this.model.ledgerType == "0") {
      this.shared.ShowAlert("Warning", "Please Select ledger Type", "warning");
      return false;
    }

    this._service.save(this.model).subscribe((res) => {
      if (res === 1) {
        this.shared.ShowAlert(
          "Success",
          "Saved & Sent for Approval.",
          "success"
        );
      } else {
        this.shared.ShowAlert(
          "Updated",
          "Updated & Sent for Approval",
          "success"
        );
      }
      this.isEdit = false;
      //this.showSaveForm = 'saveList';

      this.router.navigate(["/accounting/ledger-list"]);
      this.getLedgerList();
      this.refresh();
    });

    // else {
    //   this._service.save(this.model).subscribe(res => {
    //     this.shared.ShowAlert("Success", "Save Successfully", "success");
    //     this.getLedgerList();
    //     this.isEdit = false;
    //     this.showSaveForm = 'saveList';
    //     this.refresh();
    //   })
    // }
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = "saveForm";
    this.frmGroup.patchValue({
      id: item.id,
      groupId: item.groupId,
      accountCode: item.accountCode,
      accountName: item.accountName,
      isActive: item.isActive,
      ledgerType: item.ledgerType,

      // effectiveDate:item.effectiveDate,
      refType: item.refType,
      remarks: item.remarks,
      subGrpId: item.subGrpId,
    });
  }

  GroupList: Observable<any[]>;

  GetGroupNatureList() {
    this._groupService.GetGroupNatureList().subscribe((res) => {
      this.natureList = res;
      //console.log(res);
    });
  }

  loadGroupList() {
    this._GroupService.GetAccountGroupList().subscribe((res) => {
      this.GroupList = of(res);
    });
  }

  onGroupChange(gId: number) {
    if (gId != 0 || gId != null) {
      this.loadSubGrpList(gId);
      this.GetAccountCode(gId);
    }
  }

  getGroupByNatureId(natureId: number) {
    this._GroupService
      .GetAccountGroupListByNature(natureId)
      .subscribe((res) => {
        this.groupListByNature = res;
      });
  }

  onNatureGroupChange(nId: number) {
    if (nId != 0 || nId != null) {
      this.getGroupByNatureId(nId);
    }
  }

  loadSubGrpList(gId: number) {
    this._GroupService.getSubGrpByGrpId(gId).subscribe((res) => {
      this.subGroupList = res;
      //console.log(this.subGroupList )
      this.changeDetectorRef.detectChanges();
    });
  }

  GetAccountCode(gId: any): void {
    this._service.GetAccountCode(gId).subscribe((res: any) => {
      //this.accountsCode = res.accountCode;
      this.frmGroup.patchValue({
        accountCode: res.code,
      });
    });
  }

  delete(Id: any) {
    this._service.delete(Id).subscribe((res) => {
      this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      this.getLedgerList();
    });
  }

  refresh() {
    this.model = new Ledger();
    this.LedgerForm();
  }

  // getLedgerListForEdit() {

  //   this.activeRoute.paramMap.subscribe(params => {
  //     const id = +params.get('parameter');
  // alert(id);
  //     if (id != null) {
  //       this.isEdit = true;
  //       this._service.GetLedgerById(id).subscribe(
  //         (res: any) => {

  //             this.getGroupByNatureId(res.group.nature.id)
  //             this.loadSubGrpList(res.group.parentGroup.id)

  //             this.frmGroup.patchValue({

  //               id: res.id,
  //               natureId: res.group.nature.id,
  //               groupId: res.group.parentGroup.id,
  //               accountCode: res.accountCode,
  //               accountName: res.accountName,
  //               isActive: res.isActive,
  //               ledgerType: res.ledgerType,
  //               refType: res.refType,
  //               remarks: res.remarks,
  //               subGrpId: res.groupId,
  //               haveSubledger:res.haveSubledger
  //             })

  //         },

  //         (error: any) => {
  //           this.shared.ShowAlert('Error', 'Failed to retrieve.', 'error');
  //         }
  //       );
  //     }
  //   });
  // }

  goBack() {
    this.location.back();
  }
}
