import { Component, OnInit } from '@angular/core';
import { PasswordPolicy } from 'app/models/Security/password-policy';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SecurityService } from 'app/services/security.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: 'app-password-policy-setup',
  templateUrl: './password-policy-setup.component.html',
  styleUrls: ['./password-policy-setup.component.scss']
})
export class PasswordPolicySetupComponent implements OnInit {
  passwordPolicy: PasswordPolicy = new PasswordPolicy(); // Initialize a new instance of PasswordPolicy

  constructor(private securityService: SecurityService,  private location: Location, private pdfService: PdfService) { }

  ngOnInit(): void {
    this.getPasswordPolicy();
  }


  onSubmit() {


    this.securityService.savePasswordPolicy(this.passwordPolicy)
      .subscribe(
        (response) => {
          alert('Updated Successfully!');
          console.log('Password policy saved successfully:', response);
        },
        (error) => {
          alert('Updated Failed!');
          console.error('Error saving password policy:', error);
        }
      );

  }


  getPasswordPolicy(): void {
    this.securityService.getPasswordPolicy()
      .subscribe(
        (policy: PasswordPolicy) => {
          this.passwordPolicy = policy;
          console.log('Password policy retrieved successfully:', this.passwordPolicy);
        },
        (error) => {
          console.error('Error retrieving password policy:', error);
        }
      );
  }

  OpenPDF() {
    this.pdfService
      .GeneratePasswordPdf()
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }
  goBack() {
    this.location.back();
  }
}
