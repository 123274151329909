import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoginService } from 'app/pages/content-pages/login/login.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: 'app-nagad-direct-debit-log',
  templateUrl: './nagad-direct-debit-log.component.html',
  styleUrls: ['./nagad-direct-debit-log.component.scss']
})
export class NagadDirectDebitLogComponent implements OnInit {
  dateFormat: string = "";
  accountNo: string = "";
  status: any = "";
  loading: boolean = false;
  logs: any;
  username: string = "";
  businessDate: any;
  fromDate: string = new Date().toISOString().split("T")[0];
  toDate: string = new Date().toISOString().split("T")[0];
  private intervalId: any;

  constructor(
    private login: LoginService,
    private location: Location,
    private layoutService: LayoutService,
    private shared: SharedService,
    private pdfService: PdfService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.thumbRule.dateFormat;
    this.GetDirectDebitLogs();
    this.startInterval();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;

      this.fromDate = new Date(this.businessDate).toISOString().split("T")[0];
      this.toDate = new Date(this.businessDate).toISOString().split("T")[0];
      this.changeDetectorRef.detectChanges();
    });
  }

  GetDirectDebitLogs() {
    this.loading = true;
    this.login.GetAllDirectDebitLogs().subscribe((res) => {
      this.logs = res;
      console.log(res);
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    });
  }

  startInterval() {
    this.intervalId = setInterval(() => {
      this.GetDirectDebitLogs();
    }, 1000 * 60 * 5);
  }

  clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  FilterDirectDebitLogs() {
    this.login
      .FilterDirectDebitLogs(
        this.fromDate,
        this.toDate,
        this.accountNo,
        this.status
      )
      .subscribe((res) => {
        this.logs = res;
        this.changeDetectorRef.detectChanges();
      });
  }

  LoadAllLogs() {
    this.GetDirectDebitLogs();
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  goBack() {
    this.location.back();
  }

  formatDateFull(date: Date): string {
    const pad = (num: number) => String(num).padStart(2, "0");
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  OpenPDF(accountNo) {
    this.pdfService
      .CashTransactionPdf(accountNo, "01-01-1970", "01-01-3070")
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }

  DPSSchedulePDF(accountId: number) {
    this.pdfService.DPSOpenSchedulePdf(accountId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
