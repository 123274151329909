<div class="card" style="width: 100%;">

    <div class="card-header row">
        <div class="col-6">
            <h5 class="m-0 font-weight-bold text-primary">CIB Contract Generate</h5>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">

                <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
        </div>
    </div>
    <hr>
    <div class="card-body">

        <div class="container">
            <!-- <form #frmSalaryProcess="ngForm" (ngSubmit)="submitForm()"> -->
            <form #frmSalaryProcess="ngForm">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group row">
                            <label for="salaryPeriodId" class="col-md-3 col-form-label">Select Month<span
                                    style="color:red;">*</span></label>
                            <div class="col-md-5">
                                <!-- <input type="date" class="form-control" formControlName="generateDate" placeholder="" [(ngModel)]="generateDate"> -->
                                <select [(ngModel)]="monthsId" class="form-control" required>
                                    <option [value]="0">Select Month</option>
                                    <option [value]="01">January</option>
                                    <option [value]="02">February</option>
                                    <option [value]="03">March</option>
                                    <option [value]="04">April</option>
                                    <option [value]="05">May</option>
                                    <option [value]="06">June</option>
                                    <option [value]="07">July</option>
                                    <option [value]="08">August</option>
                                    <option [value]="09">September</option>
                                    <option [value]="10">October</option>
                                    <option [value]="11">November</option>
                                    <option [value]="12">December</option>

                                </select>



                                <!-- <select name="salaryPeriodId" id="salaryPeriodId" class="form-control" required
                                    (change)="loadSalaryPeriods()" [(ngModel)]="selectedSalaryPeriod">
                                    <option [value]="">Select Salary Period</option>
                                    <option *ngFor="let period of salaryPeriods" [[value]]="period.id">{{
                                        period.periodName }}</option>
                                </select> -->

                            </div>

                            <div class="col-md-4 row">

                                <button type="button" class="btn btn-success"
                                    (click)="cibContractGenerate()">Generate</button>

                            </div>
                        </div>



                    </div>
                </div>
            </form>
        </div>
    </div>
</div>