import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CashPostingService } from '../service/cash-posting.service';
import { LedgerService } from '../service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { CashPosting } from '../model/cash-posting';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { Observable, of } from 'rxjs';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-import-fund-transfer',
  templateUrl: './import-fund-transfer.component.html',
  styleUrls: ['./import-fund-transfer.component.scss']
})
export class ImportFundTransferComponent implements OnInit {
  file: File | null = null;

  // Trigger file input when the dropzone is clicked
  triggerFileInput() {
    const fileInput = document.getElementById('file') as HTMLElement;
    fileInput.click(); // Trigger file input click
  }

  // Handle file selection or drag-and-drop
  onFileChange(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      this.file = files[0]; // Set the selected file
    }
  }

  // Handle drag over event
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = event.currentTarget as HTMLElement;
    dropzone.classList.add('dragover');
  }

  // Handle drag leave event
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = event.currentTarget as HTMLElement;
    dropzone.classList.remove('dragover');
  }

  // Handle file drop event
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = event.currentTarget as HTMLElement;
    dropzone.classList.remove('dragover');

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.file = files[0]; // Set the dropped file
    }
  }



  uploadStatus: boolean = false;
  uploadedData: any;

  closeResult: string;
  singleVoucherData: any;
  uniqueId: number = 0;

  constructor(
    private router: Router,
    private _service: CashPostingService,
    private modalService: NgbModal,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) {
    {

    }


  }

  ngOnInit(): void {

  }


  onSubmit() {
    if (!this.file) return;

    const formData = new FormData();
    formData.append('file', this.file, this.file.name);


    this._service.GetFundTransferDataFromExcel(formData).subscribe(res => {
      console.log(res);
      this.uploadedData = res;

      if(res != null){
        this.uniqueId = res[0]?.uploadId;
      }

      this.uploadStatus = true;
      //this.shared.ShowAlert("Success", "Loaded Successfully", "success");
      //this.getLedgerList();
      //this.isEdit = false;
      //this.showSaveForm='saveList';
    })





    // // Send the file to the C# API
    // this.http.post('/api/excel/upload', formData, {
    //   headers: new HttpHeaders(),
    //   observe: 'response',
    // }).subscribe(
    //   (response) => {
    //     this.uploadStatus = 'File uploaded successfully!';
    //     console.log(response);
    //   },
    //   (error) => {
    //     this.uploadStatus = 'Error uploading file!';
    //     console.error(error);
    //   }
    // );
  }

  DownloadExcelSample() {
    this.http.get('assets/sample-files/Fund Transfer_Format.xlsx', { responseType: 'blob' })
      .subscribe((response: Blob) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Fund_Transfer_Format.xlsx';
        link.click();
      });
  }

  OpenVoucherModal(data:any){
    console.log(data);
    this.singleVoucherData = data;
  }

  UploadAllVouchers(id:string){
    console.log(id);

    this._service.UploadAllVouchers(id).subscribe(res => {
      console.log(res);
      if(res == true){
        this.shared.ShowAlert("Success", "Uploaded Successfully", "success");

        this.uploadStatus = false;
      }
      else{
        this.shared.ShowAlert("Warning", "Failed to Upload", "warning");
        window.location.reload();
      }
    })
  }

  OpenVoucherDetails(content) {
    let options: any = {
      size: "sm",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

}
