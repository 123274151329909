import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';
const apiUrl=environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class CashPostingService {

  constructor(private http: HttpClient) { }

  save(model: any){

     return this.http.post(apiUrl + '/api/CashTransaction/SaveFundTransfer',model);

   }

   GetFundTransferDataFromExcel(file: any){

    return this.http.post(apiUrl + '/api/CashTransaction/GetFundTransferDataFromExcel',file);

  }

  UploadAllVouchers(id: string){

    return this.http.get(apiUrl + '/api/CashTransaction/UploadAllVouchers?uniqueId=' + id);

  }

    getlist():Observable<any>
   {
        return this.http.get(apiUrl + '/api/CashTransaction/GetCashTransaction');
   }

  getAccList():Observable<any>{
    return this.http.get(apiUrl + '/api/Customers/GetCustomerAccountNo');
  }


  SaveOtherBankTransaction(model: any) {
    return this.http.post(apiUrl + '/api/CashTransaction/SaveOtherBankTransactions', model);

  }

  SaveWasa(model: any) {
    return this.http.post(apiUrl + '/api/CashTransaction/SaveBillPayInfo', model);

  }
  SaveMobileRecharge(model: any){
    return this.http.post(apiUrl + '/api/CashTransaction/SaveMobileRechargeInfo', model);
  }

  //#region BillPay

  getBillpayInfoByAccType(accType: string): Observable<any> {

    return this.http.get<any>(apiUrl + '/api/CustomerAccount/GetBillPayAccountInfoByAccType?accType=' + accType);
  }

  GetBillPayInfoById(accountId: number): Observable<any> {
    return this.http.get<any>(apiUrl + '/api/CustomerAccount/GetBillPayInfoByAccountId?accountId=' + accountId);
  }

  GetBillPayInfobybillNumber(billNumber: string,accType: string): Observable<any>{
    return this.http.get<any>(apiUrl + '/api/CustomerAccount/GetBillPayInfobybillNumber?billNumber=' + billNumber + '&accType=' + accType);
  }
  //#endregion

}
