<div class="card" style="width: 100%">
  <div class="card-body">
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-9">
          <h5 class="card-title page-heading" style="color: black">DPS Encashment List</h5>
        </div>
        <div class="col-md-2">


        </div>
        <div class="col-md-1">
          <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
            <i class="icon-arrow-left" style="font-size: 17px;"></i>
        </button>
        </div>
      </div>
      <hr style="height: 10%" />


          <div class="form-group row">
            <div class="col-6">
              <div class="row">
                <div class="col-4 mt-1">
                  <label style="font-size: 16px">Account Name</label>
                </div>
                <div class="col-8">
                  <div class="input-group-prepend">
                    <p class="input-group-text" style="border-right: none">
                      <i class="fa fa-search"></i>
                    </p>
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="accountName"
                      (keyup.enter)="loadDPSRelatedData()"
                      placeholder="Search by Account name ..."
                      style="border-left: none; margin-left: -6px"
                    />
                  </div>
                </div>
              </div>

            </div>

            <div class="col-6">
              <div class="row">
                <div class="col-4">
                  <label style="font-size: 16px">Filter Type</label>
                </div>

                <div class="input-group col-8">
                  <select
                    class="form-control"
                    [(ngModel)]="typeId"
                    (change)="onFilterTypeChange($event.target.value)"
                    (change)="SearchByParameter()"
                  >
                    <option value="0">Select</option>
                    <option value="1">Account No</option>
                    <option value="2">Encashment Date</option>
                  </select>
                </div>
              </div>

            </div>
          </div>


            <div class=" row" *ngIf="showaccountNoField">
              <div class="col-md-2">
               Account No
              </div>
              <div class="col-4">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="accountNo"
                    placeholder="Search by Account No...."
                  />
                  <button
                    class="btn btn-danger"
                    (click)="deleteFilterTypeFields(1)"
                  >
                    <i class="fa fa-close"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-6 row">
              <div class="col-4">
                <label style="font-size: 16px"></label>
              </div>
              <div class="col-8">
                <div class="input-group"></div>
              </div>
            </div>


          <div class="row" *ngIf="showencashmentDateField">
            <div class="col-2 mt-4">Encashment Date</div>
            <div class="col-4">


              <div class="input-group">
                <input
                  type="date"
                  class="form-control"
                  [(ngModel)]="encashmentDate"
                  placeholder="Select Encashment Date"
                />
                <button
                  class="btn btn-danger"
                  (click)="deleteFilterTypeFields(2)"
                >
                  <i class="fa fa-close"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <!-- <button class="btn btn-info pull-right ml-2" (click)="refreshFilter()"> Refresh</button> -->
               <button
                 class="btn btn-success pull-right"
                 (click)="loadDPSRelatedData()"
               >
                 Search
               </button>
           </div>
          </div>


      <div class="card-body" style="overflow: auto; max-height: 600px">
        <table
          id="example"
          id="example"
          class="table table-striped table-bordered scrollable-table"
          style="width: 100%"
          style="width: 100%"
        >
          <thead
            style="
              position: sticky;
              top: -25px;
              z-index: 1;
              background-color: #eef5e9;
            "
          >
            <tr>
              <th>Action</th>
              <th>Account No</th>
              <th>Account Name</th>
              <th>Encashment Amount</th>
              <th>AIT</th>
              <th>Encashment Date</th>
              <th>Maturity Date</th>
              <th>Encashment Type</th>
              <th>Status</th>
              <th>Channel</th>
            </tr>
          </thead>
          <tbody>
            <ng-container></ng-container>
            <tr *ngFor="let item of dpsEncashmentList">
              <td>
                <!-- <button class="btn btn-primary" style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="PrintKyc(item.customerAccount?.id)" title="Click here for download pdf">View PDF</button> -->
                <button
                  class="btn btn-primary btn-sm mt-1"
                  style="font-size: 14px; width: 120px !important; height: 35px"
                  (click)="DPSSchedulePDF(item.customerAccount?.id)"
                  title="Click here for download pdf"
                >
                  Schedule
                </button>
                <button
                  class="btn btn-info mt-1 btn-sm"
                  *ngIf="item.isApproved == 0"
                  style="font-size: 14px; width: 120px !important; height: 35px"
                  (click)="Approve(item.id)"
                  title="Click here for Approve Encashment"
                >
                  Approve
                </button>
                <button
                  class="btn btn-success mt-1 btn-sm"
                  *ngIf="item.isApproved == 1 && item.customerAccount?.partnerChannel?.channelName == 'DANA'"
                  style="font-size: 14px; width: 120px !important; height: 35px"
                  (click)="getTransactionById(item.id)" (click)="TransactionDetails(tranDetails)"
                  title="Click here for Approve Encashment"
                >
                  View
                </button>
              </td>
              <td>{{ item.customerAccount?.customerAccountNo }}</td>
              <td>{{ item.customerAccount?.accountTilte }}</td>
              <td style="text-align: right">
                {{ item.encashmentAmount | number }}
              </td>
              <td style="text-align: right">{{ item.totalAit | number }}</td>
              <td>{{ item.effectiveDate | date : dateFormat }}</td>
              <td>{{ item.maturityDate | date : dateFormat }}</td>
              <td>
                {{
                  item.effectiveDate < item.maturityDate
                    ? "Pre-mature"
                    : "Regular"
                }}
              </td>
              <td>{{ item.isApproved == 0 ? "Pending" : "Approved" }}</td>
              <td>{{ item.customerAccount?.partnerChannel?.channelName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



<ng-template #tranDetails let-c="close" let-d="dismiss">
  <div class="">

      <div class="" style=" width: 100%;">
          <div class="modal-header">
              <h4 class="modal-title page-heading" style="text-align: center;">Nagad Transactions</h4>
              <button type="button" class="close" style="size: 100%;" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body col-9">
            <table class="table table-striped table-bordered scrollable-table">
              <thead>
                <tr>
                  <th>Account No</th>
                  <th>Mobile No</th>
                  <th>Issuer TXN Id</th>
                  <th>Merchant Mobile No</th>
                  <th>Reference No</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of transactionDetails">
                  <td>{{item.customerAccountNo}}</td>
                  <td>{{item.customerMobileNo}}</td>
                  <td>{{item.issuerTxnId}}</td>
                  <td>{{item.merchantMobileNo}}</td>
                  <td>{{item.referenceNo}}</td>
                  <td>{{item.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
          </div>
      </div>
  </div>
</ng-template>
