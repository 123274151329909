<section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
  
      <div class="card-body">
        <div class="container-fluid">


          <div class="row">
            <div class="col-md-9">              
              <h5 class="card-title" style="color: black">Bank Ultimus Ledger Mapping</h5>
            </div>
            <div class="col-md-2">
              <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary btn-gotolist"
              (click)="onClickShowSaveForm('saveList')"
              style="float:right;margin-right:5px;margin-left: 8px;">List View</button>
            </div>
            <div class="col-md-1">
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                <i class="icon-arrow-left" style="font-size: 17px;"></i>
            </button>
            </div>
          </div> 
 
          <hr>
          <form [formGroup]="frmGroup">
            <div class="row">
              <div class="col-md-12">
               
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">ledger Code</label>
                  <div class="col-sm-8">
                    <select class="form-control"  formControlName="ledgerId"  (change)="getLedgerCode($event.target.value)">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of LedgerList">
                        <option [value]="item.id">{{item.accountCode}}({{item.accountName}})</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
  
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Bank  Ultimus Account Code</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="bankUltimusAccountCode" formControlName="bankUltimusAccountCode"
                      placeholder="Account Code">
                  </div>
                </div>
                
               
              </div>
              <div class="col-md-6">
              </div>
            </div>
            <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success btn-add"
              style="float:right; margin-top:5px;">Save </button>
  
          </form>
        </div>
  
      </div>
  
  
    </div>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
      <div class="card-body">
        <div class="container">


          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title" style="color: black">Bank ultimus Ledger Mapping List</h5>

            </div>
            <div class="col-md-2">
               
              <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary btn-gotoadd"
                (click)="onClickShowSaveForm('saveForm')"
                style="float:right; margin-right:5px;margin-left: 8px;">Add New</button>
            </div>
            <div class="col-md-1">
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                <i class="icon-arrow-left" style="font-size: 17px;"></i>
            </button>
            </div>
          </div> 
           
          <hr>
          <div class="row">
            <div class="col-md-6" style="text-align: left;">
              <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
                <option value="5">5</option>
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="col-md-6 form-group" style="text-align: right;">
              <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchControl" (keyup)="onSearch()">
            </div>
          </div>
          <table id="example" class="table table-bordered  ">
            <thead>
              <tr>
                <th>SL No.</th>
                <th>Ledger Name</th>
                <th>Opus Account Code</th>
                <th>Bank Ultimus Account Code</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of glList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index;">
                <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                <td>{{item.ledger?.accountName}}</td>
                <td>{{item.opusAccountCode}}</td>
                <td>{{item.bankUltimusAccountCode}}</td>
                  <td style="width: 15%!important;">
                  <button class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                  <button [disabled]="isEdit" class="btn  btn-danger btn-sm" style="margin-left: 2px;margin-right:6px"
                    (click)="delete(item.id)"><i class="fa fa-trash-o"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-md-6" style="text-align: left">
              <span>Showing {{currentItems}} of {{totalItems}} entries</span>
            </div>
            <div class="col-md-6" style="text-align: right">
              <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>