// import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { Injectable, inject } from "@angular/core";
// import { environment } from "environments/environment";
// import { Observable } from "rxjs";
// import { CookieService } from 'ngx-cookie-service';




import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { RoleUserVm } from 'app/user/role-user-vm';
import { UserActionVm } from 'app/user/UserActionVm';
const apiUrl = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})

export class UserService {
  private userUrl = environment.userurl;

  private isAuthenticate = false;
  isAuthenticated(): boolean {
    if (localStorage.getItem("token")) {
      return true;
    }
  }
  cookieService = inject(CookieService);






  constructor(private http: HttpClient) {}
  getUser(): Observable<any> {
    const url = `${this.userUrl}`;
    return this.http.get<any>(url);

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  // getUserlist():Observable<any>
  // {
  //      return this.http.get(apiUrl + '/api/Auth/GetCustomerList');
  // }
  GetCustomerList():Observable<any>
  {
       return this.http.get(apiUrl + '/api/Auth/GetCustomerList');
  }

  GetUserListWithSearchAndPagination(currentPage : any, itemsPerPage : any, searchValue : string){
    //alert(11);
    return this.http.get(apiUrl + '/api/User/GetUserListWithSearchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  SaveRoleEvents(model: any) {
    return this.http.post(apiUrl + "/api/SaveRoleEvents", model);
  }


  GetUserListRoleWiseWithPagination(currentPage : any, itemsPerPage : any, searchValue : string, roleId: string){
    return this.http.get(apiUrl + '/api/User/GetAllUserListWithSearchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue + '&roleId=' + roleId);
  }

  GetRoleList():Observable<any>
  {
       return this.http.get(apiUrl + '/api/GetAllRoleList');
  }

  GetActionsByRoleId(roleId: string):Observable<any>
  {
       return this.http.get(apiUrl + '/api/GetActionsByRoleId?roleId=' + roleId);
  }

  // CustomerInactive(id:any):Observable<any>{
  //   alert(22)
  //   return this.http.post(apiUrl + '/api/Auth/CustomerInactive?id', id);
  // }

  CustomerInactive(id:any){
    return this.http.post(apiUrl + '/api/Auth/CustomerInactive/'+ id, {});
  }
  ResetPassword(model: any){

    return this.http.post(apiUrl +'/api/CustomerAccount/UserResetPassword' , model);
  }
  ChangePassword(model: any){
    return this.http.post(apiUrl +'/api/CustomerAccount/UserChangePassword' , model);
  }

  UpdateUserRoles(model: RoleUserVm[]){

    return this.http.post(apiUrl +'/api/UpdateUserRoles' , model);
  }

  validatePassword(password: string): Observable<string[]> {
    return this.http.get<any[]>(apiUrl  + '/api/ValidatePassword?password=' + password);
  }

  UpdateUserEvent(model: UserActionVm[]){

    return this.http.post(apiUrl +'/api/UpdateUserEvents' , model);
  }

  // postProxyUser(userName: string, securityCode: string) : Observable<any> {

  //   sessionStorage.removeItem('token');
  //   const requestBody = {
  //     userName: userName,
  //     securityCode: securityCode
  //   };

  //   return this.http.post(apiUrl + '/api/Auth/SwitchedUser', requestBody);
  // }


  postProxyUser(userName: string, securityCode: string): Observable<any> {
    // Remove the existing tokens from both sessionStorage and localStorage
    sessionStorage.removeItem('token');
    localStorage.removeItem('token'); //removed
    localStorage.removeItem('userName'); //removed

    const requestBody = {
      userName: userName,
      securityCode: securityCode
    };

    return this.http.post(apiUrl + '/api/Auth/SwitchedUser', requestBody).pipe(
      map((res: any) => {
        if (res['status'] === 'ok') {
          const newToken = res['jwt'];
          localStorage.setItem('token', newToken);
          sessionStorage.setItem('token', newToken);

          new Promise(f => setTimeout(f, 1000));

          return res;
        }
      })
    );
  }



  GetRoleListByUserName(username:string):Observable<any>
  {
       return this.http.get(apiUrl + '/api/GetRoleListByUserName?username=' + username);
  }



  GetActionListByUserName(username:string):Observable<any>
  {
       return this.http.get(apiUrl + '/api/GetActionListByUserName?username=' + username);
  }




  GetAccessibleDaysByUsername(username:string):Observable<any>
  {
       return this.http.get(apiUrl + '/api/GetAccessibleDaysByUsername?username=' + username);
  }



  UpdateAccessibleDays(model: any){

    return this.http.post(apiUrl +'/api/UpdateAccessibleDays' , model);
  }



}
