//Service calling
//https://www.positronx.io/angular-httpclient-http-service/

import { Injectable, inject } from '@angular/core'
import { Login } from 'app/models/login';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import { environment } from 'environments/environment';
import { RouteInfo } from 'app/shared/vertical-menu/vertical-menu.metadata';
import { DirectDebitRequest } from 'app/models/DirectDebitRequest';
import { Model } from '@swimlane/ngx-datatable';
const apiUrl=environment.baseUrl;
interface ApiResponse {
  reqBody: string;}
@Injectable({
  providedIn: "root",
})
export class LoginService {
  private isAuthenticate = false;

  // Method to check if the user is authenticated
  isAuthenticated(): boolean {
    if (localStorage.getItem("token")) {
      return true;
    }
    // return !!sessionStorage.getItem('token');
  }
  cookieService = inject(CookieService);

  constructor(private _http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  // HttpClient API get() method => Fetch employees list
  getEmployees(): Observable<Login[]> {
    return this._http
      .get<Login[]>(apiUrl + "/api/employee/getall")
      .pipe(retry(1), catchError(this.handleError));
  }

  getEmployeeByCode(empCode: string): Observable<Login> {
    return this._http
      .get<Login>(apiUrl + "/api/employee/" + empCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  login(log: Login): Observable<Login> {
    //const headers = new HttpHeaders().set('XSRF-TOKEN',  this.cookieService.get('XSRF-TOKEN'));
    sessionStorage.removeItem("token");
    // return this._http.post<Login>(apiUrl + '/api/Auth/LogIn', log);
    var result = this._http.post<Login>(apiUrl + "/api/Auth/LogIn", log);
    this.isAuthenticate = true;
    return result;

    //// result.subscribe((response: any) => {
    ////   if(response['status'] === 'Logged'){
    ////     this.isAuthenticate = true;
    // //   }
    //// },
    //// (error: any) => {
    ////   console.error(error);
    // // })
  }
  getNavbars(): Observable<RouteInfo[]> {
    return this._http.get<RouteInfo[]>(apiUrl + "/api/User/GetNavbar");
  }
  // Error handling
  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  getUserByPhone(Mobile: string): Observable<any> {
    return this._http.get<any>(
      apiUrl + "api/Auth/GetUserByPhone/phone=" + Mobile,
      { responseType: "json" }
    );
  }

  getUserByEmail(Email: string): Observable<any> {
    return this._http.get<any>(
      apiUrl + "api/Auth/GetUserByEmail/email=" + Email,
      { responseType: "json" }
    );
  }

  checkUserAccess(username: string, route: string) {
    return this._http.get<boolean>(
      apiUrl + "/api/validate-route?username=" + username + "&route=" + route,
      { responseType: "json" }
    );
  }

  GetUserLogs() {
    return this._http.get(apiUrl + "/api/GetAllUserLogs");
  }
  GetApiLogs() {
    return this._http.get(apiUrl + "/api/GetApiLogs");
  }

  FilterUserLogs(username: string, fDate: string, tDate: string) {
    return this._http.get(
      apiUrl +
        "/api/FilterUserLogs?username=" +
        username +
        "&fDate=" +
        fDate +
        "&tDate=" +
        tDate
    );
  }

  FilterApiLogs(username: string, fDate: string, tDate: string) {
    return this._http.get(
      apiUrl +
        "/api/FilterApiLogs?username=" +
        username +
        "&fDate=" +
        fDate +
        "&tDate=" +
        tDate
    );
  }

  // GetApiLogsReqBodyById(Id: any) {
  //   return this._http.get(
  //     apiUrl +
  //       "/api/ReqBodyByApiLogsId?Id=" + Id
  //   );
  // }

  GetApiLogsReqBodyById(Id: any): Observable<ApiResponse> {
    return this._http.get<ApiResponse>(
      `${apiUrl}/api/ReqBodyByApiLogsId?Id=${Id}`
    );
  }

  FilterDirectDebitLogs(
    fDate: string,
    tDate: string,
    accountNo: string,
    status: any
  ) {
    return this._http.get(
      apiUrl +
        "/api/GetDirectDebitLogs?fDate=" +
        fDate +
        "&tDate=" +
        tDate +
        "&accountNo=" +
        accountNo +
        "&status=" +
        status
    );
  }

  GetAllDirectDebitLogs() {
    return this._http.get(apiUrl + "/api/AllDirectDebitLogs");
  }

  GetAllDirectDebitAccounts() {
    return this._http.get(apiUrl + "/api/GetTodayScheduleForDirectDebit");
  }

  GetAllDirectDebitAccountsForDashBorad(parentChannelId: any) {
    return this._http.get(
      apiUrl +
        "/api/GetTodayScheduleForDirectDebitBYChannelId?parentChannelId=" +
        parentChannelId
    );
  }

  CheckDDProcessStatus() {
    return this._http.get<boolean>(apiUrl + "/api/CheckDDProcessStatus");
  }

  GetDirectDebitLogById(Id: any): Observable<ApiResponse> {
    return this._http.get<ApiResponse>(
      `${apiUrl}/api/GetDirectDebitLogById?Id=${Id}`
    );
  }

  GetAllDirectDebitLogsSummary(
    fDate: string,
    tDate: string,
    accountNo: string,
    status: any,
    parentChannelId: any
  ) {
    return this._http.get(
      apiUrl +
        "/api/AllDirectDebitLogsSummary?fDate=" +
        fDate +
        "&tDate=" +
        tDate +
        "&accountNo=" +
        accountNo +
        "&status=" +
        status +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  GetTodayDirectDebitInfo(parentChannelId: any) {
    return this._http.get(
      apiUrl + "/api/GetTodayDirectDebit?parentChannelId=" + parentChannelId
    );
  }

  GetAllInvalidTokenLog(parentChannelId: any) {
    return this._http.get(
      apiUrl + "/api/AllInvalidTokenLogInfo?parentChannelId=" + parentChannelId
    );
  }

  GetDirectDebitLogsDetails(accountId: any) {
    return this._http.get(
      apiUrl +
        "/api/GetDirectDebitLogsDetailsByAccountId?accountId=" +
        accountId
    );
  }

  NagadSingleDirectDebit(accountId) {
    return this._http.get(
      apiUrl + "/api/NagadDirectDebit?accountId=" + accountId
    );
  }

  NagadCheckedDirectDebitProcess(accounts) {
    var result = this._http.post<DirectDebitRequest[]>(
      apiUrl + "/api/NagadCheckedDirectDebitProcess",
      accounts
    );

    return result;
  }

  GetDcryptedData(encryptedData: string) {
    const encryptVm = { encryptedData };
    return this._http.post(apiUrl + "/api/DecryptData", encryptVm);
  }

  FilterNagadDisburseLogs(
    fDate: string,
    tDate: string,
    accountNo: string,
    status: any
  ) {
    return this._http.get(
      apiUrl +
        "/api/FilterNagadDisburseLogs?fDate=" +
        fDate +
        "&tDate=" +
        tDate +
        "&accountNo=" +
        accountNo +
        "&status=" +
        status
    );
  }

  GetNagadDisbursementLogs() {
    return this._http.get(apiUrl + "/api/GetNagadDisbursementLogs");
  }

  GetSMSResponseLogs() {
    return this._http.get(apiUrl + "/api/GetSMSResponseLogs");
  }

  FilterSMSResponseLogs(fDate: string, tDate: string, to: string, status: any) {
    return this._http.get(
      apiUrl +
        "/api/FilterSMSResponseLogs?fDate=" +
        fDate +
        "&tDate=" +
        tDate +
        "&to=" +
        to +
        "&status=" +
        status
    );
  }

  FilterPaymentChannelLog(
    fDate: string,
    tDate: string,
    accountNo: string
  ) {
    return this._http.get(
      apiUrl +
        "/api/GetFilterPaymentChannelLog?fDate=" +
        fDate +
        "&tDate=" +
        tDate +
        "&accountNo=" +
        accountNo 
    );
  }
}
