<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9">
                    <h5 class="card-title page-heading" style="color: black">Dormant Account List</h5>
                </div>
                <div class="col-md-2">
                   
                   
                </div>
                <div class="col-md-1">
                  <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                    <i class="icon-arrow-left" style="font-size: 17px;"></i>
                </button>
                </div>
              </div>
              <hr style="height: 10%" />

<div class="form-group row">
    <div class="col-6">
        <div class="row">
            <div class="col-4 mt-1">
                <label style="font-size: 16px">Account Name</label>
            </div>
            <div class="col-8">
                <div class="input-group-prepend">
                    <p class="input-group-text" style="border-right: none">
                        <i class="fa fa-search"></i>
                    </p>
                    <input type="text" class="form-control" [(ngModel)]="accountName"
                        (keyup.enter)="loadDormantRelatedData()" placeholder="Search by Account name ..."
                        style="border-left: none; margin-left: -6px" />
                </div>
            </div>
        </div>

    </div>

    <div class="col-6">
        <div class="row">
            <div class="col-4">
                <label style="font-size: 16px">Filter Type</label>
            </div>

            <div class="input-group col-8">
                <select class="form-control" [(ngModel)]="typeId" (change)="onFilterTypeChange($event.target.value)"
                    (change)="SearchByParameter()">
                    <option value="0">Select</option>
                    <option value="1">Account No</option>
                    <option value="2">Dormant Date</option>
                </select>
            </div>
        </div>

    </div>
</div>

<div class="row">
    <div class="col-6 row" *ngIf="showaccountNoField">
        <div class="col-4">
            <label style="font-size: 16px">Account No</label>
        </div>
        <div class="col-8">
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="accountNo"
                    placeholder="Search by Account No...." />
                <button class="btn btn-danger" (click)="deleteFilterTypeFields(1)">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="col-6 row">
        <div class="col-4">
            <label style="font-size: 16px"></label>
        </div>
        <div class="col-8">
            <div class="input-group"></div>
        </div>
    </div>
</div>

<div class="row" *ngIf="showencashmentDateField">
    <div class="col-2 mt-4">Dormant Date</div>
    <div class="col-4">
        <label></label>

        <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="dormantDate" placeholder="Select Encashment Date" />
            <button class="btn btn-danger" (click)="deleteFilterTypeFields(2)">
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
</div>

<div class="row mb-3">
    <div class="col-12">
        
        <button class="btn btn-success pull-right" (click)="loadDormantRelatedData()">
            Search
        </button>
    </div>
</div>



             
            <div class="card-body" style="overflow: auto; max-height: 600px">
                <table id="example" id="example" class="table table-striped table-bordered scrollable-table" style="width: 100%"
                    style="width: 100%">
                <thead style="
                              position: sticky;
                              top: -25px;
                              z-index: 1;
                              background-color: #eef5e9;
                            ">
                    <tr>
                        <th>Action</th>
                        <th>Customer Info</th>
                        <th>Account No</th>
                        <!-- <th>Account Name</th> -->
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container ></ng-container>
                    <tr *ngFor="let item of DormantAccountList">
                        <td>


                            <button class="btn btn-primary mt-1" *ngIf="item.status == 0"
                                style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="Approve(item.id)"
                               >Approve</button>
                        </td>
                        <td>
                            <span><span style="font-weight: 600;">Name:</span> {{item.customerAccount.customers.customerNameEng}}</span><br>
                            <span><span style="font-weight: 600;">Email:</span> {{item.customerAccount.customers.email}}</span><br>
                            <span><span style="font-weight: 600;">Mobile:</span> {{item.customerAccount.customers.mobileNo}}</span>
                        
                        
                        </td>
                        <td>{{item.customerAccount?.customerAccountNo}}</td>
                        <!-- <td>{{item.customerAccount?.accountTilte}}</td> -->

                        <td>{{item.effectiveDate | date: dateFormat}}</td>
                        <td>{{item.status == 0 ? 'Pending' : 'Approved'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>  
    </div>
    </div>
</div>
