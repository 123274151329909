<!-- 
<div class="row " >
    <div class="col-6 mt-3">
      <h5 class="m-0 font-weight-bold text-primary page-heading">DPS MIS Report</h5>
    </div>
    <div class="col-6 mt-3">
    </div>
  </div>


  <hr> -->
  <div class="w-100 pt-3">
   
    <div class="row">
      <div class="col-md-9">
          <h5 class="card-title page-heading" style="color: black">DPS MIS Report</h5>
    
      </div>
      <div class="col-md-2">
         
         
      </div>
      <div class="col-md-1">
        <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
          <i class="icon-arrow-left" style="font-size: 17px;"></i>
      </button>
      </div>
    </div>
    <hr style="height: 10%" />
  </div>
 

      <div class="col-12 row">

        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card cuscard " (click)="onWaiting(1)" style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1">
                  <div class="black customIcon" style="width: 64px!important;">
                    <i class="fa fa-print"></i>

                  </div>
                  <div class="black ml-2 mt-1">
                    <span>Upcoming Matured Report</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card  cuscard" (click)="onWaiting(2)" style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black customIcon" style="width: 81px!important;">

                    <i class="fa fa-print"></i>
                  </div>
                  <div class="black ml-2 mt-1">
                    <span>Prematured Encashment Report</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
          <div class="card cuscard" (click)="onWaiting(3)" style="cursor: pointer; height: 100px;">
            <div class="card-content ">
              <div class="card-body py-0">
                <div class="media pb-1">
                  <div class="black customIcon">
                    <i class="fa fa-print"></i>
                    <!-- -->

                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Interest Report</span>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>



        <div class="col-lg-3 col-md-6 col-12 col-sm-12">
            <div class="card cuscard" (click)="onWaiting(4)" style="cursor: pointer; height: 100px;">
              <div class="card-content ">
                <div class="card-body py-0">
                  <div class="media pb-1">
                    <div class="black customIcon" style="width: 53px!important;">
                      <i class="fa fa-print"></i>
                      <!-- -->

                    </div>
                    <div class="black ml-2 mt-2">
                      <span>Encashment Report</span>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>



    </div>



  <hr>

  <div class="col-12 row">

    <div class="col-lg-3 col-md-6 col-12 col-sm-12">
      <div class="card cuscard " (click)="onWaiting(5)" style="cursor: pointer; height: 100px;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1 align-self-center">
              <div class="black customIcon">
                <i class="fa fa-print"></i>

              </div>
              <div class="black ml-2 mt-3">
                <span>DPS Lean Report</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>









</div>
