<section>
  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="container-fluid">


        <div class="row">
          <div class="col-md-9">
            <h5 class="card-title" style="color: black">Group Creation</h5>
             
          </div>
          <div class="col-md-2">
             
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-info btn-gotolist" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-right:5px;margin-left: 8px; border: none;">List View</button>

          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
              <i class="icon-arrow-left" style="font-size: 17px;"></i>
          </button>
          </div>
        </div>


        <!-- <div class="row">
          <div class="col-md-9">
            <h5 class="card-title" style="color: black">Group Creation</h5>

          </div>
          <div class="col-md-2">
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-info btn-gotolist" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-right:5px;margin-left: 8px; border: none;">List View</button>

          </div>
          <div class="col-md-1">
             
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()">
                  <i class="icon-arrow-left" style="font-size: 17px; float: right; padding: 2px;"></i>
              </button>
          
          </div>
        </div> -->


         <hr>
        <form [formGroup]="frmGroup">
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="NatureId" class="col-sm-4 col-form-label">GL Type<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <select class="form-control" (change)="getGroupByNatureId($event.target.value)" formControlName="NatureId">
                    <option value="">Select</option>
                    <option *ngFor="let item of GropupNatureList" [value]="item.id">{{item.natureName}} - {{item.natureCode}}</option>
                  </select>
                </div>
              </div>

            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="GroupName" class="col-sm-4 col-form-label">Parent Group Name<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <!-- <select class="form-control" (change)="GetSubGroupCode($event.target.value)"
                  formControlName="ParentGroupId" name="ParentGroupId">
                  <option value="0">Select Parent Group</option>
                  <option *ngFor="let item of ParentgroupList" [value]="item.id">{{item.groupName}} - {{item.groupCode}}</option>
                </select> -->

                <select (change)="GetSubGroupCode($event.target.value)" class="form-control" name="ParentGroupId" formControlName="ParentGroupId">
                  <option value="0">Select Parent Group</option>
                  <ng-container *ngFor="let item of ParentgroupList">
                    <ng-container *ngIf="!item.parentGroupId; else childOption">
                      <!-- Render options for parent groups -->
                      <option [value]="item.id" style="color:blue">{{item.groupName}} - {{item.groupCode}}</option>
                    </ng-container>
                    
                    <ng-template #childOption>
                      <!-- Render options for child groups -->
                      <option [value]="item.id" style="color:darkblue">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{item.groupName}} - {{item.groupCode}}</option>
                    </ng-template>
                    
                    

                    
                  </ng-container>
                </select>
                </div>
              </div>

            </div>
           
            <div class="col-md-12">
              <div class="form-group row">
                <label for="GroupName" class="col-sm-4 col-form-label">Group Name<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="GroupName" placeholder="Group Name">
                </div>
              </div>

            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="GroupCode" class="col-sm-4 col-form-label">Group Code<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" readonly formControlName="GroupCode" placeholder="Group Code">
                </div>
              </div>

            </div>
          

            <!-- <div class="col-md-12">
              <div class="form-group row">
                <label for="sortOrder" class="col-sm-4 col-form-label">Sort Order<span
                    style="color:red; font-size: 15px; ">*</span></label>
                <div class="col-sm-8">
                  <input type="number" min="0" class="form-control" formControlName="sortOrder" placeholder="Sort Order">
                </div>
              </div>

            </div> -->
          </div>


          <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()"
            value="Submit" class="btn btn-success btn-add" style="float:right; margin-top:5px;">
            <span>{{ isEdit ? 'Update' : 'Save' }}</span>
          </button>
          <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary"
            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>

        </form>
      </div>
    </div>
  </div>

  <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
      <div class="container-fluid">

        <div class="row">
          <div class="col-md-9">
            <h5 class="card-title" style="color: black">Group List</h5> 
          </div>
          <div class="col-md-2">
             
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary btn-gotoadd"
          (click)="onClickShowSaveForm('saveForm')"
          style="float:right; margin-right:5px;margin-left: 8px;">Add New</button>
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
              <i class="icon-arrow-left" style="font-size: 17px;"></i>
          </button>
          </div>
        </div>





        <!-- <div class="row">
          <div class="col-md-9">       
             <h5 class="card-title" style="color: black">Group List</h5>
          </div>
          <div class="col-md-2">
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary btn-gotoadd"
          (click)="onClickShowSaveForm('saveForm')"
          style="float:right; margin-right:5px;margin-left: 8px;">Add New</button>
          </div>
          <div class="col-md-1">
             
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()">
                  <i class="icon-arrow-left" style="font-size: 17px; float: right; padding: 2px;"></i>
              </button>
          
          </div>
        </div> -->

          

        <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left;">
              <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
                  <option value="5">5</option>
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right;">
              <input type="text" class="form-control"  [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
          </div>
      </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th>Nature Code</th>
              <th>Nature Name</th>
              <th>Group Code</th>
              <th>Group Name</th>
              <th>Sub Group Code</th>
              <th>Sub Group Name</th>
              <th style="width: 20%;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of groupList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index;">
              <!-- <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td> -->
              <td>{{item.nature.natureCode}}</td>
              <td>{{item.nature.natureName}}</td>
              <td>{{item.parentGroupId==null?item.groupCode:item.parentGroup?.groupCode}}</td>
              <td>{{item.parentGroupId==null?item.groupName:item.parentGroup?.groupName}}</td>
              <td>{{item.parentGroupId==null?"":item.groupCode}}</td>
              <td>{{item.parentGroupId==null?"":item.groupName}}</td>
              <!-- <td>{{item.sortOrder}}</td> -->

              <td >
                <button class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger btn-sm" style="margin-left: 5px;"
                  (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>

              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>