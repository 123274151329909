import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: "app-prematured-encashment-report",
  templateUrl: "./prematured-encashment-report.component.html",
  styleUrls: ["./prematured-encashment-report.component.scss"],
})
export class PrematuredEncashmentReportComponent implements OnInit {
  dateFormat: string = "";
  businessdate: any;
  businessdatevalue: any;
  constructor(
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private location: Location
  ) {}

  ngOnInit(): void {
      var thumb = JSON.parse(localStorage.getItem("thumb"));
      this.dateFormat = thumb.thumbRule.dateFormat;

    this.getCurrentBusinessDate();
  }
  goBack() {
    this.location.back();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessdatevalue = res.business_Date;
    });
  }
  OpenPDF() {
    this.pdfService
      .PrematuredEncashmentPdf(this.businessdatevalue)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
  }
}
