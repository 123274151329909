<!-- Your existing button divs -->
<!-- <div class="container">
  <div class="row">

    <div class="col-md-4">
      <button class="btnMenu" style="margin-right: 5px; width: 100%;" [ngClass]="{'active': activeButton === 1}"
        (click)="setActiveButton(1); setActiveSubButton(1)">Cash Payment</button>
    </div>

    <div class="col-md-4">
      <button class="btnMenu" style="margin-right: 5px; width: 100%;" [ngClass]="{'active': activeButton === 2}"
        (click)="setActiveButton(2); setActiveSubButton(2)">Cash Receive</button>
    </div>

    <div class="col-md-4">
      <button class="btnMenu" style="margin-right: 5px; width: 100%;" [ngClass]="{'active': activeButton === 3}"
        (click)="setActiveButton(3); setActiveSubButton(3)">Fund Transfer</button>
    </div>
  </div>
</div> -->

<!-- Your existing form content -->
<form [formGroup]="frmGroup">
  <!-- ... -->
  <div class="col-md-12">
    <div class="card" style="width: 100%">
      <div class="card-body">
        <div>
          <div style="display: flex; justify-content: space-between;">
            <h5 class="card-title" style="color: black">Fund Transfer</h5>
          <img style="cursor: pointer;" [routerLink]="['/accounting/import-fund-transfer']" src="../../../assets/img/icons/upload.png" width="50" alt="">
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <form [formGroup]="form">
                <div class="form-group row d-none">
                  <label class="col-sm-4">Search By:</label>
                  <div class="col-sm-8">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-primary mr-2"
                        (click)="selectSearchType('BDGenerateCode')"
                      >
                        BD GENERATE CODE
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        (click)="selectSearchType('PaymentAccount')"
                      >
                        Payment Account
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="form-group row"
                  *ngIf="searchType === 'BDGenerateCode'"
                >
                  <label for="BDGenerateCode" class="col-sm-4"
                    >BD GENERATE CODE</label
                  >
                  <div class="col-sm-8">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        id="BDGenerateCode"
                        name="BDGenerateCode"
                        [formControl]="formControl"
                        placeholder="Account Code"
                      />
                    </div>
                    <ul class="list-group mt-2" *ngIf="hideShow === 'Show'">
                      <li
                        class="list-group-item"
                        *ngFor="let suggestion of suggestions"
                        (click)="selectSuggestion(suggestion)"
                        (click)="hideAfterSelectValue('Hide')"
                      >
                        {{ suggestion.bdGenerateCode }}
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
              <div
                class="form-group row"
                *ngIf="searchType === 'PaymentAccount'"
              >
                <label for="name" class="col-sm-4 col-form-label"
                  >Debit Account</label
                >
                <div class="col-sm-8">
                  <div class="input-group">
                    <!-- <input type="text" class="form-control" name="customerCode" formControlName="customerCode"
                      [(ngModel)]="accountNoFund1" placeholder="Type Account No...."
                      (keyup.enter)="loadUserDataByAccountNoFundFirst()"> -->

                    <select
                      [(ngModel)]="customerAccountId2"
                      class="form-control"
                      (change)="onAccountChangeFirst($event.target.value)"
                    >
                      <option
                        *ngFor="let l of LedgerList"
                        [value]="l.ledger?.id"
                      >
                        {{ l.ledger?.accountCode }} -
                        {{ l.ledger?.accountName }}
                      </option>
                    </select>
                    <!-- <div class="input-group-append">
                      <button class="btn btn-primary btn-sm"
                        (click)="loadUserDataByAccountNoFundFirst()">Find</button>
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- <div class="form-group row">
                <label for="" class="col-sm-4 ">Debit Account Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [value]="customerInfo_cashFundTransfer1" name="customerName" readonly>
                </div>
              </div> -->
            </div>
            <div class="col-md-12">
              <div
                class="form-group row"
                *ngIf="searchType === 'PaymentAccount'"
              >
                <label for="fathersName" class="col-sm-4 col-form-label"
                  >Credit Account</label
                >
                <div class="col-sm-8">
                  <div class="input-group">
                    <select
                      [(ngModel)]="customerAccountId"
                      class="form-control"
                      (change)="onAccountChangeSecond($event.target.value)"
                    >
                      <option
                        *ngFor="let l of LedgerList"
                        [value]="l.ledger?.id"
                      >
                        {{ l.ledger?.accountCode }} -
                        {{ l.ledger?.accountName }}
                      </option>
                    </select>

                    <!-- <input type="text" class="form-control" name="customerCode" formControlName="customerCode"
                      [(ngModel)]="accountNoFund2" placeholder="Type Account No...."
                      (keyup.enter)="loadUserDataByAccountNoFundLast()">
                    <div class="input-group-append">
                      <button class="btn btn-primary btn-sm"
                        (click)="loadUserDataByAccountNoFundLast()">Find</button>
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- <div class="form-group row">
                <label for="" class="col-sm-4 ">Credit Account Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" [value]="customerInfo_cashFundTransfer2" name="customerName" readonly>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-12">
            <div class="form-group row">
              <label for="transactionDate" class="col-sm-4 col-form-label">Transaction date</label>
              <div class="col-sm-8">
                <input type="date" class="form-control" name="transactionDate" formControlName="transactionDate"
                  placeholder="Type here....">
              </div>
            </div>
          </div> -->
          <div class="col-md-12">
            <div class="form-group row">
              <label for="" class="col-sm-4">Currency</label>
              <div class="col-sm-8">
                <select
                  class="form-control"
                  name="currencyId"
                  formControlName="currencyId"
                >
                  <option value="">Select</option>
                  <ng-container *ngFor="let item of countryList | async">
                    <option [value]="item.id">{{ item.currency }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <label for="voucherAmount" class="col-sm-4 col-form-label"
                >Amount</label
              >
              <div class="col-sm-8">
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  name="voucherAmount"
                  formControlName="voucherAmount"
                  placeholder="Amount...."
                  (keyup)="CheckBalance()"
                />
                <span
                  class="text-danger"
                  style="font-size: 10px; font-weight: bold"
                  *ngIf="!isvalid"
                  >Invalid Amount. Amount cannot be more than the Balance.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="remarks" class="col-sm-4 col-form-label"
                >Narration</label
              >
              <div class="col-sm-8">
                <textarea
                  class="form-control"
                  formControlName="remarks"
                  id=""
                  cols="30"
                  rows="2"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- <button data-toggle="tooltip" (click)="onsubmit()" *ngIf="isvalid" title="Save" type="button" class="btn btn-success"
          style="float:right; margin-top:5px;">Save </button> -->
        <button
          data-toggle="tooltip"
          (click)="onsubmit()"
          *ngIf="isBtnValid"
          title="Save"
          type="button"
          class="btn btn-success"
          style="float: right; margin-top: 5px"
        >
          Save
        </button>

        <button
          type="button"
          title="Refresh"
          class="btn btn-secondary"
          style="
            float: right;
            margin-top: 5px;
            margin-right: 5px;
            margin-left: 8px;
          "
        >
          Refresh
        </button>

        <!-- ... -->
      </div>
    </div>
  </div>
  <!-- ... -->
</form>
