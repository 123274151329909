
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { CibreportsService } from "../cibreports.service";

@Component({
  selector: "app-cibcontract-report",
  templateUrl: "./cibcontract-report.component.html",
  styleUrls: ["./cibcontract-report.component.scss"],
})
export class CibcontractReportComponent implements OnInit {
  fromDate: Date = new Date();
  monthsId: number = 0;
  contractDataList: any = [];

  constructor(
    private location: Location,
    private cibService: CibreportsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }

  OpenTextWithDate() {
    const currentYear = new Date().getFullYear();

    this.cibService
      .GenerateCIBCOntractDataTextWithMonth(this.monthsId, currentYear)
      .subscribe((x) => {
        let blob: Blob = x.body as Blob;
        let url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;

        a.download = "CIBContractReport";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  PreviewData() {
    const currentYear = new Date().getFullYear();

    this.cibService
      .GetCIBContractData(this.monthsId, currentYear)
      .subscribe((res) => {
        this.contractDataList = res;
        this.changeDetectorRef.detectChanges();
      });
  }
}
