import { HttpClient, HttpRequest, HttpEvent  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { of, Observable,  } from 'rxjs';

import { ApiResponse } from './response';
import { observable } from 'core-js/fn/symbol';

const apiUrl = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private registerURL = environment.registernurl;
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  sendOTPForForgotPassWord(username: string, emailAddress: string, otyTypeId: number, otpCarrierId: number): Observable<ApiResponse> {
    //const url = `${this.baseUrl}`;
    const url = this.baseUrl + '/api/Account/ekyc/SendOtpForForgot?username=' + username + '&emailAddress=' + emailAddress + '&otyTypeId=' + otyTypeId + '&otpCarrierId=' + otpCarrierId;
    return this.http.post<any>(url, null);
  }



  sendOTP(mobileNo: string, emailAddress: string, otyTypeId: number, otpCarrierId: number): Observable<ApiResponse> {
    //const url = `${this.baseUrl}`;
    const url = this.baseUrl + '/api/Account/ekyc/sendtoken?mobileNo=' + mobileNo + '&emailAddress=' + emailAddress + '&otyTypeId=' + otyTypeId + '&otpCarrierId=' + otpCarrierId;
    return this.http.post<any>(url, null);
  }

  verifyOTP(username: string, emailAddress: string, otyTypeId: number, passcode: string): Observable<ApiResponse> {
    //const url = `${this.baseUrl}`;
    const url = this.baseUrl + '/api/Account/ekyc/verifytoken?username=' + username + '&emailAddress=' + emailAddress + '&otyTypeId=' + otyTypeId + '&passcode=' + passcode;
    return this.http.post<any>(url, null);
  }

  // verifyNID(nid: string, dob: string): Observable<ApiResponse> {
  //   //const url = `${this.baseUrl}`;
  //   const url = this.baseUrl + '/api/Account/ekyc/verifynid?nid=' + nid + '&dob=' + dob;
  //   return this.http.post<any>(url, null);
  // }

  verifyNID(model: any): Observable<ApiResponse> {

    //const url = `${this.baseUrl}`;
    const url = this.baseUrl + '/api/Account/ekyc/verifynid';
    return this.http.post<any>(url, model);
  }


  // UpdateverifyNID(model: any): Observable<ApiResponse> {
  //   //const url = `${this.baseUrl}`;
  //   const url = this.baseUrl + '/api/Account/ekyc/UpdateVerifyNIDData';
  //   return this.http.post<any>(url, model);
  // }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }

  logUser(browser: string, ip:string): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/LogUser?browser=${browser}&ip=${ip}`);
  }

  changePassword(username: string, password1: string, password2: string): Observable<ApiResponse> {
    //const url = `${this.baseUrl}`;

    const url = this.baseUrl + '/api/Auth/ekyc/SetNewPassword?username=' + username + '&password1=' + password1 + '&password2=' + password2;
    return this.http.post<any>(url, null);
  }

  checkCustomerBymobile(mobileNo: string): Observable<any> {

    return this.http.get(apiUrl + '/api/CustomerAccount/CheckWhiteListByMobileNo?MobileNo=' + mobileNo);
  }




  SaveAutoCutomerFromWhiteList(mobileNo: string): Observable<any> {
    sessionStorage.removeItem('token');

    return this.http.get<any>(apiUrl + '/api/CustomerAccount/SaveWhiteListCustomer?MobileNo=' + mobileNo);
  }

}


    // sendOTP(data:any): Observable<any> {
    // const url = `${this.baseUrl}`;
    // return this.http.post<any>(url, data);
    // }
