import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { DOCUMENT } from '@angular/common';
import { CustomizerService } from '../services/customizer.service';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'app/services/shared.service';
import { EmployeeserviceService } from 'app/employee/employee-service/employeeservice.service';
import { date } from 'ngx-custom-validators/src/app/date/validator';
import { SecurityService } from 'app/services/security.service';
import { SessionService } from 'app/services/session.service';
import { PaymentService } from 'app/payment/payment.service';


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  dateFormat: string = "";
  modalRef: NgbModalRef | undefined;
  cPassword = "";
  profilePic: string = "";
  errorMessage: string = "";
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = "assets/img/logo.png";
  menuPosition = "Side";
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  currentUser: any;
  frmGroupUser: FormGroup;
  isEdit: boolean = false;
  closeResult: string;
  customerDetailsInfos: any;
  customerList: any;
  contactList: any;
  addressList: any;
  userSessionId: string;
  orgName: string = "";
  passwordValidate: string[];

  @ViewChild("search") searchElement: ElementRef;
  @ViewChildren("searchResults") searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();

  public config: any = {};

  businessDate: any;

  isNavbarFixed = false;

  // Add a scroll event listener
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const navbarContainer = document.querySelector(".navbar-container");
    if (window.pageYOffset > 0) {
      // Add the fixed-navbar class when scrolling down
      navbarContainer.classList.add("fixed-navbar");
      this.isNavbarFixed = true;
    } else {
      // Remove the fixed-navbar class when scrolling to the top
      navbarContainer.classList.remove("fixed-navbar");
      this.isNavbarFixed = false;
    }
  }

  constructor(
    private _service: EmployeeserviceService,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private securityService: SecurityService,
    private sessionService: SessionService,
    private paymentService: PaymentService,
    private router: Router,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe((isShow) => {
      this.hideSidebar = !isShow;

    });
  }

  ngOnChanges() {}

  ngOnInit() {

    this.initForm();
    this.getCurrentBusinessDate();
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    this.layoutService.getCurrentUserName().subscribe((res) => {
      localStorage.setItem("userName", res["userName"]);
      this.currentUser = res;
      this.cPassword = res;
      this.profilePic = res.baseString;
    });
    this.GetThumbRule();
    this.GetUserInfos();
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.thumbRule.dateFormat;


    // this.GetCustomerDetails();
    //       this.GetContactList();
    //       this.GetAddressList();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;
    });
  }

  GetThumbRule() {
    this.paymentService.GetThumbRule().subscribe((res: any) => {
      this.orgName = res.thumbRule.orgName;

      const myObjectString = JSON.stringify(res);
      sessionStorage.setItem("thumb", myObjectString);
      localStorage.setItem("thumb", myObjectString);
    });
  }

  GetUserInfos() {
    this.paymentService.GetUserInfos().subscribe((res: any) => {
      const myObjectString = JSON.stringify(res.eventName);
      sessionStorage.setItem("userevents", myObjectString);
    });
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );

    var browserIdentity = localStorage.getItem("browserIdentity");
    this.sessionService.fetchAndSetUserSession(
      localStorage.getItem("userName"),
      browserIdentity
    );
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {
    if (
      this.config.layout.menuPosition &&
      this.config.layout.menuPosition.toString().trim() != ""
    ) {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = "";
    }
  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add(
        "first-active-item"
      );
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove(
        "first-active-item"
      );
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = "";
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != "") {
        this.control.setValue("");
        this.searchOpenClass = "";
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === "en") {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    } else if (language === "es") {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    } else if (language === "pt") {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    } else if (language === "bn") {
      this.selectedLanguageText = "Bangla";
      this.selectedLanguageFlag = "./assets/img/flags/bangladesh-flag.png";
    }
    this.currentLang = language;

    localStorage.setItem("lang", language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = "open";
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = "";
    }
    this.seachTextEmpty.emit(true);
  }

  logout() {
    sessionStorage.removeItem("token");
    localStorage.removeItem("token"); //removed
     localStorage.removeItem("userName"); //removed
    sessionStorage.removeItem("sessionToken");

    this.router.navigate(["/pages/login"]);
    this.modalService.dismissAll();
  }

  toggleNotificationSidebar() {
    return false;
    // this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  //#region profile
  open(content) {
    this.isEdit = true;

    // let options: any = {
    //   size: 'lg',
    //   centered: false
    // }

    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openModel(updatePhotoModal) {
    this.modalService.open(updatePhotoModal).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  formGroupReset: FormGroup;
  openChangepassword(resetcontent) {
    this.formGroupReset.patchValue({
      userName: this.currentUser.userName,
    });
    this.modalRef = this.modalService.open(resetcontent, {
      backdrop: "static",
      keyboard: false,
    });
  }

  initForm() {
    this.formGroupReset = this.formBuilder.group({
      userName: ["", Validators.required],
      currentpassword: ["", Validators.required],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  ChangePasswordSubmit() {
    if (this.formGroupReset.invalid) {
      this.shared.ShowAlert(
        "Alert",
        "Please fill in all required fields.",
        "warning"
      );
      return;
    }

    const model = {
      userName: this.formGroupReset.value.userName,
      currentpassword: this.formGroupReset.value.currentpassword,
      password: this.formGroupReset.value.password,
      confirmPassword: this.formGroupReset.value.confirmPassword,
    };
    if (
      this.formGroupReset.value.password !==
      this.formGroupReset.value.confirmPassword
    ) {
      this.shared.ShowAlert("Warning", "Password is not match!", "warning");
    } else {
      this._service.ChangePassword(model).subscribe(
        (res) => {
          this.shared.ShowAlert(
            "Thank you...",
            "Password updated successfully!",
            "success"
          );

          this.router.navigate(["/pages/login"]);
          this.closeModal();
          this.formGroupReset.reset();
          this.errorMessage = "";
        },
        (err) => {
          if (
            err &&
            err.error &&
            err.error === "Failed to change the password."
          ) {
            this.errorMessage = "Current password is incorrect.";
          } else {
            this.shared.ShowAlert(
              "Warning",
              "Something Went Wrong!",
              "warning"
            );
          }
        }
      );
    }
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  //#endregion

  openVoucherDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  // GetCustomerDetails() {

  //   this.layoutService.getlist().subscribe(data  => {
  //       this.customerList = data;
  //       // console.log(data);
  //       // console.log(777);
  //     })
  // }

  // GetContactList(){
  //   this.layoutService.getContactlist().subscribe(data=>{
  //     this.contactList = data;
  //     // console.log(5555);
  //     // console.log(data);
  //   })
  // }

  // GetAddressList(){
  //   this.layoutService.getAddressList().subscribe(data=>{
  //     this.addressList = data;
  //     console.log(5555);
  //     console.log(data);
  //   })
  // }

  ValidatePassword(event) {
    this.securityService.validatePassword(event).subscribe(
      (response: string[]) => {
        this.passwordValidate = response;
      },
      (error: any) => {
        console.error("Error occurred:", error);
      }
    );
  }

  // GetActiveSessionByUserName(username) {
  //   this.sessionService.GetActiveSessionByUserName(username).subscribe(
  //     (response: string) => {
  //       debugger;
  //       alert(response);
  //       localStorage.setItem('userSessionId', response);
  //       sessionStorage.setItem('userSessionId', response);

  //       this.userSessionId = response;
  //     },
  //     (error: any) => {
  //       console.error('Error occurred:', error);
  //     }
  //   );
  // }
}
