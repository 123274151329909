<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="pixinventLink"
      href="https://opus-bd.com/" style="color:#555555" ><img class="avatar" src="assets/img/opus.jpeg" alt="avatar" height="25" width="25" /> OPUS</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p>
</footer>
<!-- href="https://themeforest.net/user/pixinvent/portfolio?ref=pixinvent">OPUS</a> -->
<!--Footer Ends-->
