<div class="card">
  <div class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <h5 class="card-title page-heading" style="color: black">
            Direct Debit Logs
          </h5>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-1">
          <button
            class="btn btn-secondary btn-back"
            title="Go Back"
            (click)="goBack()"
            style="float: right"
          >
            <i class="icon-arrow-left" style="font-size: 17px"></i>
          </button>
        </div>
      </div>
      <hr style="height: 10%" />

      <div class="row">
        <label for="fromDate" class="col-md-2">From Date</label>
        <div class="col-md-4">
          <input type="date" class="form-control" [(ngModel)]="fromDate" />
        </div>
        <label for="toDate" class="col-md-2">To Date</label>
        <div class="col-md-4">
          <input type="date" class="form-control" [(ngModel)]="toDate" />
        </div>
      </div>

      <div class="row mt-3">
        <label for="" class="col-md-2">Account No</label>
        <div class="col-md-4">
          <input type="text" class="form-control" [(ngModel)]="accountNo" />
        </div>
        <label for="" class="col-md-2">Status</label>
        <div class="col-md-4">
          <select class="form-control" [(ngModel)]="status">
            <option value="">Select</option>
            <option value="true">Success</option>
            <option value="false">Fail</option>
          </select>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-9"></div>
        <div class="col-md-1 pull-left">
          <a
            href="javascript:void(0)"
            class="btn btn-success"
            (click)="FilterDirectDebitLogs()"
            >Find</a
          >
        </div>
        <div class="col-md-2">
          <a
            href="javascript:void(0)"
            class="btn btn-info"
            (click)="LoadAllLogs()"
            >Load All</a
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Loader -->
  <div *ngIf="loading" class="loader-overlay">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="card-body">
    <div class="container-fluid">
      <div class="row" style="overflow: scroll; max-height: 645px">
        <table style="width: 100%" class="table table-bordered table-striped">
          <thead
            style="
              position: sticky;
              top: -1px;
              z-index: 1;
              background-color: #eef5e9;
            "
          >
            <tr style="background-color: #eef5e9">
              <th class="text-center">Account No</th>
              <th class="text-center">Order Id</th>
              <th class="text-center">Payment Reference Id</th>
              <th class="text-center">Txn Id</th>
              <th class="text-center">Status</th>
              <th class="text-center">Payment Date</th>
              <th class="text-center">Business Date</th>
              <th class="text-center">Wallet No</th>
              <th class="text-center">Customer Id</th>
              <th class="text-center">Amount</th>
            </tr>
          </thead>
          <tbody style="background-color: white">
            <tr *ngFor="let item of logs">
              <td class="text-center">{{ item.customerAcc?.customerAccountNo }}</td>
              <td class="text-center">{{ item.orderId }}</td>
              <td class="text-center wrap-text">{{ item.paymentReferenceId }}</td>
              <td class="text-center">{{ item.txnId }}</td>
              <td class="text-center">{{ item.remarks }}</td>
              <td class="text-center">{{ item.paymentDateTime }}</td>
              <td class="text-center">{{ item.businessDate | date : dateFormat }}</td>
              <td class="text-center">{{ item.walletNo }}</td>
              <td class="text-center">{{ item.customerId }}</td>
              <td class="text-center">{{ item.amount | number }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
