<div class="row">
  <hr />
  <div class="col-md-12 card">
    <div class="card-header">
      <div class="row">
        <div class="col-10">
          <h5
            class="card-title"
            style="
              color: #888888;
              font-family: Arial;
              font-size: 20px !important;
            "
          >
            Loan Disbursement Report
          </h5>
        </div>
        <div class="col-2">
          <button
            class="btn btn-secondary pull-right"
            title="Go Back"
            (click)="goBack()"
          >
            <i class="icon-arrow-left" style="font-size: 17px"> </i>
          </button>
        </div>
      </div>
      <hr />
    </div>

    <div class="card-body">
      <div class="col-md-12">
        <div class="form-group row" id="DateRangeSelect">
          <label for="fromDate" class="col-sm-2 col-form-label text-left"
            >From Date <span class="redStar">*</span></label
          >
          <div class="col-sm-4 input-group">
            <input
              type="date"
              class="form-control"
              [(ngModel)]="fromDate"
              placeholder="Select From Date"
            />
          </div>
          <label for="toDate" class="col-sm-2 col-form-label text-left"
            >To Date <span class="redStar">*</span></label
          >
          <div class="col-sm-4 input-group">
            <input
              type="date"
              class="form-control"
              [(ngModel)]="toDate"
              placeholder="Select To Date"
            />
          </div>
          <!-- <label for="leadId" class="col-sm-2 col-form-label text-left">Lead ID</label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" [(ngModel)]="leadId" placeholder="Enter Lead ID (default is 0)">
                        </div> -->
        </div>

        <!-- <div class="form-group row">
                        <label class="col-sm-2 col-form-label">MFS Account</label>
                        <div class="col-sm-4">
                            <select type="text" class="form-control" [(ngModel)]="mfsType">
                                <option [value]="0">Select</option>
                                <ng-container *ngFor="let item of mfsAccountList">
                                    <option [value]="item.ledgerRelationId">{{item.accountName}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div> -->

        <div class="form-group row" style="margin-top: 10px">
          <label class="col-sm-8"></label>
          <div class="col-sm-4">
            <button
              class="btn btn-success"
              style="margin-left: 5px; float: right"
              (click)="OpenPdf()"
            >
              Print
            </button>
            <button
              class="btn btn-info"
              style="margin-left: 5px; float: right"
              (click)="OpenExcel()"
            >
              Excel
            </button>
            <button
              class="btn btn-warning"
              style="margin-left: 5px; float: right"
              (click)="LoanData()"
            >
              Load
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card col-12" style="overflow: auto; max-height: 600px">
        <table
          *ngIf="disbursementList && disbursementList.length > 0"
          class="table table-striped table-bordered scrollable-table"
          style="width: 100%"
        >
          <thead
            style="
              position: sticky;
              top: 0;
              z-index: 1;
              background-color: #f8f9fa;
            "
          >
            <tr>
              <th>Sl.</th>
              <th>Actions</th>
              <th>Customer Info</th>
              <th>Account Info</th>
              <th>Apply Date</th>
              <th>Disbursement Date</th>
              <!--  <th>MFS Type</th> -->
              <th>MFS Number</th>
              <th>Processing Fee</th>
              <th>Loan Amount</th>
              <th>Receivable Interest Amount</th>

              <th>Credit in MFS amount</th>
              <th>EMI Amount</th>
              <th>Total Receivable Amount</th>
              <th>Approver Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of disbursementList; let i = index">
              <td>{{ i + 1 }} .</td>
              <td>
                <button *ngIf="item.statusInfoId != 7"
                  class="btn btn-warning"
                  style="margin-left: 5px; float: right"
                  (click)="Disburse(item.leadId)"
                >
                  Disburse
                </button>

                <button *ngIf="item.statusInfoId == 7"
                  class="btn btn-outline-success"
                  style="margin-left: 5px; float: right"
                >
                  Approved
                </button>
              </td>
              <td>
                <span
                  ><span style="font-weight: 600">Customer Code: </span>
                  {{ item.customeR_CODE }}
                </span>
                <br />
                <span
                  ><span style="font-weight: 600">Customer Name: </span
                  >{{ item.customeR_NAME_ENG }}
                </span>
                <br />
                <span
                  ><span style="font-weight: 600">Mobile No:</span>
                  {{ item.mobilE_NO }}</span
                >
                <br />
                <span
                  ><span style="font-weight: 600">Credit Score:</span>
                  {{ item.lastCreditScore }}</span
                >
              </td>
              <td>
                <span
                  ><span style="font-weight: 600">Account No:</span>
                  {{ item.accounT_NO }}
                </span>
                <br />
                <span
                  ><span style="font-weight: 600">Account title:</span>
                  {{ item.accounT_TITLE }}</span
                >
              </td>

              <td>{{ formatDateString(item.applydate) }}</td>
              <td>{{ formatDateString(item.disbursementDate) }}</td>
              <!--  <td>{{item.mfsType}}</td> -->
              <td>{{ item.mfsNumber }}</td>
              <td style="text-align: right !important">
                {{ item.processingFee }}
              </td>
              <td style="text-align: right !important">
                {{ item.loanAmount }}
              </td>
              <td style="text-align: right !important">
                {{ item.interestAmount }}
              </td>

              <td style="text-align: right !important">
                {{ item.loanAmount - item.processingFee }}
              </td>
              <td style="text-align: right !important">
                {{ item.installmentSize }}
              </td>
              <td style="text-align: right !important">
                {{ item.outstanding }}
              </td>
              <td style="text-align: right !important">
                {{ item.approverName }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="6" style="text-align: right !important">Total</td>
              <td style="text-align: right !important">
                {{ totalprocessingFee | number : "1.2-2" }}
              </td>
              <td style="text-align: right !important">
                {{ totalLoanAmount | number : "1.2-2" }}
              </td>
              <td style="text-align: right !important">
                {{ totalInterestAmount | number : "1.2-2" }}
              </td>

              <td style="text-align: right !important">
                {{ totalDisburseAmount | number : "1.2-2" }}
              </td>
              <td style="text-align: right !important">
                {{ totalinstallmentSize | number : "1.2-2" }}
              </td>
              <td style="text-align: right !important">
                {{ totalOutstandingAmount | number : "1.2-2" }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div
        class="col-md-12"
        *ngIf="!disbursementList || disbursementList.length === 0"
      >
        <p style="text-align: center">No data available</p>
      </div>
    </div>
  </div>
</div>
