
<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">

            <div class="card-header row">

                <div class="col-10">
                <h5 class="card-title page-heading" style="color: black;font-family: Arial;font-size: 20px !important;">Financial Position Report</h5>
                </div>

                <div class="col-2">
                    <button class="btn btn-secondary pull-right btn-back" title="Go Back" (click)="goBack()">

                        <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                    </button>
                </div>
            </div>

          <hr>
            <div class="card-body">
                <div class="col-md-12">
                    <!-- <div class="form-group row" id="SBUSelect">
                        <label for="ledgerId" class="col-sm-3 col-form-label">SBU</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control" id="sbuId"></select>
                        </div>
                    </div>
                    <div class="form-group row" id="LedgerProject">
                        <label for="ledgerId" class="col-sm-3 col-form-label">Project</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control" id="projectId"></select>
                        </div>
                    </div> -->

                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Get Report By</label>
                        <div class="col-sm-10">
                            <select type="text" class="form-control"  [(ngModel)]="reportBy">
                                <option value="1">Group</option>
                                <option value="2">Ledger</option>
                            </select>
                        </div>
                    </div>

                    <!-- <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Report By</label>
                        <div class="col-sm-10">
                            <select type="text" class="form-control"  [(ngModel)]="reportByDate">
                                <option value="1">As On</option>
                                <option value="2">Date Range</option>
                            </select>
                        </div>
                    </div> -->

                    <div class="form-group row">
                        <label for="asDate" class="col-sm-2 col-form-label text-left">As on Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">

                                <input type="date" class="form-control" [(ngModel)]="asDate" placeholder="Select As on Date">



                        </div>

                    </div>
                    <!-- <div class="form-group row"  *ngIf="reportByDate == 2">
                        <label for="fromDate" class="col-sm-2 col-form-label text-left">From Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">

                                <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">



                        </div>
                        <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select From Date">
                        </div>
                    </div> -->


                    <div class="form-group row" style="margin-top:10px;">
                        <label class="col-sm-10"></label>
                        <div class="col-sm-2">
                            <!-- <button id="SubmitButton" style="float:right" class="btn btn-success btn-xs" title="Preview">Print</button> -->
                            <button  class="btn btn-success" style="float:right;" (click)="OpenPDF()">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
