
  <div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container-fluid">

          <div class="row">
            <div class="col-md-9">
                <h5 class="card-title page-heading" style="color: black">All Doccuments</h5>

            </div>
            <div class="col-md-2">
               
               
            </div>
            <div class="col-md-1">
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                <i class="icon-arrow-left" style="font-size: 17px;"></i>
            </button>
            </div>
          </div>
          <hr style="height: 10%" />
          
           
 
          <div class="card-body" style="overflow: auto; max-height: 600px;">
            <table id="example" id="example" class="table table-striped table-bordered scrollable-table" style="width:100%"
              style="width:100%">
              <thead style="position: sticky; top:-25px; z-index: 1;background-color: #EEF5E9">
              <tr>
                <th  style="width: 20%;">Sl.</th>
                <th  style="width: 20%;">Customer</th>
                <th  style="width: 20%;">Image/File</th>
                <th style="width: 20%;">Document Type </th>
                <th style="width: 20%;">Date</th>
                <th style="width: 20%;">Ref No</th>
                <th  style="width: 20%; text-align: center;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let document of documentList;let i=index">
                <td style="width: 20%;">{{ i+1 }}</td>
                <td style="width: 20%;">{{ document.customer?.customerRCode }} <br> {{ document.customer?.customerNameEng }}</td>
                <td style="width: 20%;">
                  <img *ngIf="document.fileName.split('.')[1] === 'jpeg' || document.fileName.split('.')[1] === 'png'"
                  [src]="getBase64ImageSrc(document.base64String,document.fileName.split('.')[1])" style="width: 40%; height: 30%;" alt="Preview" />
                    <img [src]="defaultDocxImage" style="width: 40%; height: 30%;" alt="Default Docx"
                         *ngIf="document.fileName && document.fileName.split('.')[1] === 'docx'" />
                    <img [src]="defaultPdfImage" style="width: 40%; height: 30%;" alt="Default PDF"
                         *ngIf="document.fileName && document.fileName.split('.')[1] === 'pdf'" />
                    <img [src]="defaultExcelImage" style="width: 40%; height: 30%;" alt="Default Excel"
                         *ngIf="document.fileName && document.fileName.split('.')[1] === 'xlsx'" />

                  </td>

                <td style="width: 20%;">{{ document.documentsType?.typeName }}</td>
                <td style="width: 20%; white-space: nowrap;">{{ document.uploadDate | date: dateFormat }}</td>
                <td style="width: 20%;">{{ document.refNo }}</td>
                <td style="width: 20%; text-align: center;">
                  <a href="getDownloadUrl2(document.base64String, document.fileName.split('.')[1])" download="{{ document.fileName }}"
                  style="background-color: #438a62; padding: 3px 10px; border-radius: 5px;font-size: 12px ;color: white; text-decoration: none;"
                  class="btn">
                 Download
               </a>                  <br>
                      <button class="btn mt-1" *ngIf="document.status == 0"
                      style="font-size: 14px; width: 120px !important; height: 35px;"
                      (click)="Approve(document.id)"
                      style="background-color: #B5C3DB; padding: 3px 10px; border-radius: 5px;font-size: 12px;    width: 97%; color: white; text-decoration: none;">Approve</button>
                </td>
              </tr>
            </tbody>


        </table>
        </div>
        </div>
      </div>
  </div>
