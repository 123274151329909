import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { AuthGuard } from './services/auth-guard/auth-guard.service';



const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "/pages/login",
    pathMatch: "full",
  },
  {
    path: "terms-and-conditions-dps",
    redirectTo: "/pages/terms-and-conditions-dps",
    pathMatch: "full"
  },
  // {
  //   path: '',
  //   redirectTo: 'customer/Customer/CumstomerListComponent',
  //   pathMatch: 'full',
  // },

  // {
  //   path: "",
  //   component: FullLayoutComponent,
  //   data: { title: "full Views" },
  //   children: Full_ROUTES,
  //   canActivate: [AuthGuard],
  // },
   { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES},
  {
    path: "",
    component: ContentLayoutComponent,
    data: { title: "content Views" },
    children: CONTENT_ROUTES,
  },

  {
    path: "",
    redirectTo: '/pages/error'
   // redirectTo: "/pages/login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
