<section>

  <diV class="card ">
      <div class="card-body">
          <div class="container-fluid">

              <div class="row">
                  <div class="col-md-9">
                      <h5 class="card-title page-heading" style="color: black">Transaction Logs</h5>
                  </div>
                  <div class="col-md-2">

                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
                      <i class="icon-arrow-left" style="font-size: 17px;"></i>
                  </button>
                  </div>
                </div>
                <hr style="height: 10%" />
              <!-- <div class="row align-items-center mt-2">
                  <div class="col">
                      <h5 class="card-title page-heading" style="color: black">Transaction Logs</h5>
                  </div>
                  <div class="col-auto">
                      <button class="btn btn-secondary  btn-back" title="Go Back" (click)="goBack()">
                          <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                      </button>
                  </div>
              </div>

              <hr> -->
              <div class="row mb-1">
                  <div class="col-md-4">
                      <label for="fromDate">From Date</label>
                      <input type="date" id="fromDate" class="form-control" [(ngModel)]="startDate"
                          (ngModelChange)="onDateChange()" placeholder="Select From Date">
                  </div>
                  <div class="col-md-4">
                      <label for="toDate">To Date</label>
                      <input type="date" id="toDate" class="form-control" [(ngModel)]="EndDate" (ngModelChange)="onDateChange()"
                          placeholder="Select To Date">
                  </div>
                  <div class="col-4">
                      <label>Filter Type</label>
                      <select class="form-control" [(ngModel)]="typeId" (change)="onFilterTypeChange($event.target.value)"
                          (change)="SearchByParameter()">
                          <option value="0"> Select</option>
                          <option value="1">Customer Name</option>
                          <option value="3"> Mobile No</option>
                          <option value="4"> Account No</option>
                      </select>
                  </div>
              </div>

              <div class="card mb-1">
                  <div class="row g-4">
                      <div class="col-md-4" *ngIf="showCustomerNameField">
                          <label>Customer Name</label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="customername"
                                  (keyup.enter)="getCustomerDPSAccount()" placeholder="Search by customer name ...">
                              <button class="btn btn-danger" (click)="deleteFilterTypeFields(1)">
                                  <i class="fa fa-close"></i>
                              </button>
                          </div>
                      </div>

                      <div class="col-md-4" *ngIf="showAccountField">
                          <label>Account No</label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="accountNo"
                                  placeholder="Search by Account No...">
                              <button class="btn btn-danger" (click)="deleteFilterTypeFields(4)">
                                  <i class="fa fa-close"></i>
                              </button>
                          </div>
                      </div>

                      <div class="col-md-4" *ngIf="showMobileNumberField">
                          <label>Mobile No</label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="mobileNumber"
                                  placeholder="Search by mobile number...">
                              <button class="btn btn-danger" (click)="deleteFilterTypeFields(3)">
                                  <i class="fa fa-close"></i>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>


              <div class="d-flex justify-content-end mb-1">
                  <button class="btn btn-success  mr-2" (click)="GetClCalculationListByDate()">Search</button>
                  <button class="btn btn-info" (click)="refreshFilter()">Refresh</button>

              </div>
          </div>
      </div>

      <div class="card-body pt-2" style="overflow: auto; max-height: 600px;">
          <div class="container-fluid">
              <table id="example" class="table table-striped table-bordered scrollable-table" style="width:100%">
                  <thead style="position: sticky; top:-5px; z-index: 1; background-color: #EEF5E9">
                      <tr>
                          <th style="font-size: smaller;">Action</th>
                          <th style="font-size: smaller;">Customer Name</th>
                          <th style="font-size: smaller;">TRAN ID</th>
                          <th style="font-size: smaller;">TRAN DATE</th>
                          <th style="font-size: smaller;">STATUS</th>
                          <th style="font-size: smaller;">AMOUNT</th>
                          <th style="font-size: smaller;">CARD TYPE</th>
                          <th style="font-size: smaller;">CARD NO</th>
                          <th style="font-size: smaller;">Recharge Id</th>
                          <th style="font-size: smaller;">Issuer Id</th>
                          <th style="font-size: smaller;">Issuer Date</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let log of transactionLogs">
                          <td>  <button class="btn btn-sm btn-primary" style="background-color: #1fca74;color: white;" (click)="TransactionDetails(tranDetails)"  (click)="getTransactionById(log.id)">Details</button></td>
                          <td>{{ log.customerAccount?.customers?.customerNameEng }}</td>
                          <td>{{ log?.tran_id }}</td>
                          <td>{{ log?.tran_date | date: dateFormat }}</td>
                          <td>{{ log?.status }}</td>
                          <td style="text-align: right;">{{ log.amount |number}}</td>
                          <td>{{ log?.card_type }}</td>
                          <td>{{ log?.customerMobileNo}}</td>
                          <!-- <td>{{ log.value_a }}</td> -->
                          <td>{{ log?.rechargeId}}</td>
                          <td>{{ log?.issuerTxnId}}</td>
                          <td>{{ log?.issuerReqDateTime | date: dateFormat }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </diV>

  </section>


  <ng-template #tranDetails let-c="close" let-d="dismiss">
      <div class="">

          <div class="" style=" width: 100%;">
              <div class="modal-header">
                  <h4 class="modal-title page-heading" style="text-align: center;">Payment Details</h4>
                  <button type="button" class="close" style="size: 100%;" aria-label="Close" (click)="d('Cross click')">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="col-12 row">
                  <div class="col-2"></div>
                  <div class="modal-body col-9">

                      <p><span style="font-weight: 800;color: black;">Customer Name:</span>   {{ transactionDetails?.customerAccount?.customers?.customerNameEng }}</p>
                      <p><span style="font-weight: 800;color: black;">Customer MFS No:</span>   {{ transactionDetails?.customerMobileNo }}</p>
                      <p><span style="font-weight: 800;color: black;">Amount:</span>   {{ transactionDetails?.amount }}</p>
                      <p><span style="font-weight: 800;color: black;">Trxn-Id:</span>  {{ transactionDetails?.tran_id }}</p>
                      <p><span style="font-weight: 800;color: black;">Trxn-Date :</span>  {{ transactionDetails?.tran_date | date }}</p>
                      <p><span style="font-weight: 800;color: black;">MFS Type:</span>  {{ transactionDetails?.card_type}}</p>
                      <p><span style="font-weight: 800;color: black;">Recharge ID:</span>  {{ transactionDetails?.rechargeId}}</p>

                      <p><span style="font-weight: 800;color: black;">Issuer Trxn Id :</span>  {{ transactionDetails?.issuerTxnId}}</p>
                      <p><span style="font-weight: 800;color: black;">Issuer Requ Id :</span>  {{ transactionDetails?.issuerReqId}}</p>
                      <p><span style="font-weight: 800;color: black;">Issuer Date:</span>

                          {{ (transactionDetails?.issuerTxnDateTime?.slice(0, 4) + '-' + transactionDetails?.requestDateTime?.slice(4, 6) + '-'
                          + transactionDetails?.requestDateTime?.slice(6, 8)) | date:'dd-MM-yyyy' }}


                      </p>
                      <p><span style="font-weight: 800;color: black;">Request Date:</span> {{ (transactionDetails?.requestDateTime?.slice(0, 4) + '-' + transactionDetails?.requestDateTime?.slice(4, 6) + '-' +
                      transactionDetails?.requestDateTime?.slice(6, 8)) | date:'dd-MM-yyyy' }}
                      </p>
                      <p><span style="font-weight: 800;color: black;">Reference No:</span>  {{ transactionDetails?.referenceNo }}</p>
                      <p><span style="font-weight: 800;color: black;">Merchant Id:</span>  {{ transactionDetails?.merchantId }}</p>
                      <p><span style="font-weight: 800;color: black;"> Merchant Mobile No:</span>  {{ transactionDetails?.merchantMobileNo }}</p>
                      <p><span style="font-weight: 800;color: black;">Merchant Balance: </span>  {{ transactionDetails?.merchantBalance }}</p>
                      <p><span style="font-weight: 800;color: black;">Merchant Aggregator Id:</span>   {{ transactionDetails?.merchantAggregatorId }}</p>

                    </div>

                    <div class="col-1"></div>
              </div>


              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
              </div>
          </div>
      </div>
  </ng-template>
