import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Applicationuser } from 'app/employee/employee-model/applicationuser';
import { EmployeeserviceService } from 'app/employee/employee-service/employeeservice.service';
import { SharedService } from 'app/services/shared.service';
import { CustomerService } from 'app/customer/service/customer.service';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LanguageServiceService } from 'app/services/language-service.service';
import { RoleService } from 'app/user/user-management/user-service/role.service';
import { DatePipe, Location } from "@angular/common";
import Swal from "sweetalert2";
import { color } from 'html2canvas/dist/types/css/types/color';
@Component({
  selector: "app-employee-register",
  templateUrl: "./employee-register.component.html",
  styleUrls: ["./employee-register.component.scss"],
})
export class EmployeeRegisterComponent implements OnInit {
  modalRef: NgbModalRef | undefined;

  model = new Applicationuser();
  // ListShow: boolean = false;
  //registeremployee: boolean = true;
  formGroupReset: FormGroup;
  closeResult: string;
  isExistingPasswordValid: boolean = true;
  genderList: Observable<any[]>;
  roleList: any;
  selectedRoles: string[] = [];
  //userlist: Observable<any[]>;
  errorMessage: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _service: EmployeeserviceService,
    private _customerservice: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private shared: SharedService,
    private _roleService: RoleService,
    private location: Location,
    private language: LanguageServiceService
  ) {
    //this.populateForm();
    language.setLanguage();
  }

  ngOnInit(): void {
    this.loadGenderList();
    this.loadRoleList();

    //this.getAllUserList();
  }

  loadGenderList() {
    this._customerservice.GetGenderList().subscribe((res) => {
      this.genderList = of(res);
    });
  }

  loadRoleList() {
    this._roleService.getlist().subscribe((res) => {
      this.roleList = res;
    });
  }

  ChangeRole(selectedRole: any): void {
    const roleValue = selectedRole.value;

    const index = this.selectedRoles.indexOf(roleValue);

    if (index !== -1) {
      this.selectedRoles.splice(index, 1);
    } else {
      this.selectedRoles.push(roleValue);
    }

    console.log("Updated selectedRoles:", this.selectedRoles);
  }

  // getAllUserList() {
  //   this._service.getUserlist().subscribe(res => {
  //     this.userlist = res;
  //   });
  // }
  refresh(data: Applicationuser) {
    this.model.EmailAddress = "";
    this.model.Mobile = "";
    this.model.Name = "";
    this.model.NID = "";
    this.model.genderId = null;
    this.model.RoleNames = [];
  }

  onsubmit(data: Applicationuser) {
    this.model.EmailAddress = this.model.EmailAddress;
    this.model.Mobile = this.model.Mobile;
    this.model.Name = this.model.Name;
    this.model.NID = this.model.NID;
    this.model.genderId = this.model.genderId;
    this.model.RoleNames = this.selectedRoles;
    
     this._roleService
       .GetUserCheckByPhone(this.model.Mobile)
       .subscribe((res) => {
         if (res !== null) {
           this.shared.ShowAlert(
             "Warning",
             "This " + this.model.Mobile + " user is already exists.",
             "warning"
           );
         }
          else {
           Swal.fire({
             title: "Are you sure?",
             text: "Do you want to register!",
             icon: "warning",
             showCancelButton: true,
             confirmButtonColor: "#3085d6",
             cancelButtonColor: "#d33",
             confirmButtonText: "Yes,register!",
           }).then((res) => {
             if (res.isConfirmed) {
               this._service.save(this.model).subscribe((res) => {
                 const message = res["message"];
                 this.shared.ShowAlert("Success", message, "success");
                 // this.ListShow = true;
                 // this.registeremployee = false;
                 // this.getAllUserList();
                 this.router.navigate(["/employeeregister/employee-list"]);
                 this.refresh(data);
               });
             }
           });
         }
       });
    this.changeDetectorRef.detectChanges();
  }

  goBack() {
    this.location.back();
  }





  // showRegistrationForm() {
  //   this.registeremployee = true;
  //   this.ListShow = false;
  // }

  // showUserList() {
  //   this.registeremployee = false;
  //   this.ListShow = true;
  // }

  //#region Password Reset

  // populateForm() {
  //   this.formGroupReset = this.formBuilder.group({
  //     userName: ['', Validators.required],
  //     password: ['', Validators.required],
  //     confirmPassword: ['', Validators.required],
  //   });
  // }

  // openReset(resetcontent, item: any) {
  //   this.formGroupReset.patchValue({
  //     userName: item.userName,
  //     password: item.password,
  //     confirmPassword: item.confirmPassword

  //   });
  //   this.modalRef = this.modalService.open(resetcontent, {
  //     backdrop: 'static',
  //     keyboard: false,
  //   });
  // }
  // ResetSubmit() {
  //   if (this.formGroupReset.invalid) {
  //     this.shared.ShowAlert('Alert', 'Please fill in all required fields.', 'warning');
  //     return;
  //   }

  //   const password = this.formGroupReset.value.password;
  //   const confirmPassword = this.formGroupReset.value.confirmPassword;
  //   if (password !== confirmPassword) {
  //     this.errorMessage = "Password didn't match.";
  //     return;
  //   }

  //   const model = {
  //     userName: this.formGroupReset.value.userName,
  //     password: password,
  //     confirmPassword: confirmPassword,
  //   };

  //   this._service.ResetPassword(model).subscribe(
  //     (res) => {

  //       this.shared.ShowAlert('Thank You', 'Password Updated successfully!', 'success');
  //       this.closeModal();
  //       //this.showUserList();
  //     },
  //     (err) => {
  //       this.shared.ShowAlert('Alert', 'Something Went Wrong!', 'warning');
  //     }
  //   );
  // }
  // closeModal() {
  //   if (this.modalRef) {
  //     this.modalRef.close();
  //   }
  // }
}