import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionLog } from 'app/payment/models/transaction-log';
import { PaymentService } from 'app/payment/payment.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: 'app-nagad-transaction-log',
  templateUrl: './nagad-transaction-log.component.html',
  styleUrls: ['./nagad-transaction-log.component.scss']
})
export class NagadTransactionLogComponent implements OnInit {
  dateFormat: string = "";
  transactionLogs: TransactionLog[] = [];
  startDate: Date | null = null;
  EndDate: Date | null = null;
  formattedStartDate: string | null = null;
  formattedEndDate: string | null = null;
  closeResult: string;
  transactionDetails: any = [];
  loading: boolean = false;
  customername: string = "";
  customercode: string = "";
  mobileNumber: string = "";
  accountNo: string = "";
  private intervalId: any;
  nidNo: string = "";
  typeId: number = 0;
  selectedFilterType: number = 0;

  showMobileNumberField: boolean = false;
  showAccountField: boolean = false;
  showCustomerCodeField: boolean = false;
  showCustomerNameField: boolean = false;
  constructor(
    private paymentService: PaymentService,
    private changeDef: ChangeDetectorRef,
    private location: Location,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
      var thumb = JSON.parse(localStorage.getItem("thumb"));
      this.dateFormat = thumb.thumbRule.dateFormat;
      this.getTransactionLogs();
  }

  getTransactionLogs() {
    this.paymentService.getTransactionLogs().subscribe(
      (logs: any[]) => {
        this.transactionLogs = logs;
        this.changeDef.detectChanges();
      },
      (error) => {
        console.error("Error fetching transaction logs", error);
      }
    );
  }
  startInterval() {
    this.intervalId = setInterval(() => {
      this.getTransactionLogs();
    }, 1000 * 60 * 5);
  }

  clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
  GetClCalculationListByDate() {
    const defaultDate = "0001-01-01";
    //this.startDate ?? defaultDate
    this.transactionLogs = [];
    const formattedStartDate = this.startDate
      ? this.datePipe.transform(this.startDate, "yyyy-MM-dd")
      : "";
    const formattedEndDate = this.EndDate
      ? this.datePipe.transform(this.EndDate, "yyyy-MM-dd")
      : "";

    this.paymentService
      .getTransactionLogsByDate(
        formattedStartDate,
        formattedEndDate,
        this.customername,
        this.accountNo,
        this.mobileNumber
      )
      .subscribe((res) => {
        this.transactionLogs = res;
        this.changeDef.detectChanges();
      });
  }

  TransactionDetails(content) {
    let options: any = {
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  getTransactionById(id: any) {
    this.paymentService.getTransactionLogsById(id).subscribe((res) => {
      this.transactionDetails = res;
    });
  }

  formatDates() {
    this.formattedStartDate = this.datePipe.transform(
      this.startDate,
      "dd-MM-yyyy"
    );
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }
  goBack() {
    this.location.back();
  }

  //////////////////////////////////
  SearchByParameter() {
    this.typeId;
  }

  onFilterTypeChange(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showCustomerNameField = true;
        this.customername = "";
        break;
      case "3":
        this.showMobileNumberField = true;
        break;

      case "4":
        this.showAccountField = true;
        break;
    }
  }

  deleteFilterTypeFields(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showCustomerNameField = false;
        this.customername = "";
        break;
      case "3":
        this.showMobileNumberField = false;
        this.mobileNumber = "";
        break;
      case "4":
        this.showAccountField = false;
        this.accountNo = "";
        break;
    }
  }

  refreshFilter(): void {
    this.showCustomerNameField = false;
    this.showMobileNumberField = false;
    this.showAccountField = false;

    this.mobileNumber = "";
    this.customername = "";
    this.accountNo = "";
    this.typeId = 0;

    this.router.navigate(["/payment/transaction-logs"], {
      skipLocationChange: true,
    });
  }

}
