
<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">

            <div class="card-header row" >

                <div class="col-10">
                    <h5 class="card-title page-heading" style="color: #888888;font-family: Arial;font-size: 20px !important;">Group Summary</h5>
                </div>

                <div class="col-2">
                    <button class="btn btn-secondary pull-right btn-back" title="Go Back" (click)="goBack()">

                        <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                    </button>
                </div>
            </div>

          <hr>
            <div class="card-body">
                <div class="col-md-12">
                    <!-- <div class="form-group row" id="SBUSelect">
                        <label for="ledgerId" class="col-sm-3 col-form-label">SBU</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control" id="sbuId"></select>
                        </div>
                    </div>
                    <div class="form-group row" id="LedgerProject">
                        <label for="ledgerId" class="col-sm-3 col-form-label">Project</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control" id="projectId"></select>
                        </div>
                    </div> -->
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="fromDate" class="col-sm-3 col-form-label text-left">From Date <span class="redStar">*</span></label>
                        <div class="col-sm-3 input-group">

                                <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">



                        </div>
                        <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select From Date">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Get Report By</label>
                        <div class="col-sm-9">
                            <select type="text" class="form-control"  [(ngModel)]="reportBy">
                            <!-- <select type="text" class="form-control"  [(ngModel)]="reportBy" > -->
                                <option value="">Select Type</option>
                                <option value="1">Group</option>
                                <option value="2">Sub-Group</option>
                                <option value="3">Ledger</option>
                                <!-- <option value="4">Sub-Ledger</option> -->
                            </select>
                        </div>
                    </div>

<!-- ------------------------------------------------------------------------ -->


<div class="form-group row" *ngIf="reportBy == 1|| reportBy == 2 ||reportBy == 3||reportBy == 4">

        <label for="groupId" class="col-sm-3 col-form-label">Main Group Name </label>
        <div class="col-sm-9">

            <!-- <select  [(ngModel)]="mgId" class="form-control"  (change)="onGroupChange($event.target.value)">
                <option *ngFor="let group of groupList" [value]="group.id">{{ group.groupName }} -{{ group.groupCode}} ({{group.nature.natureName}})</option>
            </select> -->


        <ng-select class="form-control" name="groupList" formControlName="mgId" [items]="groupList" [virtualScroll]="true"
            bindLabel="groupName" bindValue="id" [(ngModel)]="mgId" (change)="onGroupChange($event)">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span class="option-content">{{ item.groupName }} - {{ item.groupCode }} ({{ item.nature.natureName }})</span>
            </ng-template>
        </ng-select>





        </div>

</div>
<div class="form-group row" *ngIf="reportBy == 2  || reportBy == 3">
    <label for="groupId" class="col-sm-3 col-form-label">Sub Group Name </label>
    <div class="col-sm-9">


        <select [(ngModel)]="gId" class="form-control"   (change)="onAccountGroupChange($event.target.value)">
            <option *ngFor="let group of AccountGroupList" [value]="group.id">{{ group.groupName }} -{{ group.groupCode}} ({{group.nature.natureName}})</option>
        </select>


    </div>
</div>
<div class="form-group row" *ngIf="reportBy == 3 || reportBy == 4" >
    <label for="groupId" class="col-sm-3 col-form-label">Ledger Name </label>
    <div class="col-sm-9">

        <select [(ngModel)]="LId" class="form-control" (change)="onLedgerChange($event.target.value)">
            <option *ngFor="let group of ledgerList" [value]="group.id">{{ group.accountName }} -{{ group.accountCode}}</option>
        </select>


        <!-- <input type="text" class="form-control" [(ngModel)]="LName" required>
        <input type="hidden" [(ngModel)]="LId" /> -->

    </div>
</div>
<!-- <div class="form-group row" *ngIf="reportBy == 4" >
    <label for="subLedgerId" class="col-sm-3 col-form-label">Select Sub Ledger</label>
    <div class="col-sm-9">
        <select [(ngModel)]="subLedgerId" class="form-control">
            <option *ngFor="let group of SubLedgerList" [value]="group.id">{{ group.accountName }} -{{ group.accountCode}}</option>
        </select>

    </div>
</div> -->


                    <div class="form-group row" style="margin-top:10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">
                            <!-- <button id="SubmitButton" style="float:right" class="btn btn-success btn-xs" title="Preview">Print</button> -->
                            <button  class="btn btn-success" style="float:right" (click)="OpenPDF()">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />




