<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-10">
                        <h5 class="card-title page-heading" style="color: #888888;font-family: Arial;font-size: 20px !important;">DPS Encashment Report</h5>
                    </div>

                    <div class="col-2">
                        <button class="btn btn-secondary pull-right btn-back" title="Go Back" (click)="goBack()">

                            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                        </button>
                    </div>

                </div>
                <hr>
            </div>

            <div class="card-body">
                <div class="col-md-12">

                    <div class="form-group row">
                        <label for="fromDate" class="col-sm-4 col-form-label text-left">As on Date <span class="redStar">*</span></label>
                        <div class="col-sm-8 input-group">

                                <input type="text" class="form-control" value="{{businessdatevalue | date: dateFormat}}"  [(ngModel)]="businessdate" placeholder="" readonly>
                        </div>

                    </div>


                    <div class="form-group row" style="margin-top:10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">

                            <button  class="btn btn-success" style="margin-left: 5px; float: right;" (click)="OpenPDF()" >Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
